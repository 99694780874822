import { Modal, Loader, Button, Status, TableSearch, Table } from '@adtech/ui';
import { useAppDispatch } from '@hooks/useStore';
import IconTabOpen from '@images/svg/icons/icon-tab-open.svg';
import { userActions } from '@redux/slices/users';
import { IProject } from '@typings/projects';
import { IRootSlice } from '@typings/rootSlice';
import { ISearchParams } from '@typings/search';
import React, { useEffect, FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import s from '../ProjectPanel.pcss';

interface IProps {
    projectId: number;
    isOpen: boolean;
    onCloseHandler: () => void;
}

const ProjectsModal: FC<IProps> = ({ projectId, isOpen, onCloseHandler }: IProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const isInited = useRef(false);
    const isSearching = useRef(false);
    const searchValue = useRef<string>(null);

    const USER_PROJECTS_VIEW_LIMIT = 10;
    const USER_PROJECTS_REQUEST_LIMIT = 100;

    const userProjects = useSelector((state: IRootSlice) => state.userSlice.userProjects);
    const userProjectsRequest = useSelector((state: IRootSlice) => state.userSlice.userProjectsRequest);

    const isExtendedView = userProjects.length >= USER_PROJECTS_VIEW_LIMIT;

    const getUserProjects = (params: ISearchParams) => dispatch(userActions.getUserProjects(params));

    useEffect(() => {
        if (isOpen && !isInited.current) {
            // Запрос проектов пользователя при первом открытии
            getUserProjects({ limit: USER_PROJECTS_REQUEST_LIMIT });
            isInited.current = true;
        }
    }, [isOpen]);

    const searchHandler = (value: string) => {
        isSearching.current = true;
        searchValue.current = value;

        getUserProjects({
            limit: USER_PROJECTS_REQUEST_LIMIT,
            ...(value ? { search: value } : {}),
        });
    };

    const closeModalHandler = () => {
        // Сбрасываем значение поиска и инита,
        // чтобы перезапросить данные при открытии модалки, если был поиск
        if (searchValue.current) {
            isInited.current = false;
            searchValue.current = null;
        }
        onCloseHandler();
    };

    const switchProjectHandler = (id: number) => {
        const path = location.pathname;
        const isMaterial = path.includes('/material/');
        const isAuthor = path.includes('/author/');
        let newPath = `/stat/projects/${id}/dashboard`;

        if (path.includes(`projects/${projectId}`)) {
            newPath = path.replace(/projects\/(\d*)\//, `projects/${id}/`);
        }

        // Редиректы для материалов и авторов, которые уникальны для проекта
        if (isMaterial) {
            newPath = path.replace(/material\/.*/, 'materials_summary');
        } else if (isAuthor) {
            newPath = path.replace(/author\/.*/, 'authors_summary');
        }

        onCloseHandler();
        navigate(newPath);
    };

    const renderItem = ({ id, title, url }: IProject) => {
        const isCurrent = id === projectId;

        return (
            <div className={s.item}>
                <div className={s.itemInfo}>
                    <div className={s.itemHead}>
                        {title}
                        <a className={s.itemUrl} href={url} target="_blank" rel="noopener">
                            <IconTabOpen />
                        </a>
                    </div>
                    <div className={s.itemCounter}>
                        <span className={s.itemCounterId}>Cчётчик&nbsp;{id}</span>
                        {isCurrent && <Status type="mini" status="gradient" title="Текущий" />}
                    </div>
                </div>
                {!isCurrent && (
                    <Button
                        onClick={() => switchProjectHandler(id)}
                        className={s.itemButton}
                        type="dashed"
                        size="small"
                    >
                        Переключиться
                    </Button>
                )}
            </div>
        );
    };

    const tableColumns = [
        {
            dataIndex: 'title',
            render: renderItem,
        },
    ];
    const prepareDataForRender = (item: IProject) => {
        const { id } = item;

        return {
            key: id,
            id,
            title: item,
        };
    };

    return (
        <Modal
            className={s.projectsModal}
            isOpened={isOpen}
            title="Мои сайты"
            onClose={closeModalHandler}
            size={isExtendedView || isSearching.current ? 'lightbox' : 'lg'}
            centered
            destroyOnClose
            cancelText=""
            okText=""
        >
            <div className={s.projectsModalTable}>
                <Loader loading={userProjectsRequest}>
                    {isExtendedView || isSearching.current ? (
                        <div className={s.projectsModalTableSearch}>
                            <TableSearch onSearch={searchHandler} searchPlaceholder="Введите название сайта" />
                        </div>
                    ) : null}
                    <Table
                        columns={tableColumns}
                        dataSource={userProjects?.map((project: IProject) => prepareDataForRender(project))}
                        pagination={false}
                        showHeader={false}
                    />
                </Loader>
            </div>
        </Modal>
    );
};

export default ProjectsModal;
