import { Select, IconCross } from '@adtech/ui';
import React, { useState } from 'react';

import FilterFormHeader from '../components/FilterFormHeader';
import { IFilterRuleFormValue, IFiltersCnfItem } from '../types/filters';
import s from './Textarea.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: Record<string, IFilterRuleFormValue[]>;
    setAllFormsValues: (formsValues: Record<string, any>) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
}

const TextareaFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
}) => {
    const [selectTagValue, setSelectTagValue] = useState('');

    const onChangeSelectHandler = (value: string[] | null) => {
        setSelectTagValue('');
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearFilter = () => {
        onChangeSelectHandler(null);
    };

    const editTag = (nextValue: string, prevValue: string) => {
        const newValue = (ruleFormValue.value as string[])?.map((value) => (value === prevValue ? nextValue : value));
        onChangeSelectHandler(newValue);
    };

    return (
        <div id="textareaFilterSelect" className={s.textareaFilter}>
            <FilterFormHeader
                label={filterCnf?.label}
                hint={filterCnf?.hint}
                clearFilter={ruleFormValue.value?.length ? clearFilter : null}
            />
            <Select
                placeholder="Введите или выберите из списка"
                mode="tags"
                value={ruleFormValue.value || []}
                onChange={onChangeSelectHandler}
                editTags={editTag}
                showArrow={false}
                showSearch={false}
                removeIcon={<IconCross />}
                onSearch={(value: string) => setSelectTagValue(value)}
                defaultActiveFirstOption={!!selectTagValue}
                getPopupContainer={() => document.getElementById('textareaFilterSelect')}
            />
            <div className={s.textareaFilterDesc}>
                <p>Правила оформления:</p>
                <p>* - заменяет неограниченное количество символов на&nbsp;любой позиции в&nbsp;строке</p>
                <p>@ - выполнит поиск без учёта регистра при добавлении в&nbsp;начале строки</p>
            </div>
        </div>
    );
};

export default TextareaFilter;
