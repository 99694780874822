import { Button, IconChevronDown, Tooltip } from '@adtech/ui';
import { Chip } from '@sberads/ui';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { IconExclude } from '../icons';
import {
    EFilterRuleOperation,
    EFilterType,
    IFilterRuleFormValue,
    TAllFormValues,
    TDimension,
    TFilters,
    TFiltersFormCnf,
} from '../types/filters';
import { transformFormsValuesToFilters } from '../utils/filtersUtils';
import s from './FilterChips.pcss';

interface IProps {
    filtersFormCnf: TFiltersFormCnf;
    allFormsValues: TAllFormValues;
    clickChipHandler: (dimension: TDimension) => void;
    applyFiltersHandler?: (filtersData: TFilters) => void;
    dimensionsValuesDict?: Record<TDimension, Record<string, string>>;
}

const FilterChips: React.FC<IProps> = ({
    filtersFormCnf,
    allFormsValues,
    clickChipHandler,
    applyFiltersHandler,
    dimensionsValuesDict,
}) => {
    const CHIPS_ROW_HEIGHT = 40; // Высота одной строки чипсов (задана в стилях)
    const isCollapseBtnVisible = useRef(false);
    const [isChipsCollapse, setChipsCollapse] = useState(false);
    const chipsContainerElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        isCollapseBtnVisible.current = chipsContainerElement.current?.getBoundingClientRect().height > CHIPS_ROW_HEIGHT;
    }, [JSON.stringify(allFormsValues), chipsContainerElement.current]);

    const chipClickHandler = (dimension: TDimension) => {
        clickChipHandler(dimension);
    };

    const chipCloseHandler = (e: React.MouseEvent<HTMLElement>, dimension: TDimension, ruleIndex: number) => {
        e.stopPropagation();
        const curFilterFormsValues = allFormsValues[dimension];
        const newFormVal = [...curFilterFormsValues];
        newFormVal.splice(ruleIndex, 1);
        const newAllFormsValues = { ...allFormsValues, [dimension]: newFormVal };
        applyFiltersHandler?.(transformFormsValuesToFilters(newAllFormsValues, filtersFormCnf));
    };

    const transformValueToCyrillic = (dimension: TDimension, value: string): string =>
        dimensionsValuesDict[dimension]?.[value] ? dimensionsValuesDict[dimension][value] : value;

    const renderChip = (formRule: IFilterRuleFormValue, dimension: TDimension, index: number) => {
        const { op, value } = formRule;
        const { label, type } = filtersFormCnf[dimension];
        const isDatePeriod = type === EFilterType.datePeriod;
        const isExclude = op === EFilterRuleOperation.NOT;

        let delimiter = ' ';
        let opText = '';
        let formattedValue = '';

        switch (op) {
            case EFilterRuleOperation.AND:
                delimiter = ' и ';
                break;
            case EFilterRuleOperation.OR:
                delimiter = ' или ';
                break;
            case EFilterRuleOperation.NOT:
                delimiter = ', ';
                break;
            case EFilterRuleOperation.EQ:
                opText = 'равно ';
                break;
            case EFilterRuleOperation.NOTEQ:
                opText = 'не равно ';
                break;
            case EFilterRuleOperation.GTOE:
                opText = 'больше или равно ';
                break;
            case EFilterRuleOperation.GT:
                opText = isDatePeriod ? 'после ' : 'больше ';
                break;
            case EFilterRuleOperation.LTOE:
                opText = 'меньше или равно ';
                break;
            case EFilterRuleOperation.LT:
                opText = isDatePeriod ? 'до ' : 'меньше ';
                break;
            default:
                break;
        }

        if (typeof value === 'string') {
            formattedValue = transformValueToCyrillic(dimension, value);
        } else {
            formattedValue = value.map((val) => transformValueToCyrillic(dimension, val)).join(delimiter);
        }
        const text = `${label}: ${opText}${formattedValue}`;

        return (
            <Tooltip title={text} overlayClassName={s.filterChipsTooltip} key={index}>
                <Chip
                    classNames={s.filterChipsChip}
                    onClick={() => chipClickHandler(dimension)}
                    onClose={(e) => chipCloseHandler(e, dimension, index)}
                    prefix={isExclude ? <IconExclude /> : null}
                    isNegative={isExclude}
                >
                    {text}
                </Chip>
            </Tooltip>
        );
    };

    const filterChipsClass = cn(s.filterChips, { [s.filterChipsHidden]: isChipsCollapse });
    const btnCollapseText = isChipsCollapse ? 'Показать ещё' : 'Свернуть';
    const allFormsValuesDimensions = Object.keys(allFormsValues);

    return (
        <>
            {allFormsValuesDimensions?.length ? (
                <div className={filterChipsClass} ref={chipsContainerElement}>
                    {allFormsValuesDimensions.map((dimension) => {
                        const formRules = allFormsValues[dimension];
                        return formRules.map((formRule, index) => renderChip(formRule, dimension, index));
                    })}
                </div>
            ) : null}
            {isCollapseBtnVisible.current ? (
                <Button
                    className={cn(s.filterChipsCollapseBtn, { [s.filterChipsCollapseBtnUp]: !isChipsCollapse })}
                    type="link"
                    size="small"
                    suffixIcon={<IconChevronDown />}
                    onClick={() => setChipsCollapse(!isChipsCollapse)}
                >
                    {btnCollapseText}
                </Button>
            ) : null}
        </>
    );
};

export default FilterChips;
