import { Cookies } from '@adtech/cookies';
import { Loader } from '@adtech/ui';
import authTypes from '@constants/authTypes';
import cookieName from '@constants/cookieName';
import reportsConstants from '@constants/reports';
import { useAppDispatch } from '@hooks/useStore';
import { userActions } from '@redux/slices/users';
import { IRootSlice } from '@typings/rootSlice';
import { getDomain } from '@utils/auth';
import React, { useEffect, useRef } from 'react';
import { ConnectedComponent, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const LoaderComp: React.FC = () => <Loader loading />;

interface IOption {
    Component: React.FC | ConnectedComponent<any, any>;
    isPrivate?: boolean;
}

const AuthDecorator = ({ Component, isPrivate = false }: IOption) => {
    const dispatch = useAppDispatch();
    const params: IUrlParams = useParams();
    const isFirstRender = useRef(true);
    const timer = useRef<ReturnType<typeof setTimeout>>(null);

    let isRequest = useSelector((state: IRootSlice) => state.userSlice.userRequest);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const { origin } = window.location;
    const isAuth = !!userId;
    const redirectUrl = `/api/v1.0/user/keycloak_auth?backUrl=${encodeURIComponent(origin)}/stat/projects`;

    // Рефрешим токен, чтобы авторизация в кк не отвалилась, а флаги юзера были актуальными
    useEffect(() => {
        if (isAuth) {
            timer.current = setInterval(() => dispatch(userActions.refreshToken()), 180000);
        }
        return () => clearInterval(timer.current);
    }, [isAuth]);

    // Запрос на первый рендер, useEffect асинхронный, не используем
    if (!isAuth && !isRequest && isFirstRender.current) {
        isFirstRender.current = false;
        isRequest = true;
        dispatch(userActions.getUser());
    }

    if (isRequest) return <LoaderComp />;

    if (
        isAuth ||
        !isPrivate ||
        // Открываем доступ к Дашборду и отчётам для Демо-сайта
        params?.projectId === String(reportsConstants.DEMO_PROJECT_ID)
    ) {
        return <Component />;
    }

    Cookies.set(cookieName.authType, authTypes.KEYCLOAK, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        domain: getDomain(),
    });

    // фикс для firefox
    setTimeout(() => {
        window.location.href = redirectUrl;
    }, 100);

    return <LoaderComp />;
};

export default AuthDecorator;
