import { reportQueryParams } from '@configs/queryParams';
import dateConstants from '@constants/date';
import { DateType } from '@typings/date';
import { ICompareParamsDefaultGraphs, IGraphs } from '@typings/graph';
import { IUpdateMediaParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { ReportName, IReportConfig, IUpdateReportParams, ReportNameBase } from '@typings/reports';

import DateUtils from '@utils/date';
import { measuresUtils } from '@utils/index';
import { isNumber } from '@utils/typesChecks';

const { DATE_FORMAT } = dateConstants;

const reportsUtilsFunc = ({ reportsConfig }) => {
    // private

    /**
     * Сравниваем графики из параметров с дефолтными графиками
     */
    const _compareWithDefaultGraphs = (params: ICompareParamsDefaultGraphs, reportName: ReportName): IGraphs[] => {
        const { graphArray, metrics, config, defaultGraphs } = params;
        const availableMetrics = config?.availableMetrics || [];
        const disabledGraphs = config?.disabledGraphs || {};
        const newGraphs = [...graphArray];

        newGraphs.forEach((graph, index, graphs) => {
            const { key: metric, value: type } = graph;

            // Если метрика есть в активных (показаны в таблице) или доступных для отчёта метриках
            if (metrics.includes(metric) || availableMetrics?.includes(metric)) {
                // Определяем доступные типы графиков для метрики
                const availableGraphs = measuresUtils.getAvailableGraphs(metric, disabledGraphs, reportName) || [];

                // Если в доступных типах графиков нет переданного типа,
                // берем 1-й тип из доступных типов графиков
                if (!availableGraphs.includes(type)) {
                    const newGraph = availableGraphs[0];

                    newGraphs[index] = {
                        key: metric,
                        value: newGraph,
                    };
                }
                // Иначе, сравниваем с defaultGraphs и устанавливаем тип графика оттуда
            } else if (metrics.includes(defaultGraphs[index].key)) {
                newGraphs[index] = defaultGraphs[index];
                // Или устанавливаем новую метрику и тип графика по умолчанию
            } else {
                const newMetric = metrics[index] || metrics[0];
                let newGraph = config.defaultGraphs[0];

                if (index !== 0 && graphs[0].value === newGraph) {
                    newGraph = config.defaultGraphs[index];
                }

                newGraphs[index] = {
                    key: newMetric,
                    value: newGraph,
                };
            }
        });

        return newGraphs;
    };

    /**
     * Получение графиков из конфига
     */
    const _getConfigDefaultGraphs = (
        config: IReportConfig,
    ): {
        defaultGraphs: IGraphs[];
        graphsUsed: {
            defaultGraphs: number;
        };
    } => {
        const defaultGraphs = [];
        const graphsUsed = {
            defaultGraphs: null,
        };
        let defaultGraph;

        for (let i = 0, len = config.graphDefaultMetrics.length; i < len; ++i) {
            defaultGraph = {
                key: config.graphDefaultMetrics[i],
                value: config.defaultGraphs[i],
            };

            defaultGraphs.push(defaultGraph);
            graphsUsed.defaultGraphs = i;
        }

        if (defaultGraphs.length === 1) {
            defaultGraphs.push(defaultGraphs);
        }

        return { defaultGraphs, graphsUsed };
    };

    // public

    /**
     * Убираем пустые значения
     */
    const filterParams = <T extends IUpdateReportParams | IUpdateMediaParams>(params: T): T => {
        Object.keys(params).filter((key) => {
            const item = reportQueryParams[key];

            if (!item || item.required) return true;

            if (item.type === 'int') {
                return params[key] !== '' || params[key] !== undefined;
            }

            return !!params[key];
        });

        return params;
    };

    const getConfig = (reportName: ReportName): IReportConfig => reportsConfig[reportName];

    const getParamFromConfig = (reportName: ReportName, paramName: string, defaultResult: unknown = false) => {
        const config = getConfig(reportName);

        if (config && paramName in config) {
            return config[paramName];
        }

        return defaultResult;
    };

    const isDisabledCheckbox = (reportName: ReportName): boolean =>
        getParamFromConfig(reportName, 'isDisabledCheckbox');

    const isGraphsNotUsed = (reportName: ReportName): boolean => !getParamFromConfig(reportName, 'numberOfGraphs');

    const canHideIndicator = (reportName: ReportName, indicator: Metrics): boolean => {
        const config = getConfig(reportName);

        return config?.tableHiddenMetrics?.includes(indicator);
    };

    const getDefaultGraphs = (
        reportName: ReportName,
        value: IGraphs[] | null,
        count: string,
        metrics: Metrics[],
    ): IGraphs[] => {
        const config = getConfig(reportName);
        const { defaultGraphs, graphsUsed } = _getConfigDefaultGraphs(config);
        let result;

        if (value && value.length > 0 && metrics?.length) {
            const params = {
                graphArray: value,
                metrics,
                config,
                defaultGraphs,
            };
            result = _compareWithDefaultGraphs(params, reportName);

            if (count === 'two' && result.length < 2) {
                // если есть график среди дефолтных, то берем другой дефолтный
                // если нет -- первый
                const positionInUsedGraphs = graphsUsed.defaultGraphs || 1;
                result.push(defaultGraphs[positionInUsedGraphs]);
            }
        } else {
            result = defaultGraphs;
        }

        if (count === 'one' && result.length > 1) {
            result = result.slice(0, 1);
        }

        return result;
    };

    const setDay = (reportName: ReportName, dayName: string): DateType => {
        const conf = getParamFromConfig(reportName, dayName);
        let date = 'today';

        if (isNumber(conf)) {
            date = DateUtils.someDaysAgo(conf).format(DATE_FORMAT);
        }

        return date;
    };

    const getDefaultParams = (reportName: ReportName): IUpdateReportParams => {
        const today = DateUtils.getToday();
        const items = DateUtils.getGroupFromPeriod([today, today]);
        const metrics = getParamFromConfig(reportName, 'tableDefaultMetrics');
        const dimensions = getParamFromConfig(reportName, 'tableFixedDimensions');
        const numberOfGraphs = getParamFromConfig(reportName, 'numberOfGraphs', null);
        const sort = getParamFromConfig(reportName, 'defaultSort');
        const isRating = reportName === 'rating';
        const defaultGraphs = getParamFromConfig(reportName, 'defaultGraphs', null);
        let graphs = numberOfGraphs !== null ? getDefaultGraphs(reportName, null, numberOfGraphs, metrics) : null;
        graphs = !graphs && defaultGraphs?.length ? [] : graphs;
        const split = !!getParamFromConfig(reportName, 'availableTools', {}).splits;
        const dateStart = setDay(reportName, 'dateStart');
        const dateEnd = setDay(reportName, 'dateEnd');
        const viewType = getParamFromConfig(reportName, 'viewTypeDefault', null);
        const activeSample = !!getParamFromConfig(reportName, 'availableTools', {}).activeSample;
        const globalFilters = !!getParamFromConfig(reportName, 'availableTools', {}).filters;
        const offset = getParamFromConfig(reportName, 'defaultOffset', null);
        const limit = getParamFromConfig(reportName, 'defaultLimit');

        return {
            dateStart,
            dateEnd,
            groupBy: items[0],
            metrics,
            ...(dimensions ? { dimensions } : {}),
            // prettier-ignore
            ...(sort ? {
                orderBy: { key: sort.name, value: sort.order },
            } : {}),
            ...(graphs ? { graphs } : {}),
            ...(split ? { split: null } : {}),
            // prettier-ignore
            ...(isRating ? {
                categoryId: '0',
            } : {}),
            ...(viewType ? { viewType } : {}),
            ...(activeSample ? { sample: null } : {}),
            ...(globalFilters ? { globalFilters: [] } : {}),
            tableFilters: [],
            ...(offset !== null ? { offset } : {}),
            ...(limit ? { limit } : {}),
        };
    };

    const isSummary = (reportName: ReportNameBase): boolean => reportName === 'summary';

    const isRating = (reportName: ReportNameBase): boolean => reportName === 'rating';

    const isConstructor = (reportName: ReportNameBase): boolean => reportName === 'constructor';

    return Object.freeze({
        getConfig,
        getParamFromConfig,
        isDisabledCheckbox,
        canHideIndicator,
        isGraphsNotUsed,
        getDefaultGraphs,
        getDefaultParams,
        filterParams,
        isSummary,
        isRating,
        isConstructor,
        _compareWithDefaultGraphs, // для вызовы в unit тестах
    });
};

export default reportsUtilsFunc;
