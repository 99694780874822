import { Modal, TableSettingsList, TTableSettingsListItemProps } from '@adtech/ui';
import s from '@components/Funnel/Funnel.pcss';
import { columnsKeys, defaultTableColumns } from '@configs/funnels/defaultTableColumns';
import { metricsDict } from '@configs/metrics';
import { Metrics } from '@typings/metrics';
import React, { useEffect, useRef, useState } from 'react';

interface IProps {
    isOpen: boolean;
    onOkHandler: (metricsSelected?: Metrics[]) => void;
    onCloseHandler: () => void;
    metrics: Metrics[];
    allMetrics: Metrics[];
}

const TableSettingsModal: React.FC<IProps> = ({ isOpen, onOkHandler, onCloseHandler, metrics, allMetrics }) => {
    const defaultColumns = [
        {
            id: columnsKeys[0],
            checked: true,
            onChange: () => {},
            disabled: true,
            sortDisabled: true,
            title: defaultTableColumns[columnsKeys[0]],
        },
        {
            id: columnsKeys[1],
            checked: true,
            onChange: () => {},
            disabled: true,
            sortDisabled: true,
            title: defaultTableColumns[columnsKeys[1]],
        },
    ];
    const transformToItems = (metricsValues: Metrics[]) =>
        metricsValues?.map((metric) => ({
            id: metric,
            checked: metrics.includes(metric),
            onChange: () => {},
            title: metricsDict[metric]?.title || '',
        }));
    const [columns, setColumns] = useState(transformToItems(allMetrics));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const selectedItems = useRef<TTableSettingsListItemProps[]>(null);

    useEffect(() => {
        setColumns(transformToItems(allMetrics));
    }, [JSON.stringify(metrics), JSON.stringify(allMetrics)]);

    const onChangeSettings = (values) => {
        selectedItems.current = values;
    };

    const modalOkHandler = () => {
        const itemsSelected = selectedItems.current;

        if (itemsSelected) {
            const selectedMetrics = itemsSelected
                ?.filter((item) => item?.checked && !item?.disabled)
                .map((item) => item?.id) as Metrics[];

            onOkHandler(selectedMetrics);
        } else {
            onOkHandler();
        }
    };

    return (
        <Modal
            className={s.funnelsTableSettingsModal}
            isOpened={isOpen}
            title="Настройка таблицы"
            description="Настройка отображения столбцов и их порядка в таблице"
            okText="Сохранить"
            cancelText="Отменить"
            onOk={modalOkHandler}
            onClose={onCloseHandler}
            closeOnClickOutside={false}
            centered
            size="lightbox"
        >
            <div className={s.funnelsTableSettingsModalTitle} data-testid="help-text">
                Чтобы изменить список и порядок отображаемых колонок в таблице, отметьте необходимые вам метрики
                галочкой
            </div>
            <TableSettingsList
                items={[...defaultColumns, ...columns]}
                onChange={onChangeSettings}
                className={s.funnelsTableSettingsModalSettings}
            />
        </Modal>
    );
};

export default TableSettingsModal;
