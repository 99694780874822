import { projectsTableConstants } from '@constants/projects';
import {
    create,
    deleteProject,
    getCaptcha,
    getConfig, // не используется
    getMetadata,
    getProject,
    getProjectsTable,
    getProjectsWithSameUrl,
    remove, // не используется
    restoreCounter,
    restoreProject,
    save,
} from '@redux/slices/projects/api';
import setProjectRecord from '@redux/slices/projects/record';
import { createSlice } from '@reduxjs/toolkit';
import { IProjectsSlice } from '@typings/rootSlice';

/**
 * Initial state
 */
export const initialState: IProjectsSlice = {
    total: 0,
    list: [],
    map: {},
    current: setProjectRecord(),
    projectRequest: false,
    deleteRequest: false,
    tableRequest: false,
    tableLastReqId: 0,
    tableData: {
        data: [],
        meta: {
            total_rows: 0,
            deleted_rows: 0,
            limit: projectsTableConstants.TABLE_LIMIT_ROW,
        },
    },
    tableSearch: '',
    captchaRequest: false,
    needCaptcha: null,
    defaultTitle: null,
    createCounterRequest: false,
    saveCounterRequest: false,
    createdCounter: {},
    difference: [],
    today: false,
    yesterday: false,
};

const updateProjectFunc = (state: IProjectsSlice, action) => {
    const { current, list } = state;
    const { projectId, params } = action.payload;

    if (current.id === parseInt(projectId, 10)) {
        state.current = { ...current, ...params };
    }

    state.list = list.map((item) => {
        const newItem = { ...item };

        if (item.id === parseInt(projectId, 10)) {
            return { ...newItem, ...params };
        }

        return newItem;
    });
};

/**
 * Устанавливаем приехавшие данные таблицы проектов
 */
const updateProjectsTableFunc = (state: IProjectsSlice, action) => {
    const { result, offset, lastReqId }: { result: any; offset: number; search: string; lastReqId: number } =
        action.payload;
    const data = result.map((item) => setProjectRecord(item, { withoutBaseCategory: true }));

    if (lastReqId < state.tableLastReqId) return state;

    const meta = {
        ...action.payload.meta,
        limit: initialState.tableData.meta.limit,
    };

    const tableData = offset > 0 ? { ...state.tableData, data: [...state.tableData.data, ...data] } : { data, meta };

    state.tableRequest = false;
    state.tableData = tableData;
};

/**
 * Включаем режим загрузки данных о проекте
 * @param state
 * @returns {*}
 */
export const setProjectRequest = (state: IProjectsSlice): void => {
    state.current = setProjectRecord();
    state.projectRequest = true;
};

/**
 * Запись полученных данных о проекте
 * @param state
 * @param action
 * @returns {*}
 */
export const setProject = (state: IProjectsSlice, action): void => {
    const { data } = action.payload;

    if (!data) return;

    const record = setProjectRecord(data);

    if (data.id in state.map) {
        const projectPos = state.list.findIndex((project) => project.id === data.id);
        state.list[projectPos] = record;
    } else {
        state.map[data.id] = record;
        state.list.push(record);
    }

    state.total = state.list.length;
    state.current = JSON.parse(JSON.stringify(record));
    state.projectRequest = false;

    // FIXME: костыль, отчёты пока не приходят с бэка
    // Костыль для целей, потому что такого отчета нет
    state.current.available_reports.push(
        ...(state.current.available_reports.includes('conversions')
            ? ['goals', 'post_click', 'import_data_from_ym']
            : []),
        'export',
    );
};

/**
 * Обновляем параметры в стейте
 */
export const updateRequestParamsFunc = (state: IProjectsSlice, action) => {
    Object.assign(state, action.payload);
};

/**
 * Включаем режим загрузки данных у таблицы проектов
 */
export const setRequestProjectsTable = (state: IProjectsSlice, action): void => {
    state.tableRequest = true;
    state.tableLastReqId = action?.meta?.arg.lastReqId;
};

/**
 * Удаляем проект из таблицы
 */
export const setDeleteRequest = (state: IProjectsSlice): void => {
    state.deleteRequest = true;
};

export const deleteProjectInTable = (state: IProjectsSlice, action): void => {
    const data = state.tableData.data.filter((item) => item.id !== action.payload.metaInfo.projectId);
    const totalRows = state.tableData.meta.total_rows - 1;
    const deletedRows = state.tableData.meta.deleted_rows + 1;

    state.deleteRequest = false;
    state.tableData.data = data;
    state.tableData.meta.total_rows = totalRows;
    state.tableData.meta.deleted_rows = deletedRows;
};

/**
 * Ожидание каптчи
 */
export const setCaptchaRequest = (state: IProjectsSlice): void => {
    state.captchaRequest = true;
};

/**
 * Получение данных каптчи
 */
export const setCaptcha = (state: IProjectsSlice, action): void => {
    state.needCaptcha = action.payload?.data.need_captcha;
    state.captchaRequest = false;
};

/**
 * Установка title
 */
export const setDefaultTitle = (state: IProjectsSlice, action): void => {
    state.defaultTitle = action.payload?.title;
};

export const setCreatedCounterRequest = (state: IProjectsSlice): void => {
    state.createCounterRequest = true;
};

export const setCreatedCounter = (state: IProjectsSlice, action): void => {
    state.createCounterRequest = false;
    state.createdCounter = action.payload;
};

export const setCreatedCounterFailure = (state: IProjectsSlice): void => {
    state.createCounterRequest = false;
};

export const setSaveCounterRequest = (state: IProjectsSlice): void => {
    state.saveCounterRequest = true;
};

export const setFailureCounterRequest = (state: IProjectsSlice): void => {
    state.saveCounterRequest = false;
};

export const setSaveCounterReceive = (state: IProjectsSlice, action): void => {
    state.current = setProjectRecord(action.payload.data);
    state.saveCounterRequest = false;
};

export const requestProjectsWithSameUrl = (state: IProjectsSlice): void => {
    state.projectsWithSameUrlRequest = true;
};

/**
 * Получение счётчиков с таким же url, для восстановления
 */
export const setProjectsWithSameUrl = (state: IProjectsSlice, action): void => {
    const { data, limit, offset, total } = action.payload;
    state.projectsWithSameUrl = {
        data: data.map((dataItem) => ({
            title: dataItem.title,
            url: dataItem.url,
            id: dataItem.project_id,
            created: dataItem.created,
            favicon: dataItem.favicon,
            inRating: dataItem.in_rating,
        })),
        limit,
        offset,
        total,
        originUrl: action.payload.metaInfo.url,
    };
    state.projectsWithSameUrlRequest = false;
};

export const setFailureProjectsWithSameUrl = (state: IProjectsSlice): void => {
    state.projectsWithSameUrlRequest = false;
};

export const requestRestoreCounter = (state: IProjectsSlice): void => {
    state.restoreCounterRequest = true;
};

export const restoreCounterFunc = (state: IProjectsSlice): void => {
    state.restoreCounterRequest = false;
};

export const resetParamFunc = (state: IProjectsSlice, action) => {
    const param = action.payload;

    if (!(param in initialState)) return state;

    state[param] = initialState[param];
};

const projectsSlice = createSlice({
    name: 'projectsSlice',
    initialState,
    reducers: {
        clearCurrentProject(state) {
            state.current = setProjectRecord();
        },
        updateProject(state, action) {
            updateProjectFunc(state, action);
        },
        updateProjectsTable(state, action) {
            updateProjectsTableFunc(state, action);
        },
        clearCreatedCounter(state) {
            state.createdCounter = {};
        },
        clearProjectsWithSameUrl(state) {
            state.projectsWithSameUrl = {};
        },
        changeStatus(state, action) {
            state.current.status = action.payload;
        },
        updateRequestParams: (state, action) => updateRequestParamsFunc(state, action),
        resetParam: (state, action) => resetParamFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProject.pending, (state) => setProjectRequest(state))
            .addCase(getProject.fulfilled, (state, action) => setProject(state, action))

            .addCase(getProjectsTable.pending, (state, action) => setRequestProjectsTable(state, action))

            .addCase(deleteProject.pending, (state) => setDeleteRequest(state))
            .addCase(deleteProject.fulfilled, (state, action) => deleteProjectInTable(state, action))
            .addCase(restoreProject.pending, (state) => setDeleteRequest(state))
            .addCase(restoreProject.fulfilled, (state, action) => deleteProjectInTable(state, action))

            .addCase(getCaptcha.pending, (state) => setCaptchaRequest(state))
            .addCase(getCaptcha.fulfilled, (state, action) => setCaptcha(state, action))

            .addCase(getMetadata.fulfilled, (state, action) => setDefaultTitle(state, action))

            .addCase(create.pending, (state) => setCreatedCounterRequest(state))
            .addCase(create.fulfilled, (state, action) => setCreatedCounter(state, action))
            .addCase(create.rejected, (state) => setCreatedCounterFailure(state))

            .addCase(save.pending, (state) => setSaveCounterRequest(state))
            .addCase(save.fulfilled, (state, action) => setSaveCounterReceive(state, action))
            .addCase(save.rejected, (state) => setFailureCounterRequest(state))

            .addCase(getProjectsWithSameUrl.pending, (state) => requestProjectsWithSameUrl(state))
            .addCase(getProjectsWithSameUrl.fulfilled, (state, action) => setProjectsWithSameUrl(state, action))
            .addCase(getProjectsWithSameUrl.rejected, (state) => setFailureProjectsWithSameUrl(state))

            .addCase(restoreCounter.pending, (state) => requestRestoreCounter(state))
            .addCase(restoreCounter.fulfilled, (state) => restoreCounterFunc(state))
            .addCase(restoreCounter.rejected, (state) => restoreCounterFunc(state));
    },
});

export const {
    clearCurrentProject,
    updateProject,
    updateProjectsTable,
    clearCreatedCounter,
    clearProjectsWithSameUrl,
    changeStatus,
    updateRequestParams,
    resetParam,
} = projectsSlice.actions;

export const projectsActions = {
    clearCurrentProject,
    updateProject,
    updateProjectsTable,
    clearCreatedCounter,
    clearProjectsWithSameUrl,
    changeStatus,
    updateRequestParams,
    getProject,
    getProjectsTable,
    deleteProject,
    restoreProject,
    getCaptcha,
    getMetadata,
    create,
    save,
    remove,
    getConfig,
    getProjectsWithSameUrl,
    restoreCounter,
    resetParam,
};

export default projectsSlice.reducer;
