import { createSlice } from '@reduxjs/toolkit';
import { IPostClickSlice } from '@typings/rootSlice';
import {
    createCounter,
    getCounter,
    deleteCounter,
    linkCounter,
    unlinkCounter,
    updateGoals,
    updateImportFromYM,
} from './api';

export const initialState: IPostClickSlice = {
    counter: {
        id: null,
        is_active: false,
        project_id: null,
        import_from_ym: false,
    },
    counterRequest: false,
    counterRequestError: false,
    counterIsCreated: false,
    firstRequest: false,
    requestUpdateGoals: false,
    goalUpdateRequestError: false,
    selectedGoalsIds: [],
};

const setRequest = (state) => {
    state.firstRequest = false;
    state.counterRequest = true;
};

const setFailure = (state) => {
    state.counterRequest = false;
};

const setCounterData = (state, action) => {
    const { data } = action.payload || {};

    state.counterRequest = false;

    if (data) {
        state.counterIsCreated = true;
        state.counter = action.payload.data;
    }
};

const setCreatedCounterData = (state, action) => {
    const { data, errors, yandex_errors } = action.payload || {};

    state.firstRequest = true;
    state.counterRequest = false;

    if (errors || yandex_errors) {
        state.counterRequestError = true;
    }

    if (data) {
        state.counterIsCreated = true;
        state.counter = data;
        state.counterRequestError = false;
    }
};

const setRequestUpdateGoals = (state: IPostClickSlice) => {
    state.requestUpdateGoals = true;
};

const failureUpdateGoals = (state: IPostClickSlice) => {
    state.requestUpdateGoals = false;
    state.goalUpdateRequestError = true;
};

const updateGoalsFunc = (state: IPostClickSlice) => {
    state.requestUpdateGoals = false;
};

const updateSelectedGoalsFunc = (state: IPostClickSlice, action) => {
    state.selectedGoalsIds = action.payload.listIds;
};

// TODO: разобраться с firstRequest и переделать под setRequest
const setImportFromYMRequest = (state: IPostClickSlice, request: boolean = false) => {
    state.counterRequest = request;
};

const setImportFromYM = (state: IPostClickSlice, action) => {
    if (state.counter) state.counter.import_from_ym = action.payload?.data?.import_from_ym || false;
};

const resetCounterErrorFunc = (state: IPostClickSlice) => {
    state.counterRequestError = false;
};

const resetCounter = () => initialState;

const postClickSlice = createSlice({
    name: 'postClickSlice',
    initialState,
    reducers: {
        resetCounterData: resetCounter,
        resetCounterError: (state) => resetCounterErrorFunc(state),
        updateSelectedGoals: (state, action) => updateSelectedGoalsFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCounter.pending, (state) => setRequest(state))
            .addCase(getCounter.fulfilled, (state, action) => setCounterData(state, action))
            .addCase(getCounter.rejected, (state) => setFailure(state))

            .addCase(createCounter.pending, (state) => setRequest(state))
            .addCase(createCounter.fulfilled, (state, action) => setCreatedCounterData(state, action))
            .addCase(createCounter.rejected, (state) => setFailure(state))

            .addCase(linkCounter.pending, (state) => setRequest(state))
            .addCase(linkCounter.fulfilled, (state, action) => setCreatedCounterData(state, action))
            .addCase(linkCounter.rejected, (state) => setFailure(state))

            .addCase(deleteCounter.pending, (state) => setRequest(state))
            .addCase(deleteCounter.fulfilled, () => resetCounter())
            .addCase(deleteCounter.rejected, (state) => setFailure(state))

            .addCase(updateGoals.pending, (state) => setRequestUpdateGoals(state))
            .addCase(updateGoals.fulfilled, (state) => updateGoalsFunc(state))
            .addCase(updateGoals.rejected, (state) => failureUpdateGoals(state))

            .addCase(updateImportFromYM.pending, (state) => setImportFromYMRequest(state, true))
            .addCase(updateImportFromYM.fulfilled, (state, action) => setImportFromYM(state, action))
            .addCase(updateImportFromYM.rejected, (state) => setImportFromYMRequest(state));
    },
});

export const { resetCounterData, updateSelectedGoals, resetCounterError } = postClickSlice.actions;

export const postClickActions = {
    createCounter,
    getCounter,
    linkCounter,
    unlinkCounter,
    deleteCounter,
    resetCounterData,
    resetCounterError,
    updateGoals,
    updateSelectedGoals,
    updateImportFromYM,
};
export default postClickSlice.reducer;
