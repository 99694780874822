import { Loader } from '@adtech/ui';
import GReCaptcha from '@components/GReCaptcha';
import captchaConfig from '@configs/captcha';
import React, { useState, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import s from '../../FirstStep.pcss';

interface IProps {
    loading: boolean;
}

const Captcha: React.FC<IProps> = ({ loading }) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({ name: 'captcha_response', control });

    const [isLoadCaptcha, setIsLoadCaptcha] = useState(true);
    const [resetCaptcha, setResetCaptcha] = useState(false);

    useEffect(() => {
        if (error) setResetCaptcha(true);
    }, [error]);

    const setCaptchaField = (value: string) => {
        field.onChange(value);
    };

    const onLoadCaptcha = () => {
        setIsLoadCaptcha(false);
    };

    return (
        <div className={s.captchaRow}>
            <Loader loading={loading || isLoadCaptcha}>
                <GReCaptcha
                    resetCaptcha={resetCaptcha}
                    elementID="g-recaptcha"
                    siteKey={captchaConfig.publicKey}
                    hl={captchaConfig.hl}
                    callback={setCaptchaField}
                    theme="light"
                    size="normal"
                    onloadCallback={onLoadCaptcha}
                />
                {error && <span>{error.message}</span>}
            </Loader>
        </div>
    );
};

export default Captcha;
