import { Button, IconEraser, Tooltip, IconInformationSign } from '@adtech/ui';
import cn from 'classnames';
import * as React from 'react';

import s from './FilterFormHeader.pcss';

interface IProps {
    label: string;
    clearFilter?: () => void;
    hint?: string;
    isLabelBold?: boolean;
}

const FilterFormHeader: React.FC<IProps> = ({ label, clearFilter, hint, isLabelBold }) => {
    const headerClass = cn(s.filterFormHeader, { [s.filterFormHeaderSimple]: isLabelBold });

    const hintHtml = <span dangerouslySetInnerHTML={{ __html: hint }} />;

    return (
        <div className={headerClass}>
            <div className={s.filterFormHeaderTitle}>
                {isLabelBold ? <span className={s.filterFormHeaderTitleText}>{label}</span> : label}
                {hint ? (
                    <Tooltip className={s.filterFormHeaderHint} title={hintHtml}>
                        <IconInformationSign />
                    </Tooltip>
                ) : null}
            </div>
            {clearFilter ? (
                <Button
                    className={s.filterFormHeaderClearBtn}
                    onClick={clearFilter}
                    type="link"
                    prefixIcon={<IconEraser />}
                    size="small"
                />
            ) : null}
        </div>
    );
};

export default FilterFormHeader;
