import { IDynamicFilterItem } from '@components/CommonFilters/types/filters';
import dimensionsValues, { dimensionEmptyValue } from '@configs/dimensionsValues';
import { filtersCnf } from '@configs/filters/filters';
import { GroupBy } from '@configs/group';
import dateConst from '@constants/date';
import { EPeriodDetail } from '@constants/periodDetail';
import { createSlice } from '@reduxjs/toolkit';
import { IGlobalSlice } from '@typings/rootSlice';
import listDataUtils from '@utils/listDataUtils';
import { isString } from '@utils/typesChecks';
import {
    getServerTime,
    getRegionsConfig,
    getTypesConfig,
    getThemesConfig,
    getSavedFilters,
    saveFilter,
    deleteSavedFilter,
    updateSavedFilter,
    getDynamicFilters,
    getGeoIdsDict,
    getCategoryIdsDict,
} from './api';

export const initialState: IGlobalSlice = {
    error: {},
    snackbar: { message: null, type: null },
    modalPopup: {
        modalPopupId: null,
        modalPopupParams: null,
        isShowPopup: false,
    },
    globalFilters: [],
    dynamicFiltersData: {},
    filtersDimensionsDict: {},
    savedFiltersRequest: false,
    savedFiltersList: [],
    savedFilterCurrentId: null,
    dateStart: dateConst.DEFAULT_DATE,
    dateEnd: dateConst.DEFAULT_DATE,
    groupBy: GroupBy.fiveMinute,
    periodDetail: EPeriodDetail.thirtyMinutes,
    globalLoading: true,
    serverTime: new Date().getTime(),
    timeOffset: 0,
    projectConfig: {
        regions: {
            loaded: false,
            loading: false,
            data: null,
        },
        types: {
            loaded: false,
            loading: false,
            data: null,
        },
        categories: {
            loaded: false,
            loading: false,
            data: null,
        },
    },
    notification: null,
    modalNotification: null,
    categoryId: null,
    isNavigationOpen: true,
};

export const setServerTimeAndOffset = (state: IGlobalSlice, action) => {
    const { result } = action.payload;

    const date = new Date().getTime();
    let timeOffset = date - result; // ms
    // Если разница меньше минуты - не учитываем
    timeOffset = Math.abs(timeOffset) > 60 * 1000 ? timeOffset : 0;

    state.serverTime = result;
    state.timeOffset = timeOffset;
};

/**
 * Запись полученного конфига для создания счётчика
 */
export const setProjectConfigData = (type: string, state: IGlobalSlice, action) => {
    state.projectConfig[type].data = listDataUtils.toFlatConfigData(action.payload.data);
    state.projectConfig[type].loading = false;
    state.projectConfig[type].loaded = true;
};

/**
 * Устанавливаем запрос конфига
 */
export const setProjectConfigRequest = (type: string, state: IGlobalSlice) => {
    state.projectConfig[type].loading = true;
    state.projectConfig[type].loaded = false;
};

/**
 * Включаем режим загрузки сохраненных фильтров
 */
export const setSavedFiltersRequest = (state: IGlobalSlice, isPending?: boolean) => {
    state.savedFiltersRequest = isPending || false;
};

/**
 * Устанавливаем полученные сохраненные фильтры в список
 */
export const setSavedFilters = (state: IGlobalSlice, action) => {
    const { result } = action.payload;

    // Преобразовываем сохраненные фильтры под формат globalFilters
    // prettier-ignore
    const savedFiltersList = result.length ? result.map((filter) => {
        const filters = [...(filter.body.dimension_filters || []),
            ...(filter.body.metric_filters || [])];

        return {
            id: filter.id,
            title: filter.title,
            type: filter.type,
            filters,
        };
    }) : [];

    state.savedFiltersRequest = false;
    state.savedFiltersList = savedFiltersList;
};

/**
 * Сохраняем полученный фильтр
 */
export const saveSavedFilter = (state: IGlobalSlice, action) => {
    const { result } = action.payload;
    const filters = [...(result.body.dimension_filters || []), ...(result.body.metric_filters || [])];

    const savedFilter = {
        id: result.id,
        title: result.title,
        type: result.type,
        filters,
    };

    state.savedFiltersRequest = false;
    state.savedFiltersList = [...(state.savedFiltersList || []), savedFilter];
    state.savedFilterCurrentId = savedFilter.id;
};

/**
 * Устанавливаем успешное выполнение удаления фильтра
 */
export const deleteSavedFilterHandler = (state: IGlobalSlice, action) => {
    const data = state.savedFiltersList.filter((item) => Number(item.id) !== action.payload?.metaInfo.id);

    state.savedFiltersRequest = false;
    state.savedFiltersList = data;
};

/**
 * Обновление сохраненного фильтра
 */
export const updateSavedFilterHandler = (state: IGlobalSlice, action) => {
    const { result } = action.payload;
    const filters = [...(result.body.dimension_filters || []), ...(result.body.metric_filters || [])];

    const savedFilter = {
        id: result.id,
        title: result.title,
        type: result.type,
        filters,
    };

    state.savedFiltersList = state.savedFiltersList.map((item) => {
        if (item.id === savedFilter.id) return savedFilter;
        return item;
    });

    state.savedFiltersRequest = false;
};

/**
 * Включаем режим загрузки данных для фильтрации
 */
export const setDynamicFiltersRequest = (state: IGlobalSlice, action) => {
    const { filterName } = action.meta?.arg || {};

    state.dynamicFiltersData[filterName] = {
        ...(state.dynamicFiltersData[filterName] || {}),
        loading: true,
    } as IDynamicFilterItem;
};

/**
 * Устанавливаем полученные данные для динамических фильтров
 */
export const setDynamicFilters = (state: IGlobalSlice, action) => {
    const { filterName } = action.meta?.arg || {};
    const { globalFilters } = state;
    let filterValues = [];

    // Определяем дименшн фильтра по filterName
    const dimension =
        Object.keys(filtersCnf).find((key) => filtersCnf[key]?.dynamicFilterAlias === filterName) || filterName;

    // Уже имеющиеся значения динамического фильтра в глобальных фильтрах
    // Добавляем их в начало списка динамических данных,
    // а остальные данные фильтруем, чтобы избежать дубликатов
    if (dimension !== 'event_name') {
        filterValues = globalFilters
            .filter((filter) => filter.key === dimension)
            .reduce((acc, { value }) => [...acc, ...(isString(value) ? [value] : value)], []);
    }

    let dynamicFiltersData = action.payload?.result?.data
        .map((item) => {
            let value;
            let label = item.id;

            if (dimension === 'event_name') {
                value = item.event_name;

                return {
                    label: value,
                    value,
                    key: `${item.id}::${value}`,
                };
            }

            value = String(
                item.geo_country_id || item.geo_area_id || item.geo_city_id || item.category_1_id || item.id,
            );

            // Конвертируем некоторые названия в кириллицу по словарю
            if (!item.geo_country_id && !item.geo_area_id && !item.geo_city_id && !item.category_1_id) {
                label = dimensionsValues[dimension]?.[item.id]?.title || item.id || dimensionEmptyValue;
            }

            // Проверяем есть ли в значениях с бэка данного динам. фильтра значения из глобальных фильтров
            // Если да, то убираем их из данных
            if (filterValues.includes(value)) return null;

            return {
                label,
                value,
                key: value,
            };
        })
        .filter((val) => val);

    // Добавляем значения в динамические данные при первой подгрузке,
    // чтобы корректно отобразить значения в формах
    if (!state.dynamicFiltersData[filterName]?.data) {
        filterValues.forEach((val) => {
            dynamicFiltersData = [
                {
                    label:
                        state.filtersDimensionsDict[dimension]?.[val] ||
                        dimensionsValues[dimension]?.[val]?.title ||
                        val ||
                        dimensionEmptyValue,
                    value: val,
                    key: val,
                },
                ...dynamicFiltersData,
            ];
        });
    }

    state.dynamicFiltersData[filterName] = {
        ...(state.dynamicFiltersData[filterName] || {}),
        data: [...(state.dynamicFiltersData[filterName]?.data || []), ...dynamicFiltersData],
        loading: false,
        // eslint-disable-next-line no-unsafe-optional-chaining
        totals: action?.payload?.meta?.total || 0,
    };
};

export const setGeoIdsDict = (state: IGlobalSlice, action) => {
    const { result } = action.payload || {};

    state.filtersDimensionsDict.geo_country = { ...(state.filtersDimensionsDict.geo_country || {}), ...result };
    state.filtersDimensionsDict.geo_area = { ...(state.filtersDimensionsDict.geo_area || {}), ...result };
    state.filtersDimensionsDict.geo_city = { ...(state.filtersDimensionsDict.geo_city || {}), ...result };
};

export const setCategoryIdsDict = (state: IGlobalSlice, action) => {
    const { result } = action.payload || {};

    state.filtersDimensionsDict.category_1 = { ...(state.filtersDimensionsDict.category_1 || {}), ...result };
};

const globalSlice = createSlice({
    name: 'globalSlice',
    initialState,
    reducers: {
        updateParams(state, action) {
            Object.assign(state, action.payload);
        },
        setError(state, action) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = {};
        },
        setSnackbar(state, action) {
            state.snackbar = action.payload;
        },
        clearSnackbar(state) {
            state.snackbar.message = null;
        },
        setNotification(state, action) {
            state.notification = action.payload;
        },
        clearNotification(state) {
            state.notification = null;
        },
        setModalNotification(state, action) {
            state.modalNotification = action.payload;
        },
        clearModalNotification(state) {
            state.modalNotification = null;
        },
        openModalPopup(state, action) {
            state.modalPopup = {
                modalPopupId: action.payload.modalPopupId,
                modalPopupParams: action.payload.modalPopupParams || {},
                isShowPopup: true,
            };
        },
        clearModalPopup(state) {
            state.modalPopup = {
                modalPopupId: null,
                modalPopupParams: null,
                isShowPopup: false,
            };
        },
        setGlobalLoading(state, action) {
            state.globalLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServerTime.fulfilled, (state, action) => setServerTimeAndOffset(state, action))

            .addCase(getRegionsConfig.pending, (state) => setProjectConfigRequest('regions', state))
            .addCase(getRegionsConfig.fulfilled, (state, action) => setProjectConfigData('regions', state, action))

            .addCase(getTypesConfig.pending, (state) => setProjectConfigRequest('types', state))
            .addCase(getTypesConfig.fulfilled, (state, action) => setProjectConfigData('types', state, action))

            .addCase(getThemesConfig.pending, (state) => setProjectConfigRequest('categories', state))
            .addCase(getThemesConfig.fulfilled, (state, action) => setProjectConfigData('categories', state, action))

            .addCase(getSavedFilters.pending, (state) => setSavedFiltersRequest(state, true))
            .addCase(getSavedFilters.fulfilled, (state, action) => setSavedFilters(state, action))
            .addCase(getSavedFilters.rejected, (state) => setSavedFiltersRequest(state))

            .addCase(saveFilter.pending, (state) => setSavedFiltersRequest(state, true))
            .addCase(saveFilter.fulfilled, (state, action) => saveSavedFilter(state, action))
            .addCase(saveFilter.rejected, (state) => setSavedFiltersRequest(state))

            .addCase(deleteSavedFilter.pending, (state) => setSavedFiltersRequest(state, true))
            .addCase(deleteSavedFilter.fulfilled, (state, action) => deleteSavedFilterHandler(state, action))
            .addCase(deleteSavedFilter.rejected, (state) => setSavedFiltersRequest(state))

            .addCase(updateSavedFilter.pending, (state) => setSavedFiltersRequest(state, true))
            .addCase(updateSavedFilter.fulfilled, (state, action) => updateSavedFilterHandler(state, action))
            .addCase(updateSavedFilter.rejected, (state) => setSavedFiltersRequest(state))

            .addCase(getDynamicFilters.pending, (state, action) => setDynamicFiltersRequest(state, action))
            .addCase(getDynamicFilters.fulfilled, (state, action) => setDynamicFilters(state, action))

            .addCase(getGeoIdsDict.fulfilled, (state, action) => setGeoIdsDict(state, action))
            .addCase(getCategoryIdsDict.fulfilled, (state, action) => setCategoryIdsDict(state, action));
    },
});

export const {
    updateParams,
    setError,
    clearError,
    setSnackbar,
    clearSnackbar,
    setNotification,
    clearNotification,
    setModalNotification,
    clearModalNotification,
    openModalPopup,
    clearModalPopup,
    setGlobalLoading,
} = globalSlice.actions;

export const globalActions = {
    getServerTime,
    getRegionsConfig,
    getTypesConfig,
    getThemesConfig,
    getSavedFilters,
    saveFilter,
    deleteSavedFilter,
    updateSavedFilter,
    getDynamicFilters,
    getGeoIdsDict,
    getCategoryIdsDict,
    updateParams,
    setError,
    clearError,
    setSnackbar,
    clearSnackbar,
    setNotification,
    clearNotification,
    setModalNotification,
    clearModalNotification,
    openModalPopup,
    clearModalPopup,
    setGlobalLoading,
};

export default globalSlice.reducer;
