import { Radio } from '@adtech/ui';
import React from 'react';

import s from '../AccessUsers.pcss';

interface IProps {
    value: string;
    setValue: (value: EAccessValues) => void;
    disabled?: boolean;
}

export enum EAccessValues {
    view = 'view',
    edit = 'edit',
}

const RadioField: React.FC<IProps> = ({ disabled = false, value, setValue }: IProps) => {
    const items = [
        {
            title: 'Только просмотр',
            value: EAccessValues.view,
        },
        {
            title: 'Редактирование',
            value: EAccessValues.edit,
        },
    ];

    const renderRadio = (item, index) => (
        <Radio
            name="accessLevel"
            value={item.value}
            disabled={disabled}
            onChange={(e) => setValue(e.target.value)}
            checked={item.value === value}
            key={index}
        >
            {item.title}
        </Radio>
    );

    return <div className={s.radioGroup}>{items.map(renderRadio)}</div>;
};

export default RadioField;
