import { Input } from '@adtech/ui';
import validationMessages from '@constants/validationMessages';
import { formUtils } from '@utils/index';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import s from '../../FirstStep.pcss';

interface IProps {
    getDefaultTitle: (url: string) => void;
}

const Url: React.FC<IProps> = ({ getDefaultTitle }) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'url',
        control,
        rules: {
            required: {
                value: true,
                message: 'Поле не заполнено',
            },
            validate: (val) =>
                (formUtils.rules.urlWithDomainZone(val) && formUtils.rules.urlWithProtocolAndParams(val)) ||
                validationMessages.urlWithDomainZone,
        },
    });

    const { onBlur, onChange, value, name, ref } = field;

    const handleBlur = () => {
        const isValid =
            formUtils.rules.required(value) &&
            formUtils.rules.urlWithDomainZone(value) &&
            formUtils.rules.urlWithProtocolAndParams(value);

        if (isValid) getDefaultTitle(value);
        onBlur();
    };

    return (
        <div className={s.formRow}>
            <span>Адрес сайта</span>
            <Input
                value={value}
                name={name}
                placeholder="http://"
                type="text"
                errorText={error?.message || ''}
                onBlur={handleBlur}
                maxLength={200}
                onChange={onChange}
                status={error ? 'error' : ''}
                inputRef={ref}
            />
        </div>
    );
};

export default Url;
