import AttributionModel, { EAttributionModel } from '@components/AttributionModel';
import AttributionWindow from '@components/AttributionWindow/';
import CommonFilters from '@components/CommonFilters';
import { TFilters, TFiltersListCnf } from '@components/CommonFilters/types/filters';
import NumberOfGraphs from '@components/NumberOfGraphs';
import SampleField from '@components/SampleField';
import dimensionsValues from '@configs/dimensionsValues';
import { filtersCnf as filtersFormCnf } from '@configs/filters/filters';
import { GroupBy } from '@configs/group';
import reportsParamsConfig from '@configs/reportsParams';
import { EPeriodDetail } from '@constants/periodDetail';
import useFilters from '@hooks/useFilters';
import { IAvailableTools, IPeriodDetailData } from '@typings/availableTools';
import { DateType, DateLiteral } from '@typings/date';
import { IGraphs } from '@typings/graph';
import { IUpdateMediaParams } from '@typings/media';
import { Metrics } from '@typings/metrics';
import { IUpdateReportOptions, IUpdateReportParams, ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import PeriodPicker from './PeriodPicker';

import s from './Tools.pcss';

interface IProps {
    availableTools: IAvailableTools;
    reportsUtils: Record<string, TCallback<unknown, any>>;
    getTypeRequestReport: (reportName: ReportName, updateParam?: string) => string;
    reportName: ReportName;
    projectId: number;
    updateReport: (
        type: string,
        options: IUpdateReportOptions<IUpdateReportParams | IUpdateMediaParams, ReportName>,
    ) => void;
    updateReportWithoutRequest?: (updateParams: Record<string, string>) => void;
    getLatency: () => void;
    serverTime: number;
    maxDate: DateType;
    minDate: DateType;
    dateStart: DateType;
    dateEnd: DateType;
    isExcludedLatency: boolean;
    groupBy: GroupBy;
    sample: number | null;
    onChangeSample?: (newSample: number) => void;
    periodDetail?: EPeriodDetail;
    periodDetailData?: IPeriodDetailData[];
    changePeriodDetailHandler?: (value: EPeriodDetail, group?: GroupBy) => GroupBy;
    isSampleDisabled?: boolean;
    isPeriodDetailDisabled?: boolean;
    attributionModel?: string;
    attributionWindow?: string;
    metrics?: Metrics[];
    graphs?: IGraphs[];
    toggleUseDefaultLines?: (useDefaultLines: boolean) => void;
    disableNumberOfGraphs?: boolean;
    filtersConfig?: TFiltersListCnf;
    className?: string;
    title?: string;
    isOnlyToday?: boolean;
    nowDateDiff?: number;
    handleChangeDate?: (date: DateType[], relativeDate: DateLiteral) => void;
}

const ReportTools: React.FC<IProps> = ({
    className = '',
    title,
    reportsUtils,
    getTypeRequestReport,
    reportName,
    updateReport,
    updateReportWithoutRequest,
    getLatency,
    serverTime,
    isOnlyToday,
    maxDate,
    minDate,
    dateStart,
    dateEnd,
    nowDateDiff,
    isExcludedLatency,
    handleChangeDate,
    sample,
    onChangeSample,
    periodDetail,
    periodDetailData,
    changePeriodDetailHandler,
    isSampleDisabled,
    isPeriodDetailDisabled,
    attributionModel,
    attributionWindow,
    groupBy,
    metrics,
    graphs,
    disableNumberOfGraphs,
    availableTools,
    filtersConfig,
}) => {
    const filtersData = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);
    const filtersDimensionsDict = useSelector((state: IRootSlice) => state.globalSlice.filtersDimensionsDict);
    const savedFiltersList = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersList);
    const savedFiltersRequest = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersRequest);
    const savedFilterCurrentId = useSelector((state: IRootSlice) => state.globalSlice.savedFilterCurrentId);

    const {
        getDynamicFilters,
        getSavedFilters,
        applySavedFilterAction,
        getSavedFiltersByReport,
        getFiltersDimensionsDict,
        transformDimensionsValuesCnfToDict,
    } = useFilters();

    useEffect(() => getFiltersDimensionsDict(), [JSON.stringify(filtersData)]);

    const dimensionsValuesDict = {
        ...transformDimensionsValuesCnfToDict(dimensionsValues),
        ...filtersDimensionsDict,
    };

    const savedFiltersListByReport = useMemo(
        () => getSavedFiltersByReport(savedFiltersList, reportName),
        [savedFiltersList.length, reportName],
    );

    const onChangeGraphNumber = (value: string) => {
        const selectedNumber = reportsParamsConfig.numberOfGraphs.indexOf(value) + 1;

        if (graphs && graphs.length === selectedNumber) return;

        const newGraphs = reportsUtils.getDefaultGraphs(reportName, graphs, value, metrics);

        if (value === 'one') {
            updateReportWithoutRequest({ graphs: newGraphs });
        } else if (value === 'two') {
            updateReport('graph', {
                updateParams: { graphs: newGraphs },
            });
        }
    };

    const onChangeAttributionModel = (value: string) => {
        updateReport('all', {
            updateParams: { attributionModel: value },
        });
    };

    const onChangeAttributionWindow = (value: string) => {
        updateReport('all', {
            updateParams: { attributionWindow: value },
        });
    };

    /**
     * Устанавливаем выбранный фильтр
     */
    const applyFiltersHandler = (filtersArray: TFilters) => {
        const globalFilters = filtersArray.map((filter) => {
            // Преобразовываем "Время на материале" в нужный формат (сек.)
            if (filter.key === 'avg_view_duration') {
                const values = (filter.value as string).split(':');
                return {
                    ...filter,
                    value: String(parseInt(values[0], 10) * 60 + parseInt(values[1], 10)),
                };
            }
            return filter;
        });

        updateReport('all', {
            updateParams: { globalFilters },
            options: { withoutCompare: true },
        });
    };

    const fillNumberWithZero = (value: number) => (String(value)?.length < 2 ? `0${value}` : value.toString());

    // Преобразовываем некоторые из фильтров с бэка к стандартному формату (напр. "Время на материале")
    const transformFiltersToValidValues = (filtersArray: TFilters) =>
        filtersArray.map((filter) => {
            // Преобразовываем "Время на материале" в нужный формат (мм:сс)
            if (filter.key === 'avg_view_duration') {
                const val = parseInt(filter.value as string, 10);
                const minutes = fillNumberWithZero(Math.trunc(val / 60));
                const seconds = fillNumberWithZero(val % 60);

                return {
                    ...filter,
                    value: `${minutes}:${seconds}`,
                };
            }
            return filter;
        });

    return (
        <div className={cn(s.root, className)}>
            {title && <div>{title}</div>}
            <div className={s.params}>
                <div className={s.mainParams}>
                    {availableTools.numberOfGraphs && (
                        <NumberOfGraphs
                            reportName={reportName}
                            onChangeGraphNumber={onChangeGraphNumber}
                            isDisabled={disableNumberOfGraphs}
                            selected={graphs && graphs.length}
                        />
                    )}
                    <PeriodPicker
                        maxDate={maxDate}
                        minDate={minDate}
                        dateStart={dateStart}
                        dateEnd={dateEnd}
                        nowDateDiff={nowDateDiff}
                        isExcludedLatency={isExcludedLatency}
                        groupBy={groupBy}
                        reportName={reportName}
                        updateReport={updateReport}
                        handleChangeDate={handleChangeDate}
                        availableTools={availableTools}
                        getLatency={getLatency}
                        serverTime={serverTime}
                        isOnlyToday={isOnlyToday}
                        getTypeRequestReport={getTypeRequestReport}
                        periodDetail={periodDetail}
                        periodDetailData={periodDetailData}
                        isPeriodDetailDisabled={isPeriodDetailDisabled}
                        changePeriodDetailHandler={changePeriodDetailHandler}
                    />
                    {availableTools.sample && sample !== null && sample >= 0 && (
                        <SampleField
                            sample={sample}
                            activeSample={!isSampleDisabled && availableTools.activeSample}
                            updateReport={updateReport}
                            onChangeSample={onChangeSample}
                        />
                    )}
                    {availableTools.attributionModel && (
                        <AttributionModel
                            reportName={reportName}
                            onChangeAttributionModel={onChangeAttributionModel}
                            selected={attributionModel}
                        />
                    )}
                    {availableTools.attributionWindow && (
                        <AttributionWindow
                            reportName={reportName}
                            onChangeAttributionWindow={onChangeAttributionWindow}
                            isDisabled={!attributionModel || attributionModel === EAttributionModel.lastClick}
                            selected={attributionWindow}
                        />
                    )}
                </div>
                {availableTools.filters ? (
                    <CommonFilters
                        filtersData={transformFiltersToValidValues(filtersData)}
                        filtersListCnf={filtersConfig || []}
                        filtersFormCnf={filtersFormCnf}
                        dynamicFilterData={dynamicFiltersData}
                        getDynamicFilterData={getDynamicFilters}
                        dimensionsValuesDict={dimensionsValuesDict}
                        onApply={applyFiltersHandler}
                        savedFiltersList={savedFiltersListByReport}
                        savedFiltersLoading={savedFiltersRequest}
                        savedFilterCurrentId={savedFilterCurrentId}
                        getSavedFiltersList={availableTools.savedFilters ? getSavedFilters : null}
                        savedFiltersAction={applySavedFilterAction}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default ReportTools;
