import { Button, Modal } from '@adtech/ui';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { IButtonPopup } from '@typings/modalPopup';
import { IRootSlice } from '@typings/rootSlice';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import modalPopupContent from './modalPopupContent';

const ModalPopup: React.FC = () => {
    const dispatch = useAppDispatch();
    const modalPopup = useAppSelector((state: IRootSlice) => state.globalSlice.modalPopup);

    const [isShowPopup, setShowPopup] = useState(modalPopup?.isShowPopup || false);
    const navigate = useNavigate();

    useEffect(() => {
        setShowPopup(modalPopup?.isShowPopup);
    }, [modalPopup?.isShowPopup]);

    const { modalPopupId, modalPopupParams = {} } = modalPopup || {};

    if (!modalPopupId) return null;

    const getContent = modalPopupContent[modalPopupId].getContent;

    const {
        popupClassName,
        width,
        title,
        content,
        buttonContainerClassName,
        okButton,
        cancelButton,
        onClose,
        closeOnClickInsideOnly,
    } = getContent(modalPopupParams, navigate);

    const closePopup = () => {
        setShowPopup(false);
        dispatch(globalActions.clearModalPopup());

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    const getButton = (button: IButtonPopup) => {
        const onClick = () => {
            if (typeof button.onClick === 'function') {
                button.onClick();
            }

            if (button.closePopupOnClick) {
                closePopup();
            }
        };

        return (
            <Button className={button.className} type={button.type} size={button.size} onClick={onClick}>
                {button.children}
            </Button>
        );
    };

    return isShowPopup ? (
        <Modal
            isOpened={isShowPopup}
            className={popupClassName}
            title={title}
            customFooter={
                <div className={buttonContainerClassName}>
                    {cancelButton ? getButton(cancelButton) : null}
                    {okButton ? getButton(okButton) : null}
                </div>
            }
            size={width || 'lg'}
            onClose={closePopup}
            closeOnClickOutside={!closeOnClickInsideOnly}
            closeOnEsc
        >
            {content}
        </Modal>
    ) : null;
};

export default ModalPopup;
