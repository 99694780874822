import { dimensionEmptyValue } from '@configs/dimensionsValues';
import { Dimensions } from '@typings/dimensions';

interface IAliases {
    title?: string;
    tooltip?: string;
}

// Тип значения параметра
export enum DimensionValueType {
    value = 'value', // значение
    key = 'key', // ключ
    valueByKey = 'valueByKey', // значение по ключу
}

export interface IDimensions {
    name: Dimensions;
    title?: string;
    parentTitle?: string; // название родителя, для вывода в конструкторе
    aliasesByReports?: Record<string, IAliases>;
    tooltip?: string;
    format?: 'number' | 'float' | 'percent' | 'timeFormatted' | 'date' | 'datetime';
    formatterValue?: 'trimSeconds';
    type?: 'int' | 'float';
    minValue?: number | null;
    defaultValue?: string;
    needRevers?: boolean;
    titleDelimiters?: string[]; // разделители, соединяющие title с parents (обратный порядок)
    relatedDimensions?: Dimensions[], // связанные дименшины-родители (обратный порядок)
    subDimensionForValueByKey?: Dimensions, // вспомогательный дименшин для запроса по значению ключей
    relatedKeysDimension?: Dimensions, // связанный дименшин-родитель (ключи)
}

export const dimensionsDict: Record<string, IDimensions> = {
    gender: {
        name: 'gender',
        title: 'Пол',
        parentTitle: 'Аудитория',
        format: 'number',
        type: 'int',
        minValue: 10,
    },
    age_tns: {
        name: 'age_tns',
        title: 'Возраст',
        parentTitle: 'Аудитория',
        format: 'number',
        type: 'int',
        minValue: 0,
    },
    category_1: {
        name: 'category_1',
        title: 'Интересы',
        parentTitle: 'Аудитория',
    },
    media_theme: {
        name: 'media_theme',
        title: 'Интересы',
        parentTitle: 'Аудитория',
        format: 'number',
        type: 'int',
        minValue: 10,
    },
    geo_country: {
        name: 'geo_country',
        title: 'Страна',
        parentTitle: 'Аудитория',
        format: 'number',
        type: 'int',
        minValue: 10,
    },
    geo_area: {
        name: 'geo_area',
        parentTitle: 'Аудитория',
        title: 'Регион',
    },
    geo_city: {
        name: 'geo_city',
        parentTitle: 'Аудитория',
        title: 'Город',
    },
    view_depth: {
        name: 'view_depth',
        title: 'Глубина просмотра',
        parentTitle: 'Аудитория',
    },
    session_number: {
        name: 'session_number',
        title: 'Номер визита',
        parentTitle: 'Аудитория',
    },
    fullread_percent: {
        name: 'fullread_percent',
        title: 'Дочтения',
        parentTitle: 'Медиа',
        format: 'number',
        type: 'int',
        minValue: 10,
    },
    fullscroll_percent: {
        name: 'fullscroll_percent',
        title: 'Доскроллы',
        parentTitle: 'Медиа',
        format: 'number',
        type: 'int',
        minValue: 10,
    },
    media_title: {
        name: 'media_title',
        title: 'Название материала',
        parentTitle: 'Медиа',
    },
    published_datetime: {
        name: 'published_datetime',
        title: 'Опубликовано',
        parentTitle: 'Медиа',
        defaultValue: dimensionEmptyValue,
        format: 'datetime',
    },
    published_date: {
        name: 'published_date',
        title: 'Дата публикации',
        parentTitle: 'Медиа',
        defaultValue: 'Не определена',
        format: 'date',
    },
    published_time: {
        name: 'published_time',
        title: 'Время публикации',
        parentTitle: 'Медиа',
        defaultValue: dimensionEmptyValue,
        format: 'timeFormatted',
    },
    update_time: {
        name: 'update_time',
        title: 'Время обновления',
        parentTitle: 'Медиа',
        defaultValue: dimensionEmptyValue,
        format: 'timeFormatted',
    },
    media_type: {
        name: 'media_type',
        title: 'Тип материала',
        parentTitle: 'Медиа',
        defaultValue: 'Не выбран',
    },
    author_name: {
        name: 'author_name',
        title: 'Автор материала',
        parentTitle: 'Медиа',
        defaultValue: 'Не определён',
        aliasesByReports: {
            authors_summary: {
                title: 'Автор',
            },
        },
    },
    event_name: {
        name: 'event_name',
        title: 'Название события',
        parentTitle: 'События',
    },
    event_class: {
        name: 'event_class',
        title: 'Класс события',
        parentTitle: 'События',
    },
    date: {
        name: 'date',
        title: 'Дата',
        format: 'date',
    },
    split: {
        name: 'split',
        title: 'Сплит',
        parentTitle: 'Эксперименты',
    },
    traffic_type: {
        name: 'traffic_type',
        title: 'Источники переходов',
        parentTitle: 'Источники',
    },
    social_network: {
        name: 'social_network',
        title: 'Социальные сети',
        parentTitle: 'Источники',
    },
    url: {
        name: 'url',
        title: 'URL',
        parentTitle: 'Содержание',
    },
    url_no_args: {
        name: 'url_no_args',
        title: 'Популярный URL',
        parentTitle: 'Содержание',
    },
    url_args_key: {
        name: 'url_args_key',
        title: 'Параметры URL',
        parentTitle: 'Содержание',
        subDimensionForValueByKey: 'url_args_value',
    },
    url_args_value: {
        name: 'url_args_value',
        relatedKeysDimension: 'url_args_key',
    },
    entry_page: {
        name: 'entry_page',
        title: 'Страницы входа',
        parentTitle: 'Содержание',
    },
    exit_page: {
        name: 'exit_page',
        title: 'Страницы выхода',
        parentTitle: 'Содержание',
    },
    external_url: {
        name: 'external_url',
        title: 'Переходы по внешним ссылкам',
        parentTitle: 'Содержание',
    },
    title: {
        name: 'title',
        title: 'Заголовки страниц',
        parentTitle: 'Содержание',
    },
    referer: {
        name: 'referer',
        title: 'Referer',
        parentTitle: 'Источники',
    },
    referer_domain: {
        name: 'referer_domain',
        title: 'Referer domain',
        parentTitle: 'Источники',
    },
    referer_path: {
        name: 'referer_path',
        title: 'Referer path',
        parentTitle: 'Источники',
    },
    ad_campaign: {
        name: 'ad_campaign',
        title: 'Рекламная компания',
        parentTitle: 'Источники',
    },
    ad_system: {
        name: 'ad_system',
        title: 'Рекламная система',
        parentTitle: 'Источники',
    },
    openstat_ad: {
        name: 'openstat_ad',
        title: 'Openstat ad',
        parentTitle: 'Источники',
    },
    openstat_campaign: {
        name: 'openstat_campaign',
        title: 'Openstat campaign',
        parentTitle: 'Источники',
    },
    openstat_service: {
        name: 'openstat_service',
        title: 'Openstat service',
        parentTitle: 'Источники',
    },
    openstat_source: {
        name: 'openstat_source',
        title: 'Openstat source',
        parentTitle: 'Источники',
    },
    utm_campaign: {
        name: 'utm_campaign',
        title: 'Utm campaign',
        parentTitle: 'Источники',
    },
    utm_content: {
        name: 'utm_content',
        title: 'Utm content',
        parentTitle: 'Источники',
    },
    utm_medium: {
        name: 'utm_medium',
        title: 'Utm medium',
        parentTitle: 'Источники',
    },
    utm_source: {
        name: 'utm_source',
        title: 'Utm source',
        parentTitle: 'Источники',
    },
    utm_term: {
        name: 'utm_term',
        title: 'Utm term',
        parentTitle: 'Источники',
    },
    search_phrase: {
        name: 'search_phrase',
        title: 'Поисковая фраза',
        parentTitle: 'Источники',
    },
    search_system: {
        name: 'search_system',
        title: 'Поисковые системы',
        parentTitle: 'Источники',
    },
    recom_system: {
        name: 'recom_system',
        title: 'Рекомендательные системы',
        parentTitle: 'Источники',
    },
    exchange_system: {
        name: 'exchange_system',
        title: 'Обменные сети',
        parentTitle: 'Источники',
    },
    message_system: {
        name: 'message_system',
        title: 'Мессенджеры',
        parentTitle: 'Источники',
    },
    aggrnews_system: {
        name: 'aggrnews_system',
        title: 'Новостные агрегаторы',
        parentTitle: 'Источники',
    },
    qr_system: {
        name: 'qr_system',
        title: 'Переходы по QR-кодам',
        parentTitle: 'Источники',
    },
    native_mobile: {
        name: 'native_mobile',
        title: 'Мобильные приложения',
        parentTitle: 'Источники',
    },
    screen_size: {
        name: 'screen_size',
        title: 'Разрешение',
        parentTitle: 'Разрешение экрана',
    },
    screen_width: {
        name: 'screen_width',
        title: 'Ширина',
        parentTitle: 'Разрешение экрана',
        titleDelimiters: ['x'],
        relatedDimensions: ['screen_height'],
    },
    screen_height: {
        name: 'screen_height',
        title: 'Высота',
        parentTitle: 'Разрешение экрана',
        titleDelimiters: ['x'],
        relatedDimensions: ['screen_width'],
    },
    counter_type_eventbase: {
        name: 'counter_type_eventbase',
        title: 'Формат страниц',
        parentTitle: 'Технологии',
    },
    browser_family: {
        name: 'browser_family',
        title: 'Название',
        parentTitle: 'Браузер',
    },
    browser_version_major: {
        name: 'browser_version_major',
        title: 'Основная версия',
        parentTitle: 'Браузер',
        titleDelimiters: [' '],
        relatedDimensions: ['browser_family'],
    },
    browser_version_minor: {
        name: 'browser_version_minor',
        title: 'Дополнительная версия',
        parentTitle: 'Браузер',
        titleDelimiters: ['.', ' '],
        relatedDimensions: ['browser_version_major', 'browser_family'],
    },
    device_brand: {
        name: 'device_brand',
        title: 'Бренд',
        parentTitle: 'Устройство',
    },
    device_family: {
        name: 'device_family',
        title: 'Серия',
        parentTitle: 'Устройство',
    },
    device_model: {
        name: 'device_model',
        title: 'Модель',
        parentTitle: 'Устройство',
    },
    device_type: {
        name: 'device_type',
        title: 'Тип',
        parentTitle: 'Устройство',
        aliasesByReports: {
            conversion: {
                title: 'Устройство',
            },
        },
    },
    os_family: {
        name: 'os_family',
        title: 'Название',
        parentTitle: 'ОС',
        aliasesByReports: {
            conversion: {
                title: 'ОС',
            },
        },
    },
    os_version_major: {
        name: 'os_version_major',
        title: 'Основная версия',
        parentTitle: 'ОС',
        titleDelimiters: [' '],
        relatedDimensions: ['os_family'],
    },
    os_version_minor: {
        name: 'os_version_minor',
        title: 'Дополнительная версия',
        parentTitle: 'ОС',
        titleDelimiters: ['.', ' '],
        relatedDimensions: ['os_version_major', 'os_family'],
    },
    auth_rambler: {
        name: 'auth_rambler',
        title: 'Пользователи Рамблер',
        parentTitle: 'Тип авторизации',
    },
    auth_top100: {
        name: 'auth_top100',
        title: 'Авторизация сайта',
        parentTitle: 'Тип авторизации',
    },
    user_cv_key: {
        name: 'user_cv_key',
        title: 'Пользовательский параметр',
        parentTitle: 'Параметры события',
    },
    customer_params_key: {
        name: 'customer_params_key',
        title: 'Собственные события: Список параметров',
        parentTitle: 'Параметры события',
        subDimensionForValueByKey: 'customer_params_value',
    },
    customer_params_value: {
        name: 'customer_params_value',
        relatedKeysDimension: 'customer_params_key',
    },
    ub_block_list: {
        name: 'ub_block_list',
        title: 'Аналитика блоков: Список блоков',
        parentTitle: 'Параметры события',
    },
    show: {
        name: 'show',
        title: 'Показы',
    },
    click: {
        name: 'click',
        title: 'Клики',
    },
    topic: {
        name: 'topic',
        title: 'Google Topics',
    },
    app_version: {
        name: 'app_version',
        title: 'Версия приложения',
    },
    traffic_system: {
        name: 'traffic_system',
        title: 'Источники переходов детально',
    },
};
