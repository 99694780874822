import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchApi from '@utils/fetchApi';

export const getCounter = createAsyncThunk('postClickSlice/getCounter', async (projectId: number) => {
    // eslint-disable-next-line no-return-await
    const response = await fetchApi({
        url: `/api/v1.0/yandex/${projectId}/counter`,
        method: 'GET',
    });
    if (response.responseStatus === 404) {
        throw new Error('Счётчик не найден');
    }
    return response;
});

export const createCounter = createAsyncThunk(
    'postClickSlice/createCounter',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/counter`,
            method: 'POST',
            body: {
                project_id: projectId,
            },
            metaInfo: {
                ignoredErrors: [400],
            },
        }),
);

export const linkCounter = createAsyncThunk(
    'postClickSlice/linkCounter',
    async ({ projectId, params }: { projectId: string; params: { id: number; token?: string } }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/link_counter`,
            method: 'PUT',
            body: params,
            metaInfo: {
                ignoredErrors: [400],
            },
        }),
);

export const unlinkCounter = createAsyncThunk(
    'postClickSlice/unlinkCounter',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/unlink_counter`,
            method: 'PUT',
        }),
);

export const deleteCounter = createAsyncThunk(
    'postClickSlice/deleteCounter',
    async (projectId: number) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/counter`,
            method: 'DELETE',
        }),
);

export const updateGoals = createAsyncThunk(
    'postClickSlice/updateGoals',
    async ({ projectId, params }: { projectId: string; params: { goals: { id: number; send: boolean }[] } }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/update_goals`,
            method: 'PUT',
            body: params,
            metaInfo: {
                ignoredErrors: [200], // ?
            },
        }),
);

export const updateImportFromYM = createAsyncThunk(
    'postClickSlice/updateImportFromYM',
    async ({ projectId, params }: { projectId: string; params: { import_from_ym: boolean; token?: string } }) =>
        // eslint-disable-next-line no-return-await
        await fetchApi({
            url: `/api/v1.0/yandex/${projectId}/import`,
            method: 'PUT',
            body: params,
            metaInfo: {
                ignoredErrors: [400, 404],
            },
        }),
);
