import { ImageUsability } from '@images/svg/reportPlaceholder/usability';
import React from 'react';

export default {
    image: <ImageUsability />,
    link: 'https://ads.sber.ru/help/prakticheskoe-rukovodstvo-po-analitike-blokov',
    title: 'Аналитика блоков — простой инструмент для оценки CTR различных элементов сайта',
    textBlocks() {
        return [
            'Придумайте названия и иерархию параметров, отражающие структуру вашего сайта',
            'Настройте передачу данных о разметке блоков в счётчик Статистики от SberAds',
            <span>
                <a href="https://habr.com/company/rambler-co/blog/336886/" target="_blank" rel="noopener noreferrer">
                    Анализируйте
                </a>
                &nbsp;CTR выбранных элементов с помощью древовидного отчёта
            </span>,
        ];
    },
};
