import { Switch, Alert } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import s from './SettingsCounter.pcss';

interface IProps {
    counterType: boolean;
}

const SettingsCounter = ({ counterType }: IProps) => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'counterType', control });

    return (
        <CounterBlocks.Wrapper rootClassName={s.root} withoutDelimiter>
            <div className={s.switch}>
                <Switch checked={field.value} onChange={(value) => field.onChange(value)} />
                <div className={s.switchLabel}>Отображать виджет</div>
            </div>
            <Alert type="warning" message="При отключении функции плашка не будет отображаться на сайте" showIcon />
            {counterType && <CounterBlocks.LogoColorChooser />}
        </CounterBlocks.Wrapper>
    );
};

export default SettingsCounter;
