import { Button, Input } from '@adtech/ui';
import usePostClick from '@hooks/usePostClick';
import React, { ChangeEvent, FC, useState } from 'react';
import s from '../../PostClick.pcss';

const CounterTokenAccess: FC = () => {
    const [counterNumber, setCounterNumber] = useState('');
    const [token, setToken] = useState('');
    const api = usePostClick();

    const counterInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setCounterNumber(event.target.value);
    };
    const counterTokenHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setToken(event.target.value);
    };

    return (
        <div className={s.token}>
            <div className={s.tokenLabel}>
                <span className={s.tokenLabelText}>Токен</span>
                <Input onChange={counterTokenHandler} placeholder="Введите токен" />
            </div>
            <div className={s.tokenLabel}>
                <span className={s.tokenLabelText}>Номер счётчика</span>
                <Input onChange={counterInputHandler} placeholder="Введите номер счётчика" />
            </div>
            <Button onClick={() => api.linkCounter(Number(counterNumber), token)}>Синхронизировать</Button>
        </div>
    );
};

export default CounterTokenAccess;
