import ContentWithSidebar from '@components/ContentWithSidebar';
import ConversionsTable from '@components/ConversionsTable';
import PageHead from '@components/PageHead';
import ReportTools from '@components/ReportTools';
import TableTools from '@components/TableTools';

import { ConversionFormType } from '@configs/conversions/conversionForm';
import conversionsConfig from '@configs/conversions/conversionsSummary';
import { LatencyKeys } from '@constants/latency';
import reportsConstants from '@constants/reports';
import { Error507 } from '@containers/Error';

import useCheckDates from '@hooks/useCheckDates';
import useConversions from '@hooks/useConversions';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useLatency from '@hooks/useLatency';
import useModalPopup from '@hooks/useModalPopup';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';
import { useTable } from '@hooks/useTable';

import { globalActions } from '@redux/slices/global';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { storagesUtils } from '@utils/index';

import React, { FC, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './Conversions.pcss';

const ConversionsSummary: FC = () => {
    useGlobalLoading();

    const dispatch = useDispatch();
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);
    const reportName = 'conversions';
    const storageKey = 'showCommonConversions';

    const isProjectRequired = useProjectRequired();
    const isReportInited = useRef(false);

    const { api, reportsUtils } = useConversions();
    const {
        api: { resetTable },
    } = useTable();

    const { openModalPopup, closeModalPopup } = useModalPopup();
    const { updateQuery } = useQuery();

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const tableData = useSelector((state: IRootSlice) => state.conversionsSlice.tableData);
    const tableRequest = useSelector((state: IRootSlice) => state.conversionsSlice.tableRequest);
    const deleteRequest = useSelector((state: IRootSlice) => state.conversionsSlice.deleteRequest);
    const totals = useSelector((state: IRootSlice) => state.conversionsSlice.totals);
    const errorCode = useSelector((state: IRootSlice) => state.conversionsSlice.errorCode);
    const conversionDict = useSelector((state: IRootSlice) => state.conversionsSlice.conversionsDict);
    let showCommonConversions = useSelector((state: IRootSlice) => state.conversionsSlice.showCommonConversions);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);

    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);
    const titleFilter = useSelector((state: IRootSlice) => state.tableSlice.titleFilter);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));
    const clearError = () => dispatch(globalActions.clearError());
    const showErrorPage = errorCode === 507;

    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[LatencyKeys.base]);
    const isExcludedLatency = latencyConfig?.isExcludedLatency;
    const getLatency = useLatency(LatencyKeys.base);
    const checkedDates = useCheckDates(LatencyKeys.base);

    const minCalendarDate = latencyConfig?.start;
    const maxCalendarDate = latencyConfig?.end;

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Конверсии ${url} – Статистика от SberAds / ${projectId}`;
    };

    if (projectId === reportsConstants.DEMO_PROJECT_ID && showCommonConversions === null) {
        showCommonConversions = true;
    }
    const isShowAllConversions = !!storagesUtils.get(storageKey, userId) || showCommonConversions;
    const reportSample = sample || conversionsConfig.sampleDefault;

    useEffect(
        () => () => {
            // Сбрасываем сохраненные тела целей (фильтры)
            api.updateParams({ numeratorsFilters: [], denominatorsFilters: [] });
            resetTable();
            setGlobalLoading(false);
        },
        [],
    );

    useEffect(() => {
        if (!checkedDates || !isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.initConversionsSummary({
            showCommonConversions: isShowAllConversions,
            titleFilter: '',
        }).then(() => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired]);

    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            groupBy,
            sample,
        });
    }, [isReportInited.current, dateEnd, dateStart, groupBy, sample]);

    useEffect(() => {
        // Чтобы updateQuery не отрабатывал в момент перехода между проектами на старом компоненте
        isReportInited.current = false;
    }, [projectId]);

    const handleClickAddConversion = () => {
        openModalPopup('ConversionForm', {
            type: ConversionFormType.add,
            closePopup: closeModalPopup,
            api,
        });
    };

    const handleChangeShowAllConversions = (checked?: boolean) => {
        api.updateParams({ showCommonConversions: checked });
        api.getTable({ showCommonConversions: checked });
        storagesUtils.set(storageKey, checked ? 1 : 0, userId);
    };

    const handleSearchConversions = (value: string) => {
        if (tableRequest || deleteRequest) return;
        api.updateTable({ titleFilter: value });
    };

    const handleChangeDate507 = () => {
        clearError();
    };

    const renderError = () => {
        const dates = DateUtils.convertDates(dateStart, dateEnd);

        return (
            <Error507
                dateStart={dates[0]}
                dateEnd={dates[1]}
                handleChangeDate={handleChangeDate507}
                minCalendarDate={minCalendarDate}
                maxCalendarDate={maxCalendarDate}
                serverTime={serverTime}
                getLatency={getLatency}
                isExcludedLatency={isExcludedLatency}
            />
        );
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            <div className={s.root}>
                {showErrorPage && renderError()}
                {!showErrorPage && !globalLoading && (
                    <>
                        <PageHead
                            reportName={reportName}
                            projectId={projectId}
                            userId={userId}
                            title={conversionsConfig.reportTitle}
                            hintContent={conversionsConfig.reportHint}
                        />
                        <ContentWithSidebar>
                            <ReportTools
                                reportName={reportName}
                                reportsUtils={reportsUtils}
                                projectId={projectId}
                                updateReport={api.updateReport}
                                getLatency={getLatency}
                                serverTime={serverTime}
                                minDate={minCalendarDate}
                                maxDate={maxCalendarDate}
                                dateStart={dateStart}
                                dateEnd={dateEnd}
                                isExcludedLatency={isExcludedLatency}
                                handleChangeDate={api.handleChangeDate}
                                sample={reportSample}
                                groupBy={groupBy}
                                getTypeRequestReport={() => 'all'}
                                availableTools={conversionsConfig.availableTools}
                                graphs={null}
                            />
                            {tableData && (
                                <>
                                    <TableTools
                                        testId="add-button"
                                        onSearchChange={handleSearchConversions}
                                        searchPlaceholder="Искать по названию конверсии"
                                        searchWord={titleFilter}
                                        prefixButtonText="Новая конверсия"
                                        onClickPrefixButton={handleClickAddConversion}
                                        switcherText="Показать публичные конверсии"
                                        onChangeSwitcher={handleChangeShowAllConversions}
                                        switcherHintText="Показать все конверсии, включая публичные. По умолчанию показываются только личные."
                                        switcherChecked={isShowAllConversions}
                                    />
                                    <ConversionsTable
                                        tableRequest={tableRequest}
                                        deleteRequest={deleteRequest}
                                        tableData={tableData}
                                        getTableOffset={api.getTableOffset}
                                        totals={totals}
                                        conversionDict={conversionDict}
                                        api={api}
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        groupBy={groupBy}
                                    />
                                </>
                            )}
                        </ContentWithSidebar>
                    </>
                )}
            </div>
        </DocumentTitle>
    );
};

export default ConversionsSummary;
