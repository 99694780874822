const attributesData = {
    topline: {
        attrName: 'topline',
        helpBtn: 'help_btn',
        helpList: 'help_list',
        url: 'site_url_btn',
        counterLink: 'counter_link_btn',
        counterSettings: 'counter_settings_btn',
        deletedProjects: 'deleted_sites_btn',
        projectSearch: 'sites_dropdown_btn',
        projectsLink: 'my_sites_btn',
        catalogueLink: 'to_catalogue_btn',
    },
    dashboard: { // FIXME: разобраться что используется и остальное почистить
        attrName: 'dashboard',
        tools: {
            attrName: 'tools',
            detail: 'detalization_btn',
            setDetail: 'set_detalization',
            period: 'period_btn',
        },
        totals: {
            attrName: 'totals',
            ratingCategory: 'rating_category_btn',
            setRatingCategory: 'set_rating_category',
        },
        widgets: {
            attrName: 'widgets',
            settingsMetrics: 'settings_metrics',
            setMetricAttr: 'set_metric',
            link: 'link',
            sort: 'sort',
            trafficSources: {
                widgetName: 'sources',
            },
            popularUTM: {
                widgetName: 'utm',
            },
        },
    },
    newreport: {
        attrName: 'newreport',
        navAttr: 'navigation',
        navSearch: 'nav_search',
        rating: {
            attrName: 'rating',
            oldVersion: 'old_version_btn',
            categoryBtn: 'category_btn',
            setCategory: 'set_category',
        },
        closeAllHint: 'close_all_hint_btn',
        closeHint: 'close_hint_btn',
        showMoreHint: 'show_more_hint_btn',
        csvBtn: 'download_btn',
        savedFilters: 'saved_filters_btn',
        tools: {
            attrName: 'report_tools',
            splits: 'splits_btn',
            setSplit: 'set_split',
            resetSplit: 'reset_split',
            graphCount: 'graph_count_btn',
            setGraphCount: 'set_graph_count',
            period: 'period_btn',
            detail: 'detalization_btn',
            setDetail: 'set_detalization',
        },
        graph: {
            attrName: 'graph',
            tools: {
                attrName: 'tools',
                reportChart: 'metrics_dropdown',
                setMetric: 'set_metric',
                graphType: 'graph_type',
            },
        },
        table: {
            attrName: 'table',
            tools: {
                attrName: 'tools',
                sort: 'sort',
                settingsMetrics: 'settings_metrics',
                setMetricAttr: 'set_metric',
            },
            tableRowTitle: 'table_row_title',
            tableSearch: 'table_search',
            resetBtn: 'reset_lines_btn',
            filterBtn: 'filter_btn',
            percentBtn: 'percent_btn',
            selectLine: 'items_chkbx',
            expandBtn: 'expand_items_btn',
            moreBtn: 'more_button',
        },
    },
    media: {
        attrName: 'media',
        widgets: {
            attrName: 'widgets',
            settingsMetrics: 'settings_metrics',
            setMetricAttr: 'set_metric',
            link: 'link',
            sort: 'sort',
            trafficSources: {
                widgetName: 'traffic_sources',
            },
            audience: {
                widgetName: 'audience',
            },
            fullReadScroll: {
                widgetName: 'full_read_scroll',
            },
            recirculationSources: {
                widgetName: 'recirculation_sources',
            },
        },
        tools: {
            attrName: 'media_tools',
            periodDetail: 'period_detail_btn',
            setPeriodDetail: 'set_period_detail',
        },
    },
    search: {
        searchInput: 'search_input',
        searchItem: 'search_item',
    },
    filters: {
        attrName: 'report-filters',
        addFilter: 'add_filter',
        addMoreFilter: 'add_more_filter',
        showFilter: 'show_filter',
        dropFilter: 'drop_filter',
        tabs: 'tabs',
        closeIcon: 'close_icon',
        searchInput: 'search_input',
        listFilters: {
            attrName: 'list_filters',
            popularity: 'special',
            all: 'select',
        },
        cancelBtn: 'cancel_btn',
        applyBtn: 'apply_btn',
        saveFilter: 'save_filter_btn',
        resetFilter: 'reset_filter_btn',
    },
    createCounter: {
        attrName: 'create-counter',
        firstStep: 'first_step',
        createCounterBtn: 'create_counter_button',
        createCounterSuccess: 'create_counter_success',
        secondStep: 'second_step',
        ratingSwitcher: 'rating_switcher',
        saveSettings: 'save_settings',
        restoreCounterPopup: 'restore_counter_popup',
        restoreCounterBtn: 'restore_counter_btn',
        restoreCounterStep: 'restore_counter_step',
    },
};

export default attributesData;
