import { TableSearch } from '@adtech/ui';
import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { IFiltersCnfItem, TAllFormValues, TDimension, TDynamicFilterData, TFiltersListCnf } from '../types/filters';
import FiltersRules from './FilterRules';
import s from './FiltersContent.pcss';
import FiltersList from './FiltersList';

interface IProps {
    filtersListCnf: TFiltersListCnf;
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    setActiveKey: (key: string) => void;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    dynamicFilterData?: TDynamicFilterData;
    getDynamicFilterData?: (dimension: TDimension) => Promise<unknown>;
    isSearchExists?: boolean;
    wrapperClassName?: string;
    size?: 'default' | 'small';
}

const FiltersContent: React.FC<IProps> = ({
    filtersListCnf,
    filterCnf,
    currentKey,
    setActiveKey,
    allFormsValues,
    setAllFormsValues,
    dynamicFilterData,
    getDynamicFilterData,
    isSearchExists = true,
    wrapperClassName,
    size,
}) => {
    const [searchValue, setSearchValue] = useState('');

    const searchHandler = (e) => setSearchValue(e.target.value);

    return (
        <div className={s.filtersContent}>
            {isSearchExists ? (
                <div className={s.filtersContentSearch}>
                    <TableSearch
                        onInput={searchHandler}
                        searchPlaceholder="Введите название раздела"
                        onClearIconClick={searchHandler}
                    />
                </div>
            ) : null}
            <div className={cn(s.filtersContentInner, wrapperClassName)}>
                <FiltersList
                    filtersListCnf={filtersListCnf}
                    currentKey={currentKey}
                    setActiveKey={setActiveKey}
                    allFormsValues={allFormsValues}
                    setAllFormsValues={setAllFormsValues}
                    searchValue={searchValue}
                />
                <FiltersRules
                    filterCnf={filterCnf}
                    currentKey={currentKey}
                    allFormsValues={allFormsValues}
                    setAllFormsValues={setAllFormsValues}
                    dynamicFilterData={dynamicFilterData}
                    getDynamicFilterData={getDynamicFilterData}
                    size={size}
                />
            </div>
        </div>
    );
};

export default FiltersContent;
