import { ZeroState, IllustrationTable } from '@adtech/ui';
import React from 'react';

import s from './FunnelEmptyPlaceholder.pcss';

interface IProps {
    isFunnelsExist?: boolean;
    onBtnClick?: () => void;
    btnText?: string;
    isCreateModal?: boolean;
}

const FunnelEmptyPlaceholder: React.FC<IProps> = ({ isFunnelsExist, onBtnClick, btnText, isCreateModal }) => {
    const Description = () => {
        const text = () =>
            isFunnelsExist ? (
                <div data-testid="description-text">
                    Выберите существующую воронку или нажмите на&nbsp;кнопку &laquo;Создать воронку&raquo;.
                </div>
            ) : (
                <div data-testid="description-text">Нажмите на&nbsp;кнопку &laquo;Создать воронку&raquo;.</div>
            );
        return isCreateModal ? (
            <>
                {text()}
                <div>Укажите параметры, условия и&nbsp;порядок отображения шагов.</div>
            </>
        ) : (
            <>
                <div data-testid="description-text">Нажмите на&nbsp;кнопку &laquo;Добавить шаг&raquo;.</div>
                <div>Далее&nbsp;&mdash; укажите название и&nbsp;задайте необходимые условия.</div>
                <div>Добавьте не более 11 шагов.</div>
            </>
        );
    };

    const button = () =>
        onBtnClick
            ? {
                  label: btnText || '',
                  event: onBtnClick,
              }
            : null;

    let title = isFunnelsExist ? 'Воронка не выбрана' : 'Воронки ещё не созданы';
    title = isCreateModal ? title : 'Добавьте шаги воронки';

    return (
        <div className={s.funnelsEmpty} data-testid="empty-container">
            <ZeroState
                title={title}
                description={<Description />}
                icon={<IllustrationTable />}
                button={button()}
                buttonType="labeled-button"
            />
        </div>
    );
};

export default FunnelEmptyPlaceholder;
