import { Modal } from '@adtech/ui';
import React, { FC } from 'react';

import { ESavedFilterActions, ESavedFilterType, ISavedFilterActionData, ISavedFilterData } from '../types/savedFilters';
import s from './DeleteSavedFilterModal.pcss';

interface IProps {
    isOpen: boolean;
    onOkHandler: (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => void;
    onCloseHandler: () => void;
    modalData?: ISavedFilterActionData;
}

const DeleteSavedFilterModal: FC<IProps> = ({ modalData, isOpen, onOkHandler, onCloseHandler }: IProps) => {
    const { actionName, data } = modalData || {};

    if (!actionName) return null;

    const { title, type } = data || {};

    return (
        <Modal
            className={s.deleteSavedFilterModal}
            isOpened={isOpen}
            title="Удаление шаблона"
            okText="Удалить"
            cancelText="Отменить"
            onOk={() => onOkHandler(actionName, data)}
            onClose={onCloseHandler}
            closeOnClickOutside={false}
            centered
            size="sm"
        >
            <div className={s.deleteSavedFilterModalInner}>
                <p>
                    Вы&nbsp;уверены, что вам больше не&nbsp;нужен этот шаблон: {title}?
                    <br />
                    Его нельзя будет восстановить.
                </p>
                {type === ESavedFilterType.SHARED && (
                    <p>Обратите внимание, что публичный шаблон будет удален у всех участников проекта.</p>
                )}
            </div>
        </Modal>
    );
};

export default DeleteSavedFilterModal;
