import { Textarea, Alert } from '@adtech/ui';
import React from 'react';
import { useFormContext, useController } from 'react-hook-form';
import Wrapper from '../Wrapper';

import s from './KeywordsData.pcss';

interface IProps {
    disabled: boolean;
    isRequired: boolean;
}

const KeywordsData: React.FC<IProps> = ({ disabled, isRequired }) => {
    const { control } = useFormContext();
    const MESSAGE = 'Укажите ключевые слова через запятую (не менее 3 и не более 500 символов)';

    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'keywords',
        control,
        rules: {
            required: {
                value: true,
                message: MESSAGE,
            },
            minLength: {
                value: 3,
                message: 'Минимально 3 символа(ов)',
            },
        },
    });

    const { name, onBlur, onChange, value } = field;
    const { message = '' } = error || {};

    const handleTextareaChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const changedValue = event.target.value;
        onChange(event, changedValue);
    };

    return (
        <Wrapper
            subWrap
            title="Ключевые слова"
            description="По ключевым словам сайт можно будет найти в каталоге Топ-100."
            smallTitle
            fixPadding
            isRequired={isRequired}
        >
            <div>
                <Alert type="info" message={MESSAGE} showIcon className={s.alert} />
                <Textarea
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={handleTextareaChange}
                    disabled={disabled}
                    status={error ? 'error' : null}
                    errorText={error ? message : ''}
                    maxLength={500}
                    showCount
                />
            </div>
        </Wrapper>
    );
};

export default KeywordsData;
