import { IconAlertWarning, IconCheckMark, SystemAlert } from '@adtech/ui';
import snackbarTypes from '@constants/snackbarTypes';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import React, { useEffect } from 'react';

const SnackbarNotification: React.FC = () => {
    const dispatch = useAppDispatch();
    const snackbar = useAppSelector((state) => state.globalSlice.snackbar);
    const { message, type } = snackbar || {};

    let snackbarTimeout: null | ReturnType<typeof setTimeout> = null;
    const content = <span dangerouslySetInnerHTML={{ __html: message }} />;

    const clearSnackbar = () => dispatch(globalActions.clearSnackbar());

    const renderSnackbar = () => {
        switch (type) {
            case snackbarTypes.SUCCESS:
                SystemAlert.success({
                    content,
                    duration: 3,
                    className: 'SBA-message green',
                    icon: <IconCheckMark />,
                });
                break;
            case snackbarTypes.ERROR:
                SystemAlert.error({
                    content,
                    duration: 3,
                    icon: <IconAlertWarning />,
                    className: 'SBA-message red',
                });
                break;
            default:
                SystemAlert.warning({
                    content,
                    duration: 3,
                    icon: <IconAlertWarning />,
                    className: 'SBA-message black',
                });
        }
    };

    useEffect(() => {
        if (message) {
            if (typeof snackbarTimeout !== 'undefined') {
                clearTimeout(snackbarTimeout);
            }
            snackbarTimeout = setTimeout(() => {
                clearSnackbar();
            }, 3000);

            renderSnackbar();
        }

        return () => {
            clearTimeout(snackbarTimeout);
        };
    }, [message, clearSnackbar]);

    return null;
};

export default SnackbarNotification;
