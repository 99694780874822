import Conversion from '@containers/Conversion';
import ConversionsSummary from '@containers/ConversionsSummary';
import CreateCounter from '@containers/CreateCounter';
import Dashboard from '@containers/Dashboard';
import Export from '@containers/Export';
import Funnels from '@containers/Funnels';
import Goals from '@containers/Goals';
import ImportDataFromYM from '@containers/ImportDataFromYM';
import Layout from '@containers/Layout';
import Media from '@containers/Media';
import PostClick from '@containers/PostClick';
import Projects from '@containers/Projects';
import Report from '@containers/Report';
import SettingsCounter from '@containers/SettingsCounter';
import Templates from '@containers/Templates';
import AuthDecorator from '@decorators/auth';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import React, { useEffect } from 'react';
import { Route, Navigate, Routes, useParams, useLocation } from 'react-router-dom';

const project = (path: string): string => `/stat/projects/:projectId${path}`;

const RedirectToStat = (): JSX.Element => {
    const { search } = useLocation();
    const { '*': allParams } = useParams();

    return <Navigate to={`/stat/projects/${allParams}${search}`} replace />;
};

const NoMatch = () => {
    const dispatch = useAppDispatch();
    const setError = (errorCode: number) => dispatch(globalActions.setError({ code: errorCode }));

    useEffect(() => {
        setError(404);
    }, []);

    return null;
};

export default function AppRouter() {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/stat/projects" element={<AuthDecorator Component={Projects} isPrivate />} />
                <Route path="/stat/projects/deleted" element={<AuthDecorator Component={Projects} isPrivate />} />
                <Route path="/stat/create" element={<AuthDecorator Component={CreateCounter} isPrivate />} />
                <Route
                    path={project('/settings/counter')}
                    element={<AuthDecorator Component={SettingsCounter} isPrivate />}
                />
                <Route
                    path={project('/settings/rating')}
                    element={<AuthDecorator Component={SettingsCounter} isPrivate />}
                />
                <Route
                    path={project('/settings/access')}
                    element={<AuthDecorator Component={SettingsCounter} isPrivate />}
                />
                <Route path={project('/dashboard')} element={<AuthDecorator Component={Dashboard} isPrivate />} />
                <Route path={project('/constructor')} element={<AuthDecorator Component={Templates} isPrivate />} />
                <Route path={project('/goals')} element={<AuthDecorator Component={Goals} isPrivate />} />
                <Route
                    path={project('/conversions')}
                    element={<AuthDecorator Component={ConversionsSummary} isPrivate />}
                />
                <Route
                    path={project('/conversions/:conversionId')}
                    element={<AuthDecorator Component={Conversion} isPrivate />}
                />
                <Route path={project('/funnels')} element={<AuthDecorator Component={Funnels} isPrivate />} />
                <Route path={project('/export')} element={<AuthDecorator Component={Export} isPrivate />} />
                <Route path={project('/post_click')} element={<AuthDecorator Component={PostClick} isPrivate />} />
                <Route
                    path={project('/import_data_from_ym')}
                    element={<AuthDecorator Component={ImportDataFromYM} isPrivate />}
                />
                <Route path={project('/new/report/:reportName')} element={<AuthDecorator Component={Report} />} />

                {/* Отчёты по медиа */}
                <Route path={project('/new/media/:reportName')} element={<AuthDecorator Component={Media} />} />
                <Route
                    path={project('/new/media/:reportName/:mediaId')}
                    element={<AuthDecorator Component={Media} />}
                />

                {/* Redirects */}
                <Route path="/" element={<Navigate to="/stat/projects" replace />} />
                <Route path="/stat" element={<Navigate to="projects" replace />} />
                <Route path="/stat/projects/:projectId" element={<Navigate to="dashboard" replace />} />
                <Route path="/stat/projects/:projectId/settings" element={<Navigate to="counter" replace />} />

                {/* Redirects from old sources */}
                <Route path="/create" element={<Navigate to="/stat/create" replace />} />
                <Route path="/login" element={<Navigate to="/stat/projects?login" replace />} />
                <Route path="/stat/login" element={<Navigate to="/stat/projects" replace />} />
                <Route path="/projects/*" element={<RedirectToStat />} />

                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    );
}
