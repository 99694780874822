export const getDomain = (domainLevel = 2) => {
    const { hostname } = document.location;
    let domain = '';

    if (hostname === 'localhost') {
        domain = 'localhost';
    } else {
        domain = `.${hostname.split('.').reverse().splice(0, domainLevel).reverse().join('.')}`;
    }
    return domain;
};
