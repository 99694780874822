import { ChartColors } from '@configs/graph';

export default {
    Web: {
        title: 'Web',
        color: ChartColors[0],
    },
    'Прочее': {
        title: 'Прочее',
        color: ChartColors[3],
    },
    Turbo: {
        title: 'Turbo',
        color: ChartColors[5],
    },
    AMP: {
        title: 'AMP',
        color: ChartColors[9],
    },
    Android: {
        title: 'Android',
        color: ChartColors[6],
    },
    'Web-view': {
        title: 'Web-view',
        color: ChartColors[4],
    },
    iOS: {
        title: 'iOS',
        color: ChartColors[8],
    },
    Yandex: {
        title: 'Yandex',
        color: ChartColors[9],
    },
};
