import { Input, Checkbox, Modal } from '@adtech/ui';
import React, { useEffect, useState, FC, ChangeEvent } from 'react';

import { ISavedFilterData, ESavedFilterType, ISavedFilterActionData, ESavedFilterActions } from '../types/savedFilters';
import s from './CreateOrEditSavedFilterModal.pcss';

interface IProps {
    savedFiltersTitles: string[];
    isOpen: boolean;
    onOkHandler: (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => void;
    onCloseHandler: () => void;
    modalData?: ISavedFilterActionData;
}

const CreateOrEditSavedFilterModal: FC<IProps> = ({
    savedFiltersTitles = [],
    isOpen,
    onOkHandler,
    onCloseHandler,
    modalData,
}: IProps) => {
    const { actionName, data } = modalData || {};
    const { id, title, type } = data || {};
    const [inputTitleValue, setInputTitleValue] = useState<string>(title);
    const [inputTypeValue, setInputTypeValue] = useState<ESavedFilterType>(type || ESavedFilterType.PRIVATE);
    const [validTitle, setValidTitle] = useState({ isValid: true, message: '' });
    const allSavedFiltersTitles =
        actionName === 'edit' ? savedFiltersTitles.filter((value) => title !== value) : savedFiltersTitles;

    useEffect(() => {
        if (!isOpen) return;

        setInputTitleValue(title || '');
        setInputTypeValue(type || ESavedFilterType.PRIVATE);
    }, [isOpen]);

    if (!actionName) return null;

    const modalTitle = actionName === 'edit' ? 'Редактирование шаблона' : 'Сохранение шаблона';

    // Валидация имени шаблона фильтров, от 1 до 255 символов
    const validateTitle = (value: string) => {
        let result = {
            isValid: true,
            message: '',
        };

        const trimValue = value.trim();
        const isValidValue = trimValue && !/^[\s]+$/.test(trimValue);

        if (!isValidValue) {
            result = {
                isValid: false,
                message: 'Поле не заполнено',
            };
        } else if (allSavedFiltersTitles.includes(trimValue)) {
            // Проверяем введенное имя на уникальность (если редактируем шаблон, не учитываем его исходное название)
            result = {
                isValid: false,
                message: 'Шаблон с таким названием уже существует',
            };
        }

        setValidTitle(result);

        return result.isValid;
    };

    const onChangeType = (e) => {
        const checked = e.target?.checked;
        setInputTypeValue(checked ? ESavedFilterType.SHARED : ESavedFilterType.PRIVATE);
    };

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        validateTitle(value);
        setInputTitleValue(value);
    };

    const handleBlurTitle = (e) => {
        validateTitle(e.target.value);
    };

    const modalOkHandler = () => {
        if (!validateTitle(inputTitleValue)) return;

        onOkHandler?.(actionName, {
            title: inputTitleValue,
            type: inputTypeValue,
            id,
        });

        setInputTitleValue('');
        setInputTypeValue(ESavedFilterType.PRIVATE);
    };

    const modalCloseHandler = () => onCloseHandler?.();

    return (
        <Modal
            className={s.сreateOrEditSavedFilterModal}
            isOpened={isOpen}
            title={modalTitle}
            okText="Сохранить"
            cancelText="Отменить"
            onOk={modalOkHandler}
            onClose={modalCloseHandler}
            closeOnClickOutside={false}
            centered
            size="lg"
        >
            <div>
                <div className={s.сreateOrEditSavedFilterModalLabel}>Название шаблона</div>
                <Input
                    defaultValue={title}
                    value={inputTitleValue}
                    type="text"
                    onChange={handleChangeTitle}
                    onBlur={handleBlurTitle}
                    placeholder="Введите название шаблона"
                    status={validTitle.isValid ? null : 'error'}
                    errorText={validTitle.message}
                    showCount
                    maxLength={255}
                />
                <div className={s.сreateOrEditSavedFilterModalType}>
                    <Checkbox checked={inputTypeValue === ESavedFilterType.SHARED} onChange={onChangeType}>
                        Публичный шаблон
                    </Checkbox>
                    <div className={s.сreateOrEditSavedFilterModalDescription}>
                        Все пользователи с&nbsp;доступом к&nbsp;статистике сайта могут использовать этот шаблон для
                        создания отчётов
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CreateOrEditSavedFilterModal;
