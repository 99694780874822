import { Switch, Alert } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import { TitleField } from '@components/CounterBlocks/InputData';
import inputConstants from '@constants/input';
import { projectsStatus, ratingTitleStatus, ratingTextStatus } from '@constants/projects';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import s from './Rating.pcss';
import ratingFields from './ratingFieldsConfig';

interface IProps {
    config: Record<string, any>;
    inRating: boolean;
    canRating: boolean;
    projectStatus: string;
    disableEdit?: boolean;
    withoutDelimiter?: boolean;
    ratingBlocker?: boolean;
    mode?: 'create' | 'settings';
    rootClassName?: string;
    smallTitle?: boolean;
    dataAttrName?: string;
    dataAttr?: string[];
}

interface ISwitcherProps {
    mode: string;
    status: string;
    canRating: boolean;
    ratingBlocker: boolean;
    dataAttrName?: string;
    dataAttr?: string[];
}

interface IMessageProps {
    message: string;
    linkText: string;
}

export const SupportMessage = ({ message, linkText }: IMessageProps) => (
    <span>
        {message}
        <a href="mailto:counter@ads.sber.ru" target="_blank" rel="noopener noreferrer">
            {linkText}
        </a>
        .
    </span>
);

export const SwitcherField: React.FC<ISwitcherProps> = ({
    mode,
    status,
    canRating,
    ratingBlocker,
    dataAttrName,
    dataAttr,
}) => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'inRating', control });

    const isCreate = mode === 'create';

    const renderAlertMain = (
        <p className={s.alertMessage}>
            Обращаем ваше внимание, что для участия в рейтинге обязательно наличие на сайте счётчика Статистики от
            SberAds. Инструкцию по установке счётчика можно найти&nbsp;
            <a href="https://ads.sber.ru/help/ustanovka-schyotchika" target="_blank" rel="noopener noreferrer">
                здесь
            </a>
            .
        </p>
    );

    const renderAlertWhenChecked = (
        <span className={s.alertMessage}>
            Предлагаем прочитать&nbsp;
            <a href="https://ads.sber.ru/help/uchastie-v-reitinge" target="_blank" rel="noopener noreferrer">
                рекомендации по заполнению метаинформации
            </a>
            .
        </span>
    );

    let description =
        !isCreate && ratingTextStatus[status] ? (
            ratingTextStatus[status]
        ) : (
            <SupportMessage message={ratingTextStatus[projectsStatus.DEFAULT]} linkText=" службу поддержки" />
        );

    if (canRating) {
        description = (
            <span>
                {ratingTextStatus[status]}
                {(projectsStatus.EXCLUDED === status || projectsStatus.DEFAULT === status) && (
                    <SupportMessage message="" linkText=" форму обратной связи" />
                )}
            </span>
        );
    }

    if (ratingBlocker) {
        description = (
            <SupportMessage message={ratingTextStatus[projectsStatus.DEFAULT]} linkText=" службу поддержки" />
        );
    }

    return (
        <CounterBlocks.Wrapper
            title={isCreate ? 'Участие в рейтинге Топ-100' : ratingTitleStatus[status] || ''}
            description={description}
            descClassName={s.ratingDesc}
            titleClassName={s.ratingTitle}
            smallTitle={mode === 'settings'}
            isRating
            withoutDelimiter
        >
            <div className={s.switch}>
                <Switch
                    checked={field.value}
                    onChange={field.onChange}
                    disabled={!canRating || status === projectsStatus.EXCLUDED}
                    {...(dataAttrName && dataAttr.length ? { [`data-${dataAttrName}`]: `${dataAttr.join('::')}` } : {})}
                />
                <div className={s.switchLabel}>Участвовать в рейтинге</div>
            </div>
            <div className={s.alertList}>
                <Alert type="warning" message={renderAlertMain} showIcon />
                {field.value && <Alert type="info" message={renderAlertWhenChecked} showIcon />}
            </div>
        </CounterBlocks.Wrapper>
    );
};

const Rating: React.FC<IProps> = ({
    config,
    inRating,
    canRating,
    withoutDelimiter,
    ratingBlocker,
    mode = 'create',
    disableEdit,
    projectStatus,
    rootClassName,
    dataAttrName,
    dataAttr,
}) => {
    const isConfigLoaded = (key: string) => config[key]?.loaded;

    const renderListsItem = (item: Record<string, any>, index: number) => {
        const isLoaded = isConfigLoaded(item.key);
        const data = isLoaded ? config[item.key].data.data : [];
        const map = isLoaded ? config[item.key].data.map : {};
        const listDataMode = isLoaded ? config[item.key].data.mode : 'three';

        return (
            <CounterBlocks.ListData
                key={index}
                isLoadedConfig={isLoaded}
                rules={item.rules}
                name={item.key}
                label={item.label}
                config={item}
                map={map}
                data={data}
                mode={listDataMode}
                isRequired={item.isRequired}
                disabled={disableEdit}
                smallTitle
            />
        );
    };

    const renderLists = () => <div>{ratingFields.map((item, key) => renderListsItem(item, key))}</div>;

    return (
        <CounterBlocks.Wrapper mainWrap withoutDelimiter={withoutDelimiter} rootClassName={rootClassName}>
            <SwitcherField
                status={projectStatus}
                mode={mode}
                canRating={canRating}
                ratingBlocker={ratingBlocker}
                dataAttrName={dataAttrName}
                dataAttr={dataAttr}
            />
            {inRating ? (
                <div className={s.settings}>
                    <div className={s.titleField}>
                        <CounterBlocks.Wrapper subWrap withoutDelimiter rootClassName={s.ratingField}>
                            <TitleField
                                disabled={disableEdit}
                                showHint
                                // eslint-disable-next-line max-len
                                hint="Для сайтов, участвующих в рейтинге, максимальная длина названия составляет 55 символов"
                                maxTitleLength={inputConstants.MAX_TITLE_LENGTH_IN_RATING}
                                placeholder="Будет отображаться в списке сайтов"
                            />
                        </CounterBlocks.Wrapper>
                    </div>
                    {renderLists()}
                    <CounterBlocks.KeywordsData disabled={disableEdit} isRequired />
                    <CounterBlocks.DisplayStatistics />
                </div>
            ) : null}
        </CounterBlocks.Wrapper>
    );
};

export default Rating;
