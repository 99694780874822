import { Button, Textarea } from '@adtech/ui';
import snackbarsConstants from '@constants/snackbars';
import { SettingsPageUtils } from '@utils/settingsPageUtils';
import React, { useEffect, useRef } from 'react';

import s from './CounterCode.pcss';

interface IProps {
    id: string;
    counterType: number;
    blockAnalyticsAttribute: string;
    useBlockAnalytics: boolean;
    useHashes: boolean;
    useUserId: boolean;
    setSnackbar: (message: React.ReactNode, type?: string) => void;
    mode?: 'default' | 'create';
}

const CounterCode: React.FC<IProps> = ({
    id,
    counterType,
    blockAnalyticsAttribute,
    useBlockAnalytics,
    useHashes,
    useUserId,
    setSnackbar,
    mode = 'default',
}) => {
    const counterCode = useRef<HTMLDivElement>(null);
    const timeoutId = useRef<ReturnType<typeof setTimeout>>(null);

    useEffect(() => {
        // setTimeout - костыль.
        // Проблема работы с хешами, страница может проскролиться на какое-то расстояние,
        // но не для нужного элемента
        timeoutId.current = setTimeout(() => {
            const { current } = counterCode;
            if (current && window.location.hash === '#counter-code') {
                current.scrollIntoView({
                    block: 'start',
                    inline: 'center',
                });
            }
        }, 0);
        return () => clearTimeout(timeoutId.current);
    }, []);

    const handleClickButton = (event: React.MouseEvent<HTMLButtonElement | HTMLTextAreaElement>) => {
        event.preventDefault();

        const el = document.getElementById('code') as HTMLTextAreaElement;
        el.focus();
        el.select();
        document.execCommand('copy');

        setSnackbar(snackbarsConstants.COPY_CODE);
    };

    const renderCounterCode = () => (
        <div className={s.counterCode}>
            <Textarea
                readOnly
                className={s.counterCodeTextarea}
                id="code"
                value={SettingsPageUtils.getCounterCode({
                    id,
                    type: counterType,
                    attrAnalytic: blockAnalyticsAttribute,
                    useAnalytic: useBlockAnalytics,
                    useHashes,
                    useUserId,
                })}
                onDoubleClick={handleClickButton}
            />
        </div>
    );

    const renderCopyButton = () => {
        const type = mode === 'default' || mode === 'create' ? 'default' : 'dashed';

        return (
            <Button type={type} onClick={handleClickButton} className={s.button}>
                Скопировать код
            </Button>
        );
    };

    const renderDefaultDescription = () => {
        const defaultModeText =
            'Для корректного подсчёта статистики необходимо установить код на все страницы сайта. Код можно встроить в любое место HTML-кода, желательно в начало страницы, чтобы счётчик успел загрузиться при коротких визитах.'; // eslint-disable-line max-len

        const createModeText = 'Код также доступен на странице настроек.';

        const description = mode === 'default' ? defaultModeText : createModeText;

        return <div className={s.description}>{description}</div>;
    };

    const defaultModeText =
        'Установите код счётчика на все страницы сайта, ' +
        'по которым должна собираться статистика. Код также доступен на странице настроек.';

    const createModeText = (
        <>
            Для подсчёта статистики добавьте код счётчика на все страницы сайта.
            <br />
            Его можно встроить в любое место HTML-кода страницы, желательно в начало, чтобы счётчик успел загрузиться
            при коротких визитах.
        </>
    );

    const description = mode === 'default' ? defaultModeText : createModeText;

    return (
        <div ref={counterCode}>
            <div className={s.description}>{description}</div>
            {renderCopyButton()}
            {renderCounterCode()}
            {renderDefaultDescription()}
        </div>
    );
};

export default CounterCode;
