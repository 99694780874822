class FormUtils {
    public static rules = {
        required: (value: string) => Boolean(value && !/^[\s]+$/.test(value)),
        // prettier-ignore
        maxLength: (max: number) => (value: string) => (
            value?.length <= max
        ),
        // prettier-ignore
        minLength: (min: number) => (value: string) => (
            value?.trim().length >= min
        ),
        // prettier-ignore
        email: (value: string) => Boolean(
            value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ),
        // prettier-ignore
        url: (value: string) => Boolean(
            value && /^((http|https):\/\/)?([a-zа-яё0-9]+([\-\.]{1}[a-zа-яё0-9]+)*\.[a-zа-яё]{2,5}|localhost|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|)){4})(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
        ),
        // prettier-ignore
        urlWithDomainZone: (value: string) => Boolean(
            value && /^((http|https):\/\/)?(([a-zа-яё0-9]{1,}[a-zа-яё0-9\-]*[a-zа-яё0-9]*\.)+(xn--[a-z0-9]+|[a-zа-яё]{2,}))(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
        ),
        // prettier-ignore
        urlWithProtocolAndParams: (value: string) => Boolean(
            value && /^((http|https):\/\/)(([a-zа-яё0-9\-]{1,}[a-zа-яё0-9]?\.)+(xn--[a-z0-9]+|[a-zа-яё]{2,63}|localhost|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|)){4}))(:[0-9]{1,5})?(\/.*)?$/.test(value) // eslint-disable-line
        ),
    };
}

export default FormUtils;
