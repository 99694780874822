import { Button, DropDownButton, DropDownList, IconDots, IconEdit, IconTrash, Tooltip } from '@adtech/ui';
import cn from 'classnames';
import React, { FC, useState } from 'react';
import { TFilters } from '../types/filters';
import { ESavedFilterActions, ESavedFilterType, ISavedFilterData } from '../types/savedFilters';
import s from './SavedFiltersList.pcss';

interface IProps {
    list: ISavedFilterData[];
    filtersData: TFilters;
    currentFilterId: number;
    setCurrentFilterId: (id: number) => void;
    isLoading: boolean;
    onClickAction: (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => void;
    applyFiltersHandler?: (filtersData: TFilters) => void;
}

interface IMenuAction {
    title: string;
    icon: React.ReactNode;
    value: ESavedFilterActions;
}

const SavedFiltersList: FC<IProps> = ({
    list,
    filtersData,
    currentFilterId,
    setCurrentFilterId,
    isLoading,
    onClickAction,
    applyFiltersHandler,
}) => {
    const actionsList: IMenuAction[] = [
        {
            title: 'Редактировать',
            icon: <IconEdit />,
            value: ESavedFilterActions.edit,
        },
        {
            title: 'Удалить',
            icon: <IconTrash />,
            value: ESavedFilterActions.delete,
        },
    ];
    const [isActionListOpen, setIsActionListOpen] = useState(false);
    const [actionActiveId, setActionActiveId] = useState<number>(null);
    const currentFilter = list.find(({ id }) => id === currentFilterId);
    const isFilterEdited =
        currentFilter?.filters && JSON.stringify(filtersData) !== JSON.stringify(currentFilter?.filters);

    let selectedTitle = currentFilter?.title;
    if (isFilterEdited && selectedTitle) selectedTitle = `${selectedTitle} (изменён)`;

    const renderContextMenuAction = (action: IMenuAction, itemData: ISavedFilterData) => ({
        dropDownItemProps: {
            text: (
                <span className={s.savedFiltersListActionsItem}>
                    <span className={s.savedFiltersListActionsItemIcon}>{action.icon}</span> {action.title}
                </span>
            ),
            onClick: () => {
                onClickAction(action.value, itemData);
                setIsActionListOpen(false);
                setActionActiveId(null);
            },
        },
    });

    const getItem = (itemData: ISavedFilterData, itemIndex: number) => {
        const { title, id, type, filters } = itemData || {};
        const actionsBtnClass = cn(s.savedFiltersListActionsBtn, {
            [s.savedFiltersListActionsBtnOpen]: itemIndex === actionActiveId,
        });

        const clickItemHandler = () => {
            setCurrentFilterId(id);
            applyFiltersHandler(filters);
        };

        const onOpenChange = (open: boolean) => {
            setIsActionListOpen(open);
            if (!open) setActionActiveId(null);
        };

        return {
            dropDownItemProps: {
                className: s.savedFiltersListItem,
                onClick: clickItemHandler,
                text: (
                    <div className={s.savedFiltersListItemTitle}>
                        {title}
                        <div className={s.savedFiltersListItemType}>
                            {type === ESavedFilterType.SHARED ? 'Публичный' : 'Личный'}
                        </div>
                    </div>
                ),
                icon: (
                    <DropDownList
                        className={cn(s.savedFiltersListItemActions, 'drop-down-list')}
                        onOpenChange={onOpenChange}
                        menuItems={actionsList.map((action) => renderContextMenuAction(action, itemData))}
                        customButton={
                            <Button
                                className={actionsBtnClass}
                                size="small"
                                round
                                prefixIcon={<IconDots />}
                                type="dashed"
                                onClick={() => setActionActiveId(itemIndex)}
                            />
                        }
                    />
                ),
            },
        };
    };

    const renderLoading = [
        {
            dropDownItemProps: {
                text: null,
                className: s.savedFiltersListItem,
            },
        },
    ];

    const isDropdownDisabled = !list?.length || isLoading;

    const renderDropdownBtn = () => (
        <DropDownButton size="small" type="dashed" className={s.savedFiltersListAnchor} disabled={isDropdownDisabled}>
            <span className={s.savedFiltersListAnchorText}>{selectedTitle || 'Шаблоны фильтров'}</span>
        </DropDownButton>
    );

    return (
        <DropDownList
            className={cn(s.savedFiltersList, 'drop-down-list', { [s.savedFiltersListOverlay]: isActionListOpen })}
            menuItems={isLoading ? renderLoading : list.map((item, index) => getItem(item, index))}
            customButton={
                isDropdownDisabled ? (
                    <Tooltip title="Здесь хранятся ранее сохраненные шаблоны">{renderDropdownBtn()}</Tooltip>
                ) : (
                    renderDropdownBtn()
                )
            }
        />
    );
};

export default SavedFiltersList;
