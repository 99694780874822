import { Button, ContextAlertMark } from '@adtech/ui';
import Form from '@components/Form';
import attributesData from '@configs/attributesData';
// import captchaConfig from "@configs/captcha";
import serverErrorsConstants from '@constants/serverErrors';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import * as Sentry from '@sentry/react';
import { IRootSlice } from '@typings/rootSlice';
import { top100Counter } from '@utils/index';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import s from './FirstStep.pcss';
import Captcha from './components/fields/Captcha';
import Terms from './components/fields/Terms';
import Title from './components/fields/Title';
import Url from './components/fields/Url';

interface IProps {
    goToRestoreCounter: () => void;
    nextStep: () => void;
    projectsWithSameUrlExist: boolean;
}

interface IFormFields {
    title: string;
    url: string;
    agree: boolean;
}

const FirstStep: React.FC<IProps> = ({ goToRestoreCounter, nextStep, projectsWithSameUrlExist }) => {
    const dispatch = useAppDispatch();

    const needCaptcha = useAppSelector((state: IRootSlice) => state.projectsSlice.needCaptcha);
    const captchaRequest = useAppSelector((state: IRootSlice) => state.projectsSlice.captchaRequest);
    const defaultTitle = useAppSelector((state: IRootSlice) => state.projectsSlice.defaultTitle);
    const createCounterRequest = useAppSelector((state: IRootSlice) => state.projectsSlice.createCounterRequest);
    const getProjectRequest = useAppSelector((state: IRootSlice) => state.projectsSlice.projectRequest);
    const projectsWithSameUrlRequest = useAppSelector(
        (state: IRootSlice) => state.projectsSlice.projectsWithSameUrlRequest,
    );

    const [serverError, setServerError] = useState<string>(null);

    const defaultValues = {
        title: '',
        url: '',
        agree: false,
    };
    const formMethods = useForm<IFormFields>({
        defaultValues,
        values: { ...defaultValues },
    });

    const {
        handleSubmit,
        // setError,
    } = formMethods;

    useEffect(() => {
        dispatch(projectsActions.getCaptcha());
        dispatch(projectsActions.resetParam('defaultTitle'));
    }, []);

    const getDefaultTitle = debounce((url: string) => {
        dispatch(projectsActions.getMetadata(url));
    }, 1000);

    const openModalPopup = (modalPopupId, modalPopupParams = {}) => {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    };

    const createCounterHandler: SubmitHandler<IFormFields> = (values) => {
        // if (!values.captcha_response && needCaptcha) {
        //     setError('captcha_response', { type: "custom", message: captchaConfig.notValid });
        //     return;
        // }

        /**
         * Создаем счётчик и проверяем ответ.
         * Валидируем и подсвечиваем ошибки из ответа.
         */
        const createAndCheckCounter = () => {
            top100Counter.trackEvent('create_counter', { send: true });

            dispatch(projectsActions.create(values))
                .then(({ payload }) => {
                    top100Counter.trackEvent('create_counter', { status: payload.responseStatus });

                    if (payload.responseStatus === 200) {
                        dispatch(projectsActions.getProject({ projectId: payload.project_id })).then(() => {
                            nextStep();
                            dispatch(projectsActions.clearProjectsWithSameUrl());
                        });
                    } else if (payload && payload.errors) {
                        Sentry.captureException({
                            response: payload.errors,
                            request: values,
                        });
                        Sentry.captureMessage(payload.errors.message);

                        if (payload.errors.message === serverErrorsConstants.NOT_VALID_CAPTCHA) {
                            // TODO: добавить после добавления капчи
                            // setError('captcha_response', { type: "custom", message: captchaConfig.notValid });
                        } else if (payload.errors.message === serverErrorsConstants.REQUEST_VALIDATION_ERRORS) {
                            setServerError('Ошибка валидации');
                        }
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                });
        };

        /**
         * Проверяем существуют ли счётчики с похожим url.
         * Если существуют - показываем попап о восстановлении счётчика.
         */
        if (!projectsWithSameUrlExist) {
            dispatch(
                projectsActions.getProjectsWithSameUrl({
                    url: values.url,
                    limit: 15,
                    offset: 0,
                }),
            ).then(({ payload }) => {
                if (payload.responseStatus === 200) {
                    if (payload.total) {
                        openModalPopup('RestoreAccessCounter', {
                            goToRestoreCounter,
                            createCounter: createAndCheckCounter,
                        });
                    } else {
                        createAndCheckCounter();
                    }
                }
            });
        } else {
            createAndCheckCounter();
        }
    };

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(createCounterHandler)}>
                <Url getDefaultTitle={getDefaultTitle} />
                <Title defaultTitle={defaultTitle} />
                <Terms />
                {/* TODO: при добавлении капчи, необходимо будет проверить и скорректировать (пока каптча отключена) */}
                {needCaptcha && <Captcha loading={captchaRequest} />}
                <Button
                    loading={createCounterRequest || getProjectRequest || projectsWithSameUrlRequest}
                    htmlType="submit"
                    className={s.createButton}
                    data-create-counter={`${attributesData.createCounter.firstStep}::${attributesData.createCounter.createCounterBtn}`}
                >
                    Создать счётчик
                </Button>
                <div className={s.serverError}>
                    {serverError && <ContextAlertMark message={serverError} status="error" />}
                </div>
            </Form>
        </FormProvider>
    );
};

export default FirstStep;
