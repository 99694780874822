import { Button, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import serverErrorsConstants from '@constants/serverErrors';
import snackbarTypes from '@constants/snackbarTypes';
import snackbarsConstants from '@constants/snackbars';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { projectsActions } from '@redux/slices/projects';
import { IUsersValue } from '@typings/counter';
import * as React from 'react';
import { useEffect, useState } from 'react';

import s from '../SettingsCounter.pcss';

const AccessForm: React.FC = () => {
    const currentProject = useAppSelector((state) => state.projectsSlice.current);
    const projectUsers = useAppSelector((state) => state.projectsSlice.current.users);
    const saveCounterRequest = useAppSelector((state) => state.projectsSlice.saveCounterRequest);
    const dispatch = useAppDispatch();
    const { setSnackbar } = useSnackbar();

    const getUsers = () =>
        projectUsers.map((user) => ({
            accessLevel: user.access_level ? 'view' : 'edit',
            email: user.user.email,
        }));

    const [usersList, setUsersList] = useState<IUsersValue[]>(getUsers());
    const [serverError, setServerError] = useState<string>(null);

    useEffect(() => {
        setUsersList(getUsers());
    }, [projectUsers]);

    const saveCounter = (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data }));

    const saveAccessSettings = () => {
        const users = usersList.map((user) => ({
            email: user.email,
            access_level: user.accessLevel === 'edit' ? 0 : 1,
            can_edit_project: user.accessLevel === 'edit',
        }));

        const data = { users };

        return saveCounter(currentProject.id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                let message;

                const newUsers = payload?.data?.users;

                // Выводим сообщение об изменениях доступа.
                // Сообщение о выдаче доступа - в приоритете.
                if (newUsers?.length > projectUsers?.length) {
                    message = snackbarsConstants.ACCSSES_GRANTED;
                } else {
                    message = snackbarsConstants.ACCSSES_CHANGED;
                }

                setSnackbar(message, snackbarTypes.WARNING);

                window.scrollTo(0, 0);
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                } else if (payload.errors.message === serverErrorsConstants.RATE_LIMIT_EXCEEDED) {
                    // prettier-ignore
                    // eslint-disable-next-line max-len
                    setServerError('В день вы можете добавить не более 20 пользователей. Чтобы дать доступ большему числу людей, вернитесь завтра или обратитесь в <a href="mailto:counter@ads.sber.ru">службу поддержки.</a>');
                }
            }
        });
    };

    return (
        <div>
            <CounterBlocks.AccessUsers usersList={usersList} setUsersList={setUsersList} />
            <div className={s.actions}>
                <Button loading={saveCounterRequest} disabled={saveCounterRequest} onClick={saveAccessSettings}>
                    Сохранить изменения
                </Button>
                {serverError && <ContextAlertMark message={serverError} status="error" />}
            </div>
        </div>
    );
};

export default AccessForm;
