import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

declare const window: IWindow;
declare const grecaptcha;

interface IWindow extends Window {
    grecaptcha: {
        render;
        reset;
    };
}

interface IProps {
    elementID: string;
    siteKey: string;
    callback;
    onloadCallback?;
    theme: 'light' | 'dark';
    hl: string;
    size: 'normal' | 'compact';
    resetCaptcha: boolean;
}

// TODO: проверить после включения на бэке
const GReCaptcha: React.FC<IProps> = ({
    elementID = 'g-recaptcha',
    siteKey = '',
    callback = () => {},
    onloadCallback,
    theme = 'light',
    hl = 'ru',
    size = 'normal',
    resetCaptcha,
}) => {
    const isReadyCheck = () =>
        typeof window !== 'undefined' &&
        typeof window.grecaptcha !== 'undefined' &&
        typeof window.grecaptcha.render === 'function';

    const [isReady, setIsReady] = useState(isReadyCheck());
    const [captcha, setCaptcha] = useState(null);
    const readyCheckInterval = useRef<ReturnType<typeof setTimeout>>(null);

    const loadCaptcha = () => {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = 'https://www.google.com/recaptcha/api.js';
        document.body.appendChild(script);
    };

    const renderCaptcha = () => {
        setCaptcha(
            grecaptcha.render(elementID, {
                sitekey: siteKey,
                callback,
                theme,
                hl,
                size,
            }),
        );

        if (onloadCallback) onloadCallback();
    };

    const updateReadyState = () => {
        if (isReadyCheck()) {
            setIsReady(true);

            clearInterval(readyCheckInterval.current);
        }
    };

    useEffect(() => {
        if (isReady) {
            renderCaptcha();
        } else {
            loadCaptcha();
        }

        if (!isReady && window !== undefined) {
            readyCheckInterval.current = setInterval(updateReadyState, 1000);
        }

        return () => {
            if (readyCheckInterval) clearInterval(readyCheckInterval.current);
        };
    }, []);

    useEffect(() => {
        if (isReady && !captcha) {
            renderCaptcha();
        }
    }, [isReady]);

    const reset = () => {
        if (isReady && captcha !== null) {
            window.grecaptcha.reset(captcha);
        }
    };

    useEffect(() => {
        if (resetCaptcha) reset();
    }, [resetCaptcha]);

    return <div id={elementID} />;
};

export default GReCaptcha;
