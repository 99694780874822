/* eslint-disable max-len */
export default [
    {
        key: 'categories',
        title: 'Темы',
        label: 'Темы',
        titlePopup: 'Добавить темы',
        description:
            'Рекомендуем указывать тему точно подходящую для всего сайта. Сайты, привязанные к темам второго уровня, будут отображаться при выборе темы первого уровня.',
        labelButton: 'Добавить тему',
        labelSelected: 'Выберите от 1 до 7 тем',
        isRequired: true,
        rules: {
            required: {
                value: true,
                message: 'Обязательное поле',
            },
            validate: (val) => val?.length <= 7 || 'Максимальное количество тем: 7',
        },
    },
    {
        key: 'types',
        title: 'Типы',
        label: 'Типы',
        titlePopup: 'Добавить типы',
        description: 'Определяет тип, характер сайта.',
        labelButton: 'Добавить тип',
        labelSelected: 'Выберите не более 2 типов',
        rules: {
            validate: (val) => val?.length <= 2 || 'Максимальное количество типов: 2',
        },
    },
    {
        key: 'regions',
        title: 'Регионы',
        label: 'Регионы',
        titlePopup: 'Добавить регионы',
        description:
            'Регион, информация о котором содержится на сайте, либо регион, в котором предоставляются услуги, описанные на сайте.',
        labelButton: 'Добавить регион',
        labelSelected: 'Выберите не более 10 регионов',
        rules: {
            validate: (val) => val?.length <= 10 || 'Максимальное количество регионов: 10',
        },
    },
];
