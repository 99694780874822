import { MultilevelMenu, IconClose, ChipButton } from '@adtech/ui';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import { TAllFormChangeStatus, TAllFormValues, TDimension, TFiltersListCnf, TFiltersMenu } from '../types/filters';
import s from './FiltersList.pcss';

interface IProps {
    filtersListCnf: TFiltersListCnf;
    currentKey: string;
    setActiveKey: (key: string) => void;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    searchValue: string;
}

interface IMenuItems {
    values?: TFiltersMenu<React.ReactNode>;
    changesCount?: number;
    changedKeys?: TDimension[];
}

const FiltersList: React.FC<IProps> = ({
    filtersListCnf,
    currentKey,
    setActiveKey,
    allFormsValues,
    setAllFormsValues,
    searchValue,
}) => {
    // Модифицируем общий стейт форм в более удобную форму статусов изменений, которая намного реже меняется
    const transformFormValuesToStatuses = (formsValues: TAllFormValues): TAllFormChangeStatus => {
        let formsChangeStatus = {};

        Object.keys(formsValues).forEach((key) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const formValue of formsValues[key]) {
                let { value } = formValue;

                if (typeof value !== 'string') {
                    value = value?.join('');
                }

                if (value?.length) {
                    formsChangeStatus = { ...formsChangeStatus, [key]: true };
                    break;
                }
            }
        });

        return formsChangeStatus;
    };

    const [openMenuKeys, setOpenMenuKeys] = useState(null);
    const prevSearchValue = useRef(searchValue);
    const allFormsChangeStatus = transformFormValuesToStatuses(allFormsValues);
    let openKeys = [];

    // Очищаем конфиг от лишних свойств для компонента меню
    const clearItemsUselessProps = (items: TFiltersListCnf): TFiltersMenu =>
        items.map(({ key, label, children }) => {
            let newChildren: TFiltersMenu = null;

            if (children?.length) {
                newChildren = clearItemsUselessProps(children);
            }

            return {
                key,
                label,
                ...(newChildren?.length ? { children: [...newChildren] } : {}),
            };
        });
    const clearItems = useMemo(() => clearItemsUselessProps(filtersListCnf), [JSON.stringify(filtersListCnf)]);

    // Поиск
    const filterItemsBySearch = (items: TFiltersMenu, isRoot?: boolean): TFiltersMenu => {
        const values = [];

        items.forEach((item) => {
            let newChildren: TFiltersMenu = null;

            if (item.children?.length) {
                newChildren = filterItemsBySearch(item.children);
            }

            // Если нашли потомков, то просто добавляем родителя вместе с ними
            if (newChildren?.length) {
                values.push({ ...item, children: newChildren });
                openKeys.push(item.key);
                // Если потомков нет или среди них нет искомых, то проверяем текущий узел
            } else if (item.label.toLowerCase().includes(searchValue.toLowerCase())) {
                values.push(item);

                if (item.children?.length) openKeys.push(item.key);
            }
        });

        // Помечаем открытыми найденные узлы
        if (isRoot && JSON.stringify(openMenuKeys) !== JSON.stringify(openKeys)) {
            setOpenMenuKeys(openKeys);
            openKeys = [];
        }

        return values;
    };
    const getSearchItems = (items: TFiltersMenu) => {
        if (searchValue) {
            prevSearchValue.current = searchValue;
            return filterItemsBySearch(items, true);
        }

        // При сбросе поиска помечаем все узлы закрытыми
        if (searchValue !== prevSearchValue.current) {
            setOpenMenuKeys([]);
        }

        prevSearchValue.current = searchValue;

        return items;
    };
    const searchItems = useMemo(() => getSearchItems(clearItems), [searchValue]);

    const menuChipBtnClickHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, changedKeys: TDimension[]) => {
        e.stopPropagation();

        const newFormValues = { ...allFormsValues };
        changedKeys.forEach((key) => delete newFormValues[key]);
        setAllFormsValues(newFormValues);
    };
    const renderMenuItemLabel = (label: string, count: number, changedKeys: TDimension[], isRoot?: boolean) => {
        const labelClass = isRoot ? s.filtersListMenuLabel : s.filtersListMenuText;

        return (
            <div className={labelClass}>
                <span className={s.filtersListMenuTextValue}>{label}</span>
                {count ? (
                    <ChipButton
                        icon={<IconClose />}
                        size="small"
                        type="primary"
                        onClick={(e) => menuChipBtnClickHandler(e, changedKeys)}
                        checked
                    >
                        {count}
                    </ChipButton>
                ) : null}
            </div>
        );
    };
    // Помечаем выбранные узлы и модифицируем лейблы в react nodes
    // Для оптимизации используем не стейт форм, а стейт изменений форм
    const getMenuItems = (items: TFiltersMenu, isRoot?: boolean): IMenuItems => {
        const values: TFiltersMenu<React.ReactNode> = [];
        let changesCount = 0;
        let changedKeys = []; // ключи-дименшины, в которых были изменения

        items.forEach((item) => {
            let result: IMenuItems = {};

            if (item.children?.length) {
                result = getMenuItems(item.children);
            }

            // Родительский узел
            if (result.values?.length) {
                values.push({
                    ...item,
                    label: renderMenuItemLabel(item.label, result.changesCount, result.changedKeys, isRoot),
                    children: result.values,
                });
                changesCount += result.changesCount;
                changedKeys = [...changedKeys, ...result.changedKeys];
                // Листовой узел
            } else {
                // eslint-disable-next-line no-lonely-if
                if (allFormsChangeStatus[item.key]) {
                    changedKeys.push(item.key);
                    changesCount += 1;
                    values.push({
                        ...item,
                        label: renderMenuItemLabel(item.label, 1, [item.key], isRoot),
                    });
                } else {
                    values.push(item);
                }
            }
        });

        return { values, changedKeys, changesCount };
    };
    const { values: menuItems } = useMemo(
        () => getMenuItems(searchItems, true),
        [JSON.stringify(allFormsChangeStatus)],
    );

    // Раскрываем узлы меню при клике на chip кнопку с выбранным фильтром
    const getDefaultOpenKeys = (items: TFiltersMenu): string[] => {
        let values = [];

        items.forEach(({ children, key }) => {
            let newOpenKeys: string[] = [];

            if (children?.length) {
                newOpenKeys = getDefaultOpenKeys(children);
            }

            if (newOpenKeys?.length) {
                values = [...newOpenKeys, key];
            } else if (key === currentKey) {
                values.push(key);
            }
        });

        return values;
    };
    const defaultOpenKeys = useMemo(() => getDefaultOpenKeys(clearItems), [currentKey]);

    const selectMenuItemHandler = (item) => {
        setActiveKey(item?.key);
    };

    const openSubmenuHandler = (keys: string[]) => {
        setOpenMenuKeys(keys);
    };

    return (
        <div className={s.filtersList}>
            <MultilevelMenu
                className={s.filtersListMenu}
                items={menuItems}
                onSelect={selectMenuItemHandler}
                defaultOpenKeys={defaultOpenKeys}
                onOpenChange={openSubmenuHandler}
                {...(openMenuKeys?.length ? { openKeys: openMenuKeys } : {})}
            />
        </div>
    );
};

export default FiltersList;
