import { Button, ContextAlertMark, Input, Loader } from '@adtech/ui';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { userActions } from '@redux/slices/users';
import { IUsersValue } from '@typings/counter';
import formUtils from '@utils/formUtils';
import React, { useState, ChangeEvent } from 'react';
import s from './AccessUsers.pcss';
import RadioField, { EAccessValues } from './Fields/RadioField';

interface IProps {
    usersList: IUsersValue[];
    setUsersList: (value: IUsersValue[]) => void;
}

const AddUserForm: React.FC<IProps> = ({ usersList, setUsersList }) => {
    const dispatch = useAppDispatch();
    const { setSnackbar } = useSnackbar();

    const [inputValue, setInputValue] = useState('');
    const [radioValue, setRadioValue] = useState(EAccessValues.view);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [inputError, setInputError] = useState<string>(null);
    const [serverError, setServerError] = useState<string | boolean>(null);
    const [isLoading, setIsLoading] = useState(false);

    const getUserExists = (email) => dispatch(userActions.getUserExists(email));

    const changeInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target || {};

        setEmailIsValid(formUtils.rules.required(value) && formUtils.rules.email(value));

        setInputValue(e.target.value);
        setInputError('');
        setServerError('');
    };

    const handleClick = () => {
        const ERR_MSG = 'Такой email не найден в системе, пользователю необходимо пройти регистрацию';

        const isUserExistsInList = usersList.some((user) => user.email === inputValue);

        if (isUserExistsInList) {
            setInputError('Пользователь уже добавлен');
            setEmailIsValid(false);
            return;
        }

        setIsLoading(true);

        getUserExists(inputValue)
            .then((data) => {
                if (data.payload.exists) {
                    setUsersList([
                        ...usersList,
                        {
                            email: inputValue,
                            accessLevel: radioValue,
                        },
                    ]);
                    setServerError('');
                    setSnackbar('Пользователь добавлен в список. Пожалуйста, сохраните изменения');
                } else {
                    setServerError(ERR_MSG);
                }
            })
            .catch(() => {
                setServerError(ERR_MSG);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Loader loading={isLoading}>
            <div className={s.addNewUserFields}>
                <Input
                    name="email"
                    value={inputValue}
                    placeholder="Введите email"
                    onChange={changeInputHandler}
                    status={inputError ? 'error' : ''}
                    errorText={inputError || ''}
                    autoComplete="off"
                    type="text"
                />
                <RadioField value={radioValue} setValue={setRadioValue} />
            </div>
            <div className={s.addNewUserAction}>
                <Button disabled={!emailIsValid} type="dashed" onClick={handleClick} className={s.addNewUserButton}>
                    Дать доступ
                </Button>
                {serverError && <ContextAlertMark status="error" message={serverError} />}
            </div>
            <div className={s.addNewUserText}>
                Пользователь, которому выдается доступ, должен быть зарегистрирован в системе
            </div>
        </Loader>
    );
};

export default AddUserForm;
