import { IconChevronDown, TableSearch } from '@adtech/ui';
import useIntersectionObserver from '@hooks/useIntersectionObserver';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import s from './DataList.pcss';

export interface IDataListItem {
    title?: string;
    name?: string;
    main?: boolean; // корневая нода(ы), которая обычно раскрыта
    children?: IDataListItem[];
    parent?: string; // название родительской ноды
    onClick?: (item?: IDataListItem) => void;
}

interface IProps {
    list: IDataListItem[];
    isSearchEnabled?: boolean;
    searchPlaceholder?: string;
    classNameList?: string;
    classNameListScroller?: string;
    renderItemHandler?: (item: IDataListItem) => JSX.Element;
    onListItemClick?: (item: IDataListItem) => void;
    onListItemNestedClick?: (item: IDataListItem) => void;
    onScrollToListEnd?: () => void;
}

const DataList: React.FC<IProps> = ({
    list,
    isSearchEnabled = false,
    classNameList,
    classNameListScroller,
    searchPlaceholder,
    renderItemHandler,
    onListItemClick,
    onListItemNestedClick,
    onScrollToListEnd,
}) => {
    const [expandedNodes, setExpandedNodes] = useState({});
    const [searchNodes, setSearchNodes] = useState({});
    const [isSearchActive, setSearchActive] = useState(false);

    const { elemRef, isIntersecting } = useIntersectionObserver();

    const listClassNames = cn(s.list, classNameList);

    const setMainNodesExpanded = () => {
        list.forEach((item) => {
            if (!item.main) return;

            setExpandedNodes((nodes) => ({ ...nodes, [item.name]: true }));
        });
    };

    useEffect(() => {
        setMainNodesExpanded();
    }, [list.length]);

    useEffect(() => {
        if (isIntersecting && onScrollToListEnd) {
            onScrollToListEnd();
        }
    }, [isIntersecting]);

    // Добавляем ноду и ее потомков в список найденных
    const markNodeAsMatched = (item: IDataListItem) => {
        const { name, children } = item;

        setSearchNodes((nodes) => ({ ...nodes, [name]: true }));

        if (children) {
            children.forEach((child) => markNodeAsMatched(child));
        }
    };

    const searchOnList = (item: IDataListItem, value: string = '') => {
        const { name, title, children } = item;
        let isMatched = title.toLowerCase().includes(value.toLowerCase());
        let isChildMatched = false;

        if (children) {
            children.forEach((child) => {
                isChildMatched = searchOnList(child, value) || isChildMatched;
            });

            // Если хотя бы один потомок соответствует поиску
            if (isChildMatched) {
                setExpandedNodes((nodes) => ({ ...nodes, [name]: true }));
                isMatched = isChildMatched;
                // Если текущий соответствующий узел не имеет потомков соответствующих поиску,
                // все равно помечаем их как найденные, чтобы их можно было показать при раскрытии узла
            } else if (isMatched) {
                children.forEach((child) => markNodeAsMatched(child));
            }
        }

        if (isMatched) {
            setSearchNodes((nodes) => ({ ...nodes, [name]: true }));
        }

        return isMatched;
    };

    const searchHandler = (e) => {
        const { value } = e.target;
        setSearchActive(value !== '');
        setExpandedNodes({});
        setSearchNodes({});

        if (value === '') {
            setMainNodesExpanded();
            return;
        }

        list.forEach((item) => searchOnList(item, value));
    };

    const listItemClickHandler = (item: IDataListItem): void => {
        if (onListItemClick) onListItemClick(item);
    };

    const listItemNestedClickHandler = (item: IDataListItem): void => {
        const { name } = item;

        if (onListItemNestedClick) {
            onListItemNestedClick(item);
            return;
        }

        setExpandedNodes({ ...expandedNodes, [name]: !expandedNodes[name] });
    };

    const renderItem = (item: IDataListItem) => {
        const { name, title, children, main } = item;

        const listItemClass = cn(s.listItem, { [s.listItemMain]: !!main });
        const listItemArrowClass = cn(s.listItemArrowIcon, {
            [s.expanded]: expandedNodes[name],
        });

        if (isSearchActive && !searchNodes[name]) return null;

        if (children) {
            return (
                <div className={listItemClass} key={name} data-testid="list-item">
                    <div
                        className={s.listItemTitle}
                        onClick={() => listItemNestedClickHandler(item)}
                        data-testid="firstTopic"
                    >
                        {title}
                        <span className={listItemArrowClass}>
                            <IconChevronDown />
                        </span>
                    </div>
                    {expandedNodes[name] ? children.map((child) => renderItem(child)) : null}
                </div>
            );
        }

        return (
            <div className={s.listItem} key={name} onClick={() => listItemClickHandler(item)} data-testid="list-item">
                <div className={s.listItemTitle} data-testid="secondTopic">
                    {renderItemHandler ? renderItemHandler(item) : title}
                </div>
            </div>
        );
    };

    return (
        <div className={s.root}>
            {isSearchEnabled ? (
                <div className={s.search}>
                    <TableSearch
                        onInput={searchHandler}
                        searchPlaceholder={searchPlaceholder}
                        onClearIconClick={searchHandler}
                    />
                </div>
            ) : null}
            <div className={cn(s.listScroller, classNameListScroller)}>
                <div className={listClassNames}>{list.map((item) => renderItem(item))}</div>
                {list.length ? <div className={s.listBottomBound} ref={onScrollToListEnd ? elemRef : null} /> : null}
            </div>
        </div>
    );
};

export default DataList;
