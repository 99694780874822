import { Button, ContextAlertMark, Accordion, AccordionItem } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import inputConstants from '@constants/input';
import notifications from '@constants/notifications';
import serverErrorsConstants from '@constants/serverErrors';
import snackbarsConstants from '@constants/snackbars';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import IconTrash from '@images/svg/icons/icon-trash.svg';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { INotification } from '@typings/notification';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import s from '../SettingsCounter.pcss';

interface IFormFields {
    blockAnalyticsAttribute: string;
    color: string;
    counterType: boolean;
    mergeSubdomains: boolean;
    mergeWww: boolean;
    title: string;
    url: string;
    useBlockAnalytics: boolean;
    useDomainBlacklist: boolean;
    useHashes: boolean;
    useUserId: boolean;
    useWhitelist: boolean;
    whitelistedUrls: string[];
    blacklistedUrls: string[];
}

const CounterForm: React.FC = () => {
    const currentProject = useAppSelector((state) => state.projectsSlice.current);
    const saveCounterRequest = useAppSelector((state) => state.projectsSlice.saveCounterRequest);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { setSnackbar } = useSnackbar();

    const [serverError, setServerError] = useState<string>(null);

    const formMethods = useForm<IFormFields>({
        defaultValues: {
            blockAnalyticsAttribute: '',
            color: '',
            counterType: false,
            mergeSubdomains: false,
            mergeWww: false,
            title: '',
            url: '',
            useBlockAnalytics: false,
            useDomainBlacklist: false,
            useHashes: false,
            useUserId: false,
            useWhitelist: false,
            whitelistedUrls: [],
            blacklistedUrls: [],
        },
        values: {
            blockAnalyticsAttribute: currentProject.counter_settings.block_analytics_attribute || 'your-attribute',
            color: currentProject.counter_settings.color,
            counterType: currentProject.counter_settings.counter_type === 1,
            mergeSubdomains: currentProject.counter_settings.merge_subdomains,
            mergeWww: !currentProject.counter_settings.merge_www,
            title: currentProject.title,
            url: currentProject.url,
            useBlockAnalytics: currentProject.counter_settings.use_block_analytics,
            useDomainBlacklist: !!currentProject.counter_settings.blacklisted_urls.length,
            useHashes: currentProject.counter_settings.track_hashes,
            useUserId: currentProject.counter_settings.track_user_id,
            useWhitelist: currentProject.counter_settings.use_whitelist,
            whitelistedUrls: currentProject.counter_settings.whitelisted_urls,
            blacklistedUrls: currentProject.counter_settings.blacklisted_urls,
        },
    });

    const { handleSubmit, watch } = formMethods;
    const formValues = watch();

    const setNotification = (notification: INotification) => dispatch(globalActions.setNotification(notification));
    const showPopupNotification = () => setNotification(notifications.settingsCounter);

    useEffect(() => {
        let timer;
        if (typeof setNotification === 'function') {
            timer = setTimeout(showPopupNotification, 4000);
        }
        return () => clearTimeout(timer);
    }, []);

    const openModalPopup = (modalPopupId, modalPopupParams = {}) => {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    };

    const removeCounter = (id: number) =>
        dispatch(projectsActions.remove(id)).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                const { DELETE_PROJECT_COUNTER } = snackbarsConstants.counterMessages;
                setSnackbar(DELETE_PROJECT_COUNTER);
            }
        });

    const handleApplyRemoveCounter = () => {
        removeCounter(currentProject.id).then(() => {
            navigate('/stat/projects');
        });
    };

    const handleOpenPopup = () => {
        openModalPopup('RemoveCounter', {
            okButtonHandler: handleApplyRemoveCounter,
        });
    };

    const saveSettingsCounter: SubmitHandler<IFormFields> = (values) => {
        const data = {
            title: currentProject.in_rating ? currentProject.title : values.title,
            counter_settings: {
                block_analytics_attribute: values.blockAnalyticsAttribute,
                merge_subdomains: values.mergeSubdomains,
                track_hashes: values.useHashes,
                track_user_id: values.useUserId,
                use_block_analytics: values.useBlockAnalytics,
                blacklisted_urls: values.blacklistedUrls || [],
                merge_www: !values.mergeWww,
                use_whitelist: values.useWhitelist,
                whitelisted_urls: values.whitelistedUrls,
                color: values.color,
                counter_type: Number(values.counterType),
            },
        };

        dispatch(projectsActions.save({ id: currentProject.id, data })).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                setSnackbar(snackbarsConstants.SAVE_CHANGES);
                window.scrollTo(0, 0);
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                }
            }
        });
    };

    const messageTitle = (
        <span className={s.alertMessage}>
            Отправить заявку на изменение названия сайта можно на вкладке&nbsp;
            <Link to={`/stat/projects/${currentProject.id}/settings/rating`}>«Рейтинг»</Link>.
        </span>
    );

    const messageUrl = (
        <span className={s.alertMessage}>
            Для изменения адреса сайта, участвующего в рейтинге, обратитесь в&nbsp;
            <a href="mailto:counter@ads.sber.ru" target="_blank" rel="noopener noreferrer">
                службу поддержки Статистики от SberAds
            </a>
            .
        </span>
    );

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(saveSettingsCounter)}>
                <div key="info_counter" className={s.block}>
                    <div className={s.blockTitle}>Вводные данные</div>
                    <CounterBlocks.InputData
                        disabledUrl
                        disabledTitle={currentProject.in_rating}
                        isShowMaxLength={false}
                        maxTitleLength={inputConstants.MAX_TITLE_LENGTH}
                        messageUrl={messageUrl}
                        messageTitle={currentProject.in_rating ? messageTitle : ''}
                    />
                </div>
                <Accordion defaultActiveKey={['settings_counter']} onChange={showPopupNotification}>
                    <AccordionItem key="settings_counter" header="Настройки счётчика">
                        <CounterBlocks.SettingsCounter counterType={formValues.counterType || false} />
                        <CounterBlocks.SettingsDomain useDomainBlacklist={formValues.useDomainBlacklist} />
                        <CounterBlocks.SettingsOther />
                    </AccordionItem>
                </Accordion>
                <div key="code_counter" className={s.block}>
                    <div className={s.blockTitle}>Код счётчика</div>
                    <CounterBlocks.CounterCode
                        id={String(currentProject.id)}
                        counterType={Number(formValues.counterType)}
                        blockAnalyticsAttribute={formValues.blockAnalyticsAttribute}
                        useBlockAnalytics={formValues.useBlockAnalytics}
                        useHashes={formValues.useHashes}
                        useUserId={formValues.useUserId}
                        setSnackbar={setSnackbar}
                    />
                </div>
                <div className={s.actions}>
                    <Button loading={saveCounterRequest} disabled={saveCounterRequest} htmlType="submit">
                        Сохранить изменения
                    </Button>
                    <div className={s.removeCounter} onClick={handleOpenPopup}>
                        <IconTrash className={s.removeCounterIcon} />
                        <span>Удалить счётчик</span>
                    </div>
                </div>
                {serverError && <ContextAlertMark message={serverError} status="error" />}
            </Form>
        </FormProvider>
    );
};

export default CounterForm;
