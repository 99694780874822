import { Button } from '@adtech/ui';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';
import { projectsStatus } from '@constants/projects';
import useProjectRequired from '@hooks/useProjectRequired';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';

import { IRootSlice } from '@typings/rootSlice';
import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useParams, useLocation } from 'react-router-dom';
import AccessForm from './AccessForm/index';
import CounterForm from './CounterForm/index';
import RatingForm from './RatingForm/index';

import s from './SettingsCounter.pcss';

const SettingsCounter: React.FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();

    const isProjectRequired = useProjectRequired();

    const permissions = useAppSelector((state: IRootSlice) => state.projectsSlice.current.permissions);
    const rolePermissions = useAppSelector((state: IRootSlice) => state.userSlice.rolePermissions);

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));

    useEffect(() => {
        dispatch(globalActions.getRegionsConfig());
        dispatch(globalActions.getTypesConfig());
        dispatch(globalActions.getThemesConfig());
    }, []);

    useEffect(() => {
        if (!isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        setGlobalLoading(false);

        // Определяем есть ли доступ к настройкам
        if (!permissions.can_edit_project && !rolePermissions.can_edit_project) {
            dispatch(globalActions.setError({ code: 403 }));
        }
    }, [isProjectRequired]);

    const pages = {
        counter: { title: 'Настройка счётчика', component: <CounterForm /> },
        rating: { title: 'Рейтинг Топ-100', component: <RatingForm /> },
        access: { title: 'Настройка доступа', component: <AccessForm /> },
    };

    const getPath = () => {
        const split = location.pathname.split('/');
        return split[split.length - 1];
    };

    const getPageTitle = () => {
        const { projectId } = params;
        const path = getPath();

        const type = pages[path] ? pages[path].title : 'Настройка';

        return `${type} — Статистика от SberAds / ${projectId}`;
    };

    const changeStatus = (status: string) => {
        dispatch(projectsActions.changeStatus(status));
    };

    const path = getPath();
    const Component = pages[path] ? pages[path].component : false;

    if (!Component) return null;

    return (
        <DocumentTitle title={getPageTitle()}>
            <div>
                <PageHead title="Настройка" />
                <ContentWithSidebar navigationType="settings">
                    {__DEV__ && (
                        <div className={s.changeStatusWrapper}>
                            {Object.keys(projectsStatus).map((item) => (
                                <Button
                                    key={item}
                                    className={s.changeStatusButton}
                                    type="primary"
                                    onClick={() => changeStatus(projectsStatus[item])}
                                >
                                    {item}
                                </Button>
                            ))}
                        </div>
                    )}
                    <div className={s.content}>{Component}</div>
                </ContentWithSidebar>
            </div>
        </DocumentTitle>
    );
};

export default SettingsCounter;
