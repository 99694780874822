import React from 'react';

interface IProps {
    children: React.ReactNode;
    onSubmit;
    className?: string;
}

const Form: React.FC<IProps> = ({ children, onSubmit, className = '' }) => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(e);
    };

    return (
        <form className={className} onSubmit={handleSubmit}>
            {children}
        </form>
    );
};

export default Form;
