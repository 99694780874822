import ContentWithSidebar from '@components/ContentWithSidebar';
import { IDataListItem } from '@components/DataList';
import Grid from '@components/Grid';
import PageHead from '@components/PageHead';
import ReportTools from '@components/ReportTools';
import EventsWidgetFilter from '@components/WidgetFilter/EventsWidgetFilter';
import MetricWidgetFilter from '@components/WidgetFilter/MetricWidgetFilter';
import TrafficSourcesWidgetFilter from '@components/WidgetFilter/TrafficSourcesWidgetFilter';
import { PageUpdater, Widget } from '@components/index';

import dashboardConfig from '@configs/dashboard/dashboard';
import getWidgetsLinks from '@configs/dashboard/widgets';
import { filtersListCnf } from '@configs/filters/filtersConfigEventBased';
import { ChartTypes } from '@configs/graph';
import { GroupBy } from '@configs/group';
import hintsDashboard from '@configs/hintsDashboard';
import { WidgetTypes } from '@configs/widget';
import dashboardConstants from '@constants/dashboard';
import { LatencyKeys } from '@constants/latency';

import { Error507 } from '@containers/Error';
import { useAvailableTools } from '@hooks/useAvailableTools';
import useCheckDates from '@hooks/useCheckDates';
import useCheckReportAccess from '@hooks/useCheckReportAccess';
import useDashboard from '@hooks/useDashboard';
import useFilters from '@hooks/useFilters';
import useGlobalLoading from '@hooks/useGlobalLoading';
import useGlobalParams from '@hooks/useGlobalParams';
import useLatency from '@hooks/useLatency';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';
import { globalActions } from '@redux/slices/global';

import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import s from './Dashboard.pcss';

const Dashboard: FC = () => {
    useGlobalLoading();

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const isProjectRequired = useProjectRequired();

    const { updateQuery } = useQuery();

    const { api, reportsUtils, reportName } = useDashboard();

    const { clearFilters } = useFilters();

    const { resetDatesToToday, updateGlobalParams } = useGlobalParams();

    const isReportAccess = useCheckReportAccess({ isProjectRequired, customReport: reportName });

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const isAutoUpdate = useSelector((state: IRootSlice) => state.dashboardSlice.isAutoUpdate);
    const errorCode = useSelector((state: IRootSlice) => state.dashboardSlice.errorCode);
    const activePages = useSelector((state: IRootSlice) => state.dashboardSlice.activePages);
    const trafficSources = useSelector((state: IRootSlice) => state.dashboardSlice.trafficSources);
    const usersCount = useSelector((state: IRootSlice) => state.dashboardSlice.userCount);
    const usersOnline = usersCount.count;
    const pageViews = useSelector((state: IRootSlice) => state.dashboardSlice.pageViews);
    const audience = useSelector((state: IRootSlice) => state.dashboardSlice.audience);
    const platforms = useSelector((state: IRootSlice) => state.dashboardSlice.platforms);
    const events = useSelector((state: IRootSlice) => state.dashboardSlice.events);
    const popularUtm = useSelector((state: IRootSlice) => state.dashboardSlice.popularUtm);
    const eventsList = useSelector((state: IRootSlice) => state.dashboardSlice.eventsList);
    const eventsListRequest = useSelector((state: IRootSlice) => state.dashboardSlice.eventsListRequest);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const periodDetail = useSelector((state: IRootSlice) => state.globalSlice.periodDetail);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);

    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);

    const { getParamFromConfig } = reportsUtils;
    const titleAliases = getParamFromConfig(reportName, 'titleAliases');
    const baseAvailableTools = getParamFromConfig(reportName, 'availableTools');
    const availableTools = useAvailableTools(reportName, baseAvailableTools);

    const dashboardRequest =
        activePages.loading ||
        trafficSources.loading ||
        usersCount.loading ||
        pageViews.loading ||
        platforms.loading ||
        events.loading ||
        audience.loading ||
        popularUtm.loading;

    // При первом запросе в виджетах должен отображатся лоадер
    const [isToday, setIsToday] = useState(DateUtils.isPeriodToday(dateStart, dateEnd));
    const [isPageActive, setPageActive] = useState(true);
    const [isResetTimer, setResetTimerValue] = useState(false);
    const isReportInited = useRef(false);

    // Если нужно использовать дату "только сегодня", то берем соответствующий латенси
    const latencyKey = LatencyKeys.base;
    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[latencyKey]);
    const isExcludedLatency = latencyConfig?.isExcludedLatency;
    const minCalendarDate = latencyConfig?.start;
    const maxCalendarDate = latencyConfig?.end;

    const widgetsLinks = getWidgetsLinks(projectId);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getLatency = useLatency(latencyKey);
    const checkedDates = useCheckDates(latencyKey);
    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));
    const clearError = () => dispatch(globalActions.clearError());

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Дашборд ${url} – Статистика от SberAds / ${projectId}`;
    };

    const handleChangeDate507 = () => clearError();

    const renderError = () => {
        const dates = DateUtils.convertDates(dateStart, dateEnd);

        return (
            <Error507
                dateStart={dates[0]}
                dateEnd={dates[1]}
                handleChangeDate={handleChangeDate507}
                minCalendarDate={minCalendarDate}
                maxCalendarDate={maxCalendarDate}
                serverTime={serverTime}
                getLatency={getLatency}
                isExcludedLatency={isExcludedLatency}
            />
        );
    };

    const renderReportTools = (): JSX.Element => (
        <ReportTools
            reportName={reportName}
            reportsUtils={reportsUtils}
            projectId={projectId}
            updateReport={api.updateReport}
            getLatency={getLatency}
            serverTime={serverTime}
            minDate={minCalendarDate}
            maxDate={maxCalendarDate}
            dateStart={dateStart}
            dateEnd={dateEnd}
            handleChangeDate={api.handleChangeDate}
            isExcludedLatency={isExcludedLatency}
            sample={isToday ? dashboardConfig.sampleDefault : sample}
            isSampleDisabled={isToday}
            isPeriodDetailDisabled={!isToday}
            groupBy={groupBy}
            periodDetail={periodDetail}
            changePeriodDetailHandler={api.handleChangePeriodDetail}
            getTypeRequestReport={() => 'all'}
            availableTools={availableTools}
            filtersConfig={filtersListCnf}
        />
    );

    const renderPageHeadExtraRightNode: JSX.Element = (
        <PageUpdater
            run={isAutoUpdate && isPageActive && !dashboardRequest && isToday}
            seconds={30}
            onUpdate={() => api.updateDashboard(null, true)}
            disabled={!isToday}
            useSwitch
            isResetTimer={isResetTimer}
            onSwitchChange={api.setIsAutoUpdate}
        />
    );

    // Следим за видимостью страницы
    useEffect(() => {
        window.addEventListener('visibilitychange', () => setPageActive(!document.hidden));
        return () => {
            window.removeEventListener('visibilitychange', () => setPageActive(!document.hidden));
        };
    }, []);

    useEffect(() => {
        resetDatesToToday(GroupBy.minute);

        return () => {
            clearFilters();
            setGlobalLoading(false);
            updateGlobalParams({ groupBy: GroupBy.fiveMinute });
            api.resetDashboard();
        };
    }, []);

    // При переходе между проектами сплиты и фильтры сбрасываем
    useEffect(() => {
        // Чтобы updateQuery не отрабатывал в момент перехода между проектами на старом компоненте
        isReportInited.current = false;
        clearFilters();
    }, [params.projectId]);

    useEffect(() => {
        if (!checkedDates || !isProjectRequired || !isReportAccess) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.init().then(() => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired, isReportAccess, reportName]);

    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            groupBy,
            sample,
            globalFilters,
        });
    }, [isReportInited.current, dateStart, dateEnd, groupBy, sample, globalFilters]);

    useEffect(() => {
        // Сбрасываем таймер при выполнении нового запроса
        if (dashboardRequest) {
            setResetTimerValue(!isResetTimer);
        }
    }, [dashboardRequest]);

    useEffect(() => {
        setIsToday(DateUtils.isPeriodToday(dateStart, dateEnd));
    }, [dateStart, dateEnd]);

    const showErrorPage = errorCode === 507 && !globalLoading;

    const onApplyTrafficSourcesFilter = (value: string) => {
        const widgetParams = api.transformSourcesDataToWidgetParams(value);
        api.updateDashboard({ widgetParams });
    };

    const onApplyEventsFilter = (value: Record<string, string[]>) => {
        const widgetParams = api.transformEventsDataToWidgetParams(value);
        api.updateDashboard({ widgetParams });
    };

    const metricsList: IDataListItem[] = [
        { title: 'Просмотры', name: 'page_views' },
        { title: 'Визиты', name: 'visits' },
        { title: 'Посетители', name: 'visitors' },
    ];

    return (
        <DocumentTitle title={getPageTitle()}>
            <div className={s.root}>
                {showErrorPage && renderError()}
                {!showErrorPage && !globalLoading && (
                    <>
                        <PageHead
                            reportName={reportName}
                            projectId={projectId}
                            userId={userId}
                            title={dashboardConfig.reportTitle}
                            hintContent={dashboardConfig.reportHint}
                            extraRightNode={renderPageHeadExtraRightNode}
                        />
                        <ContentWithSidebar>
                            {renderReportTools()}
                            <Grid>
                                <Widget
                                    loading={usersCount.loading}
                                    type={WidgetTypes.count}
                                    label="Уникальные посетители"
                                    className={s.usersOnline}
                                    hintTitle={hintsDashboard.userCount}
                                    count={usersOnline}
                                    attrCnfName="dashboard"
                                />
                                <Widget
                                    type={WidgetTypes.graph}
                                    className={s.pageViews}
                                    title="Просмотры страниц"
                                    hideLegend
                                    heightChart={180}
                                    name="pageViews"
                                    groupBy={groupBy}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    {...pageViews}
                                    graphType={
                                        pageViews.graphType === ChartTypes.bar
                                            ? ChartTypes.barSpecial
                                            : pageViews.graphType
                                    }
                                    disabledGraphs={{ pie: true, area: true }}
                                    isDisabledTabs
                                    size={3}
                                    attrCnfName="dashboard"
                                    titleClickHandler={() => navigate(widgetsLinks.pageViews)}
                                />
                                <Widget
                                    reportName={reportName}
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.bar}
                                    title="Источники переходов"
                                    name="trafficSources"
                                    heightChart={200}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    enableAllGraphTypes
                                    {...trafficSources}
                                    attrCnfName="dashboard"
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.trafficSources)}
                                    widgetCustomSettingsElement={() => (
                                        <TrafficSourcesWidgetFilter onApply={onApplyTrafficSourcesFilter} />
                                    )}
                                    size={2}
                                    isDisabledTabs
                                />
                                <Widget
                                    type={WidgetTypes.table}
                                    title="Популярные страницы"
                                    tableTitle="URL страницы"
                                    name="activePages"
                                    {...activePages}
                                    data={activePages.data?.slice(0, dashboardConstants.WIDGET_DEFAULT_LIMIT)}
                                    isDisabledSettings
                                    isDisabledTabs
                                    isShowOneMetric
                                    isDisabledSort
                                    attrCnfName="dashboard"
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.activePages)}
                                    size={2}
                                />
                                <Widget
                                    reportName={reportName}
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.line}
                                    title="Аудитория"
                                    name="audience"
                                    heightChart={145}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    onUpdate={api.updateAudienceWidget}
                                    tabsNum={3}
                                    dimensions={['gender', 'age_tns', 'geo_country']}
                                    selected="gender"
                                    {...audience}
                                    metrics={null}
                                    allDimensions={['gender', 'age_tns', 'geo_country']}
                                    attrCnfName="dashboard"
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.audience)}
                                    size={2}
                                    widgetCustomSettingsElement={() => (
                                        <MetricWidgetFilter
                                            metricsList={metricsList}
                                            widgetName="audience"
                                            onChange={api.updateWidgetMetric}
                                        />
                                    )}
                                />
                                <Widget
                                    reportName={reportName}
                                    period={[dateStart, dateEnd]}
                                    groupBy={groupBy}
                                    type={WidgetTypes.graph}
                                    graphType={ChartTypes.pie}
                                    title="Формат страниц"
                                    name="platforms"
                                    heightChart={200}
                                    isShowChartSwitcher
                                    handleGraphSelect={api.updateWidgetParams}
                                    titleAliases={titleAliases}
                                    {...platforms}
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.platforms)}
                                    size={2}
                                    isDisabledTabs
                                    widgetCustomSettingsElement={() => (
                                        <MetricWidgetFilter
                                            metricsList={metricsList}
                                            widgetName="platforms"
                                            onChange={api.updateWidgetMetric}
                                        />
                                    )}
                                />
                                <Widget
                                    type={WidgetTypes.table}
                                    title="События"
                                    tableTitle="Тип > Название события"
                                    notHiddenHeads
                                    name="events"
                                    size={2}
                                    isDisabledTabs
                                    isDisabledSort
                                    // FIXME: спилить когда будет таблица для событий
                                    isShowAllMetrics
                                    {...events}
                                    data={api
                                        .filterEventsWidgetData(events?.data)
                                        .slice(0, dashboardConstants.WIDGET_DEFAULT_LIMIT)}
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.events)}
                                    widgetCustomSettingsElement={() => (
                                        <EventsWidgetFilter
                                            eventsList={eventsList}
                                            eventsListRequest={eventsListRequest}
                                            onClick={api.getEventsList}
                                            onApply={onApplyEventsFilter}
                                        />
                                    )}
                                />
                                <Widget
                                    type={WidgetTypes.table}
                                    title="Популярные UTM"
                                    tableTitle="UTM Source > UTM Medium > UTM Campaign > UTM Content > UTM Term"
                                    notHiddenHeads
                                    name="popularUTM"
                                    size={2}
                                    isDisabledSort
                                    isDisabledTabs
                                    {...popularUtm}
                                    heightType="small"
                                    titleClickHandler={() => navigate(widgetsLinks.popularUtm)}
                                />
                            </Grid>
                        </ContentWithSidebar>
                    </>
                )}
            </div>
        </DocumentTitle>
    );
};

export default Dashboard;
