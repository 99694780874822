import AuthorsList from '@components/AuthorsList';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';
import ReportTools from '@components/ReportTools';
import { PageUpdater } from '@components/index';

import { filtersListCnf } from '@configs/filters/filtersConfigMedia';
import { periodDetailConfig } from '@configs/media/top';
import dateConstants from '@constants/date';
import { LatencyKeys } from '@constants/latency';
import { EPeriodDetail } from '@constants/periodDetail';
import reportsConstants from '@constants/reports';
import { Error507 } from '@containers/Error';
import { useAvailableToolsMedia } from '@hooks/useAvailableTools';
import useCheckDates from '@hooks/useCheckDates';
import useCheckReportAccess from '@hooks/useCheckReportAccess';
import useFilters from '@hooks/useFilters';
import useGlobalLoading from '@hooks/useGlobalLoading';
import { useGraph } from '@hooks/useGraph';
import useLatency from '@hooks/useLatency';
import useMedia from '@hooks/useMedia';
import useModalPopup from '@hooks/useModalPopup';
import useProjectRequired from '@hooks/useProjectRequired';
import useQuery from '@hooks/useQuery';
import useReport from '@hooks/useReport';
import { useTable } from '@hooks/useTable';
import { DateLiteral } from '@typings/date';
import { ReportNameMedia } from '@typings/media';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import React, { useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

const Media: React.FC = () => {
    useGlobalLoading();

    const isProjectRequired = useProjectRequired();
    const isReportAccess = useCheckReportAccess({ isProjectRequired });
    const params: IUrlParams<ReportNameMedia> = useParams();

    const { reportName, mediaId, projectId: projectIdQuery } = params;

    const {
        reportsUtils,
        api: {
            getContainer,
            initReport,
            updateReport,
            handleChangeDate,
            getTypeRequestReport,
            setIsAutoUpdate,
            updateSearchAuthors,
            updateReportWithoutRequest,
        },
    } = useMedia();
    const { getParamFromConfig } = reportsUtils;

    const {
        api: { setError, clearError, setGlobalLoading },
    } = useReport();

    const { clearFilters } = useFilters();

    const {
        api: { resetTable },
    } = useTable();
    const {
        api: { resetGraph },
    } = useGraph();

    const reportTitle = getParamFromConfig(reportName, 'reportTitle');
    const hintContent = getParamFromConfig(reportName, 'reportHint');
    const isOnlyToday = getParamFromConfig(reportName, 'isOnlyToday');
    const useAutoUpdate = getParamFromConfig(reportName, 'useAutoUpdate');
    const useDropdownAuthors = getParamFromConfig(reportName, 'useDropdownAuthors');
    const tableUpdateDelay = getParamFromConfig(reportName, 'tableUpdateDelay');

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const serverTime = useSelector((state: IRootSlice) => state.globalSlice.serverTime);

    const [paramsUpdater, setParamsUpdater] = useState({
        run: false,
        disabled: true,
        isResetTimer: true,
        onUpdate: () => {},
    });

    let latencyKey = LatencyKeys.base;

    // Если нужно использовать дату "только сегодня", то берем соответствующий латенси
    latencyKey = isOnlyToday ? LatencyKeys.today : latencyKey;

    const latencyConfig = useSelector((state: IRootSlice) => state.latencySlice[latencyKey]);

    const isExcludedLatency = latencyConfig?.isExcludedLatency;

    const publishedDateTime = useSelector(
        (state: IRootSlice) => state.mediaSlice?.materialInfo?.publishedDateTime || null,
    );

    let minCalendarDate = latencyConfig?.start;
    const maxCalendarDate = latencyConfig?.end;

    // Для отчёта по одному материалу переопределяем minCalendarDate
    // на дату публикации материала
    if (mediaId && publishedDateTime) {
        minCalendarDate = DateUtils.getDate(publishedDateTime).format(dateConstants.DATE_FORMAT);
    }

    const userId = useSelector((state: IRootSlice) => state.userSlice.id);

    const projectId = useSelector((state: IRootSlice) => state.projectsSlice.current.id);
    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const metrics = useSelector((state: IRootSlice) => state.tableSlice.metrics);
    const orderBy = useSelector((state: IRootSlice) => state.tableSlice.orderBy);
    const graphs = useSelector((state: IRootSlice) => state.graphSlice.graphs);

    const authorsRequest = useSelector((state: IRootSlice) => state.mediaSlice.authorsRequest);
    const authorInfo = useSelector((state: IRootSlice) => state.mediaSlice.authorInfo);
    const authors = useSelector((state: IRootSlice) => state.mediaSlice.authors);

    const errorCode = useSelector((state: IRootSlice) => state.mediaSlice.errorCode);
    const showError507Page = errorCode === 507;

    const { openModalPopup } = useModalPopup();
    const availableTools = useAvailableToolsMedia(reportName);

    const navigate = useNavigate();

    const getLatency = useLatency(latencyKey);
    const checkedDates = useCheckDates(latencyKey);
    const { updateQuery } = useQuery();

    const isReportInited = useRef(false);

    const Container = getContainer(reportName);

    // useEffect #1, добавлено для написания тестов
    useEffect(() => {
        // Отчёт по материалам с id материала в адресе
        const isWrongLocation = reportName === 'materials_summary' && !!mediaId;

        if (isWrongLocation) {
            setError(404);
            setGlobalLoading(false);
        } else if (!userId && Number(projectIdQuery) !== reportsConstants.DEMO_PROJECT_ID) {
            navigate('/');
        }

        return () => {
            resetTable();
            clearFilters();
            setGlobalLoading(false);
        };
    }, []);

    // При переходе между проектами сплиты и фильтры сбрасываем
    useEffect(() => {
        // Чтобы updateQuery не отрабатывал в момент перехода между проектами на старом компоненте
        isReportInited.current = false;
        clearFilters();
    }, [params.projectId]);

    // useEffect #2
    useEffect(() => {
        if (!checkedDates || !isProjectRequired || !isReportAccess) return;

        isReportInited.current = false;

        // При переходе между отчётами сбрасываем состояние графиков
        resetGraph(true);

        initReport(latencyKey).then(async () => {
            isReportInited.current = true;
            setGlobalLoading(false);
        });
    }, [checkedDates, isProjectRequired, isReportAccess, reportName, mediaId]);

    // useEffect #3
    useEffect(() => {
        if (!isReportInited.current) return;

        updateQuery({
            dateEnd,
            dateStart,
            groupBy,
            metrics,
            graphs,
            orderBy,
            globalFilters,
        });
    }, [
        isReportInited.current,
        reportName,
        mediaId,
        dateEnd,
        dateStart,
        groupBy,
        metrics,
        graphs,
        orderBy,
        globalFilters,
    ]);

    // useEffect #4
    useEffect(() => {
        if (errorCode === 404) {
            setError(404);
            setGlobalLoading(false);
        }
    }, [errorCode]);

    // Формируем Title для страниц медиа отчётов
    const getPageTitle = () => {
        const title = reportTitle ? `${reportTitle} ` : '';
        const url = projectURL ? `(${projectURL}) ` : '';

        return `${title}${url} – Статистика от SberAds / ${projectId}`;
    };

    const dates = DateUtils.convertDates(dateStart, dateEnd);

    const handleChangeDate507 = (date: string[], relativeDate: DateLiteral) => {
        clearError();
        handleChangeDate(date, relativeDate);
    };

    // Обновление параметров для PageUpdater, если в отчете используется автообновление
    const handleChangeUpdater = (newParamsUpdater) => {
        if (!useAutoUpdate) return;
        setParamsUpdater(newParamsUpdater);
    };

    const renderPageHeadExtraRightNode = useAutoUpdate && (
        <PageUpdater
            run={paramsUpdater.run}
            seconds={tableUpdateDelay}
            onUpdate={paramsUpdater.onUpdate}
            useSwitch
            onSwitchChange={setIsAutoUpdate}
            isResetTimer={paramsUpdater.isResetTimer}
            disabled={paramsUpdater.disabled}
        />
    );

    const renderPageHeadExtraHeaderNode = useDropdownAuthors && (
        <AuthorsList
            projectId={projectId}
            authorsRequest={authorsRequest}
            authorInfo={authorInfo}
            authors={authors}
            updateSearchAuthors={updateSearchAuthors}
        />
    );

    const renderReportTools = (param?: Record<string, EPeriodDetail>): JSX.Element => (
        <ReportTools
            reportName={reportName}
            reportsUtils={reportsUtils}
            projectId={projectId}
            updateReport={updateReport}
            updateReportWithoutRequest={updateReportWithoutRequest}
            getLatency={getLatency}
            serverTime={serverTime}
            isOnlyToday={isOnlyToday}
            minDate={minCalendarDate}
            maxDate={maxCalendarDate}
            dateStart={dateStart}
            dateEnd={dateEnd}
            isExcludedLatency={isExcludedLatency}
            handleChangeDate={handleChangeDate}
            sample={sample}
            groupBy={groupBy}
            getTypeRequestReport={getTypeRequestReport}
            availableTools={availableTools}
            filtersConfig={filtersListCnf}
            periodDetail={param?.periodDetail}
            periodDetailData={periodDetailConfig}
            graphs={graphs}
            metrics={metrics}
        />
    );

    return (
        <DocumentTitle title={getPageTitle()}>
            <>
                {!showError507Page && !globalLoading && (
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title={reportTitle}
                        hintContent={hintContent}
                        extraRightNode={renderPageHeadExtraRightNode}
                        extraHeaderNode={renderPageHeadExtraHeaderNode}
                        openModalPopup={openModalPopup}
                    />
                )}
                <ContentWithSidebar>
                    {showError507Page && (
                        <Error507
                            dateStart={dates[0]}
                            dateEnd={dates[1]}
                            handleChangeDate={handleChangeDate507}
                            minCalendarDate={minCalendarDate}
                            maxCalendarDate={maxCalendarDate}
                            serverTime={serverTime}
                            getLatency={getLatency}
                            isExcludedLatency={isExcludedLatency}
                        />
                    )}
                    {!showError507Page && !globalLoading && (
                        // Отдельные контейнеры отчётов
                        <Container
                            reportName={reportName}
                            projectId={projectId}
                            userId={userId}
                            renderReportTools={renderReportTools}
                            onChangeUpdater={handleChangeUpdater}
                        />
                    )}
                </ContentWithSidebar>
            </>
        </DocumentTitle>
    );
};

export default Media;
