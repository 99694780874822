import { ChipButton, IconCross } from '@adtech/ui';
import React from 'react';

import s from '../ListData.pcss';

interface IProps {
    map: Record<string, any>;
    removeSelectedItem: (values?: number[]) => void;
    selectedValues: number[];
}

const Selected: React.FC<IProps> = ({ map, removeSelectedItem, selectedValues }) => {
    const renderItem = (id: number, key: number) => {
        const currentItem = map[id];
        const title = [];

        const pushParentTitles = (item: Record<string, any>) => {
            if (item.parent_id) {
                const parentItem = map[item.parent_id];
                if (parentItem) title.push(parentItem.title);

                pushParentTitles(parentItem);
            }
        };

        pushParentTitles(currentItem);

        title.reverse();
        title.push(currentItem.title);

        return (
            <ChipButton key={key} fixedWidth icon={<IconCross className={s.removeIcon} />} data-id={id} checked={false}>
                {`${title.join(' / ')}`}
            </ChipButton>
        );
    };

    const removeRemainingElements = (id: string) => {
        removeSelectedItem(selectedValues.filter((elem) => String(elem) !== id));
    };

    const deleteElement = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;

            if (id) removeRemainingElements(id);
        }
    };

    if (!selectedValues.length) return null;

    return (
        <div className={s.tagsList} onClick={deleteElement}>
            {selectedValues.map((id, key) => renderItem(id, key))}
        </div>
    );
};

export default Selected;
