import { Button, IconCirclePlus, IconTrash, IllustrationTable, Tabs, ZeroState } from '@adtech/ui';
import cn from 'classnames';
import React, { FC } from 'react';

import FilterFormHeader from '../components/FilterFormHeader';
import { rulesLength } from '../configs/rulesLength';
import {
    CheckboxFilter,
    DatePeriodFilter,
    EventFilter,
    PeriodFilter,
    RadioFilter,
    TextareaFilter,
} from '../filterTypes/index';
import {
    EFilterRuleOperation,
    EFilterType,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TDimension,
    TDynamicFilterData,
} from '../types/filters';
import s from './FilterRules.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    dynamicFilterData?: TDynamicFilterData;
    getDynamicFilterData?: (dimension: TDimension) => Promise<unknown>;
    size?: 'default' | 'small';
}

const FiltersRules: FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    dynamicFilterData,
    getDynamicFilterData,
    size,
}) => {
    const MAX_RULES_LENGTH = rulesLength[filterCnf?.type];
    const curFilterFormsValues =
        allFormsValues?.[currentKey] ?? (filterCnf ? [{ op: EFilterRuleOperation.AND, value: null }] : []);
    const isRulesTitlesOff = [EFilterType.radio, EFilterType.period, EFilterType.datePeriod].includes(filterCnf?.type);
    const isShowFilterTitle = [EFilterType.period, EFilterType.datePeriod].includes(filterCnf?.type);

    const addRule = () => {
        setAllFormsValues({
            ...allFormsValues,
            [currentKey]: [...curFilterFormsValues, { op: EFilterRuleOperation.AND, value: null }],
        });
    };

    const deleteRule = (index: number) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal.splice(index, 1);
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const changeRuleOperation = (value, index: number) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[index] = { ...newFormVal[index], op: value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const renderForm = (type: string, ruleFormValue: IFilterRuleFormValue, ruleIndex: number) => {
        const filterFormProps = {
            filterCnf,
            currentKey,
            allFormsValues,
            setAllFormsValues,
            curFilterFormsValues,
            ruleFormValue,
            ruleIndex,
        };

        switch (type) {
            case EFilterType.checkbox:
                return (
                    <CheckboxFilter
                        {...filterFormProps}
                        dynamicFilterData={dynamicFilterData[filterCnf.dynamicFilterAlias || currentKey]}
                        getDynamicFilterData={getDynamicFilterData}
                    />
                );
            case EFilterType.textarea:
                return <TextareaFilter {...filterFormProps} />;
            case EFilterType.radio:
                return <RadioFilter {...filterFormProps} />;
            case EFilterType.period:
                return <PeriodFilter {...filterFormProps} deleteRuleHandler={deleteRule} />;
            case EFilterType.datePeriod:
                return <DatePeriodFilter {...filterFormProps} deleteRuleHandler={deleteRule} />;
            case EFilterType.event:
                return (
                    <EventFilter
                        {...filterFormProps}
                        dynamicFilterData={dynamicFilterData[filterCnf.dynamicFilterAlias || currentKey]}
                        getDynamicFilterData={getDynamicFilterData}
                    />
                );
            default:
                return null;
        }
    };

    const renderRule = (ruleFormValue: IFilterRuleFormValue, index: number) => {
        const ruleOperation = ruleFormValue?.op;
        const tabsOptions = [
            {
                label: 'И',
                value: EFilterRuleOperation.AND,
            },
            {
                label: 'ИЛИ',
                value: EFilterRuleOperation.OR,
            },
            {
                label: 'Исключить',
                value: EFilterRuleOperation.NOT,
            },
        ];

        const ruleTitle = filterCnf?.type === EFilterType.event ? 'Правило' : `Правило ${index + 1}`;

        const filterRuleClassName = cn(s.filterRulesAnyRule, { [s.filterRulesAnyRuleSmall]: size === 'small' });

        return (
            <div key={index}>
                {index > 0 ? (
                    <div className={s.filterRulesDivider}>
                        <div className={s.filterRulesTitle}>И</div>
                        <div className={s.filterRulesDividerLine} />
                    </div>
                ) : null}
                {!isRulesTitlesOff ? (
                    <div className={filterRuleClassName}>
                        <div className={s.filterRulesTitle}>{ruleTitle}</div>
                        <div className={s.filterRulesBtnsWrapper}>
                            <Tabs
                                type="labeled"
                                value={ruleOperation}
                                onChange={(val) => changeRuleOperation(val, index)}
                                options={tabsOptions}
                            />
                            {curFilterFormsValues?.length > 1 ? (
                                <Button icon={<IconTrash />} type="dashed" onClick={() => deleteRule(index)} />
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {renderForm(filterCnf.type, ruleFormValue, index)}
            </div>
        );
    };

    return (
        <div className={s.filterRules}>
            {isShowFilterTitle ? (
                <FilterFormHeader label="Настройте условия" hint={filterCnf?.hint} isLabelBold />
            ) : null}
            {curFilterFormsValues?.length ? (
                curFilterFormsValues?.map((value, i) => renderRule(value, i))
            ) : (
                <div className={s.filterRulesZero}>
                    <ZeroState
                        title="Параметры не выбраны"
                        description={
                            <span className={s.filterRulesZeroDesc}>Пока не выбрана ни одна группа параметров</span>
                        }
                        icon={<IllustrationTable />}
                    />
                </div>
            )}
            {curFilterFormsValues?.length < MAX_RULES_LENGTH ? (
                <Button
                    className={s.filterRulesAddRuleBtn}
                    prefixIcon={<IconCirclePlus color="currentColor" />}
                    type="link"
                    onClick={() => addRule()}
                >
                    Добавить правило
                </Button>
            ) : null}
        </div>
    );
};

export default FiltersRules;
