import { Button, IconCirclePlus, IconEraser, IconInformationSign, Modal, Tooltip } from '@adtech/ui';
import * as React from 'react';
import { useState, useImperativeHandle, useMemo, useEffect } from 'react';

import s from './CommonFilters.pcss';
import FilterChips from './components/FilterChips';
import FiltersContent from './components/FiltersContent';
import SavedFilters from './components/SavedFilters';
import {
    TFilters,
    TFiltersListCnf,
    TFiltersFormCnf,
    TAllFormValues,
    TDimension,
    TDynamicFilterData,
} from './types/filters';
import { ESavedFilterActions, ISavedFilterData } from './types/savedFilters';
import { transformFiltersToFormsValues, transformFormsValuesToFilters } from './utils/filtersUtils';

interface IProps {
    filtersData: TFilters; // Данные фильтров
    filtersListCnf: TFiltersListCnf; // Конфиг списка, структуры фильтров
    filtersFormCnf: TFiltersFormCnf; // Конфиг фильтров, где хранятся параметры каждого фильтра
    onApply?: (filtersData: TFilters) => void;
    dynamicFilterData?: TDynamicFilterData;
    getDynamicFilterData?: (dimension: TDimension) => Promise<unknown>;
    dimensionsValuesDict?: Record<TDimension, Record<string, string>>; // словарь значений дименшинов
    mode?: 'modal' | 'simple'; // Режим с модалкой и без
    isSearchExists?: boolean;
    wrapperClassName?: string; // класс для основного контейнера фильтров
    size?: 'default' | 'small';
    // Сохраненные фильтры
    savedFiltersList?: ISavedFilterData[];
    savedFiltersLoading?: boolean;
    savedFilterCurrentId?: number;
    getSavedFiltersList?: () => void;
    savedFiltersAction?: (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => void;
}

const CommonFilters = React.forwardRef<{ getValues(): TFilters }, IProps>(
    (
        {
            filtersData,
            filtersListCnf,
            filtersFormCnf,
            mode = 'modal',
            dynamicFilterData,
            getDynamicFilterData,
            dimensionsValuesDict,
            isSearchExists,
            onApply,
            wrapperClassName,
            size,
            savedFiltersList,
            savedFiltersLoading,
            savedFilterCurrentId,
            getSavedFiltersList,
            savedFiltersAction,
        },
        ref,
    ) => {
        const formsValuesFromProps = useMemo(
            () => transformFiltersToFormsValues(filtersData, filtersFormCnf),
            [JSON.stringify(filtersData)],
        );
        const [allFormsValues, setAllFormsValues] = useState<TAllFormValues>(formsValuesFromProps);
        const [currentKey, setCurrentKey] = useState<string>(null);
        const [isModalOpen, setIsModalOpen] = useState(false);

        const hint =
            'По умолчанию фильтры объединяются по условию «И». Это означает, что поиск осуществляется по пересечению выбранных вами условий';

        useImperativeHandle(
            ref,
            () => ({
                getValues() {
                    return transformFormsValuesToFilters(allFormsValues, filtersFormCnf);
                },
            }),
            [allFormsValues],
        );

        useEffect(() => {
            setAllFormsValues(transformFiltersToFormsValues(filtersData, filtersFormCnf));
        }, [JSON.stringify(filtersData)]);

        const openModal = () => setIsModalOpen(true);

        const okModalHandler = () => {
            setIsModalOpen(false);
            setCurrentKey(null);
            onApply?.(transformFormsValuesToFilters(allFormsValues, filtersFormCnf));
        };

        const closeModalHandler = () => {
            setIsModalOpen(false);
            setCurrentKey(null);
            setAllFormsValues(formsValuesFromProps);
        };

        const modalFooterCustomBtn = () => (
            <Button onClick={() => setAllFormsValues({})} type="link" suffixIcon={<IconEraser />}>
                Сбросить настройки
            </Button>
        );

        const clearFiltersHandler = () => {
            setCurrentKey(null);
            setAllFormsValues({});
            onApply?.([]);
        };

        const clickFilterChipHandler = (dimension: TDimension) => {
            setCurrentKey(dimension);
            openModal();
        };

        const filterContent = () => (
            <FiltersContent
                filtersListCnf={filtersListCnf}
                filterCnf={filtersFormCnf[currentKey]}
                currentKey={currentKey}
                setActiveKey={setCurrentKey}
                allFormsValues={allFormsValues}
                setAllFormsValues={setAllFormsValues}
                dynamicFilterData={dynamicFilterData}
                getDynamicFilterData={getDynamicFilterData}
                isSearchExists={isSearchExists}
                wrapperClassName={wrapperClassName}
                size={size}
            />
        );

        return (
            <div className={s.commonFilters}>
                {mode === 'modal' ? (
                    <>
                        <div className={s.commonFiltersBtnsWrapper}>
                            <Button
                                onClick={openModal}
                                className={s.commonFiltersBtn}
                                type="link"
                                prefixIcon={<IconCirclePlus />}
                            >
                                Фильтры
                            </Button>
                            <Tooltip className={s.commonFiltersHint} title={hint}>
                                <IconInformationSign />
                            </Tooltip>
                            {Object.keys(formsValuesFromProps)?.length ? (
                                <Button
                                    onClick={clearFiltersHandler}
                                    className={s.commonFiltersClearBtn}
                                    type="link"
                                    prefixIcon={<IconEraser />}
                                >
                                    Сбросить
                                </Button>
                            ) : null}
                            {getSavedFiltersList ? (
                                <SavedFilters
                                    savedFiltersList={savedFiltersList}
                                    savedFilterCurrentId={savedFilterCurrentId}
                                    getSavedFiltersList={getSavedFiltersList}
                                    requestAction={savedFiltersAction}
                                    loading={savedFiltersLoading}
                                    filtersData={filtersData}
                                    allFormsValues={formsValuesFromProps}
                                    applyFiltersHandler={onApply}
                                />
                            ) : null}
                        </div>
                        <FilterChips
                            filtersFormCnf={filtersFormCnf}
                            allFormsValues={formsValuesFromProps}
                            clickChipHandler={clickFilterChipHandler}
                            applyFiltersHandler={onApply}
                            dimensionsValuesDict={dimensionsValuesDict}
                        />
                        <Modal
                            isOpened={isModalOpen}
                            title="Фильтры по условиям"
                            okText="Применить"
                            cancelText="Отменить"
                            onOk={okModalHandler}
                            onClose={closeModalHandler}
                            footerCustomElement={modalFooterCustomBtn()}
                            closeOnClickOutside={false}
                            centered
                            size="lightbox"
                            destroyOnClose
                        >
                            {filterContent()}
                        </Modal>
                    </>
                ) : (
                    filterContent()
                )}
            </div>
        );
    },
);

export default CommonFilters;
