export enum GoalFormType {
    add = 'add',
    edit = 'edit',
}

export enum GoalFormConditionTypes {
    event = 'event',
    parameter = 'parameter',
}

export interface ITypeConfig {
    title: string;
    buttonTitle: string;
}

export const goalFormTypesConfig: Record<GoalFormType, ITypeConfig> = {
    add: {
        title: 'Добавить целевое событие',
        buttonTitle: 'Добавить',
    },
    edit: {
        title: 'Редактировать целевое событие',
        buttonTitle: 'Сохранить',
    },
};
