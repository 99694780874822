import { useAppSelector } from '@hooks/useStore';
import IconTrash from '@images/svg/icons/icon-trash.svg';
import { IUsersValue } from '@typings/counter';
import cn from 'classnames';
import React, { FC } from 'react';
import s from './AccessUsers.pcss';
import RadioField, { EAccessValues } from './Fields/RadioField';

interface IProps {
    usersList: IUsersValue[];
    setUsersList: (value: IUsersValue[]) => void;
}

const UsersList: FC<IProps> = ({ usersList, setUsersList }) => {
    const currentEmail = useAppSelector((state) => state.userSlice.email);

    const removeUser = (index: number) => {
        const newUsers = [...usersList];
        newUsers.splice(index, 1);
        setUsersList(newUsers);
    };

    const changeUserAccessLevel = (email: string, accessLevel: EAccessValues) => {
        const newUsers = usersList.map((user) => {
            if (user.email === email) return { ...user, accessLevel };

            return user;
        });

        setUsersList(newUsers);
    };

    const renderItem = (item, index: number) => {
        const simpleKey = index + 1;
        const isCurrentUser = item.email === currentEmail;

        return (
            <div className={s.item} key={item.email}>
                <div className={s.itemNumber}>{simpleKey}.</div>
                <div className={s.itemMain}>
                    <div className={cn(s.user, { [s.userCurrent]: isCurrentUser })}>
                        {item.email} {isCurrentUser && '(это вы)'}
                        {!isCurrentUser && (
                            <IconTrash className={s.userRemoveButton} onClick={() => removeUser(index)} />
                        )}
                    </div>
                    <RadioField
                        disabled={isCurrentUser}
                        value={item.accessLevel}
                        setValue={(value) => changeUserAccessLevel(item.email, value)}
                    />
                </div>
            </div>
        );
    };

    return <div className={s.list}>{usersList.map(renderItem)}</div>;
};

export default UsersList;
