import { TFilters } from '../types/filters';

export enum ESavedFilterType {
    PRIVATE = 'private',
    SHARED = 'shared',
}

export enum ESavedFilterActions {
    delete = 'delete',
    save = 'save',
    create = 'create',
    edit = 'edit',
}

export interface ISavedFilterData {
    id: number;
    title: string;
    type: ESavedFilterType;
    filters: TFilters;
}

export interface ISavedFilterActionData {
    actionName: ESavedFilterActions;
    data: Partial<ISavedFilterData>;
}
