import { ContextAlertMark, Radio } from '@adtech/ui';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Wrapper from '../Wrapper';
import s from './DisplayStatistics.pcss';

const DisplayStatistics: React.FC = () => {
    const { control } = useFormContext();
    const radioItems = [
        {
            title: 'Доступно всем',
            value: 'opened',
        },
        {
            title: 'Закрытая',
            value: 'closed',
        },
    ];

    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'publicStats',
        control,
        rules: {
            required: {
                value: true,
                message: 'Необходимо выбрать один из пунктов',
            },
        },
    });

    const { name, onChange, value } = field;

    const renderRadio = (item) => (
        <Radio name={name} value={item.value} onChange={onChange} checked={value === item.value} key={item.value}>
            {item.title}
        </Radio>
    );

    return (
        <Wrapper
            withoutDelimiter
            subWrap
            title="Отображение статистики"
            // eslint-disable-next-line max-len
            description="При открытой статистике любой посетитель Статистики от SberAds сможет увидеть динамику посещаемости вашего сайта. При закрытой — только динамику популярности."
            smallTitle
            fixPadding
        >
            <div className={s.radioGroup}>{radioItems.map(renderRadio)}</div>
            {error && <ContextAlertMark status="error" message={error.message || ''} />}
        </Wrapper>
    );
};

export default DisplayStatistics;
