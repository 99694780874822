import { IDataListItem } from '@components/DataList';
import dimensionsValues from '@configs/dimensionsValues';
import {
    getTable,
    updateTable,
    getGoalsByIds,
    createGoal,
    updateGoal,
    deleteGoal,
    getEventsList,
} from '@redux/slices/goals/api';
import { createSlice } from '@reduxjs/toolkit';
import { IGoalsSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';

export const initialState: IGoalsSlice = {
    deleteRequest: false,
    tableRequest: false,
    tableData: [],
    totals: null,
    eventsList: null,
    eventsListRequest: false,
    showCommonGoals: null,
    showOnlyCommonGoals: null,
};

export const setRequestTable = (state: IGoalsSlice) => {
    state.tableRequest = true;
};

export const setDeleteRequest = (state: IGoalsSlice) => {
    state.deleteRequest = true;
};

export const updateTableFunc = (state: IGoalsSlice, action, isUpdate?: boolean) => {
    const { goals, totals } = action.payload?.data || {};

    const data = goals.map((item) => ({
        ...item,
        update_date: DateUtils.readableDay(DateUtils.getDate(item.update_date)),
    }));
    const tableData = isUpdate ? [...state.tableData, ...data] : data;

    state.tableRequest = false;
    state.tableData = tableData;
    state.totals = totals;
};

export const deleteGoalFromTable = (state: IGoalsSlice, action) => {
    const data = state.tableData.filter((item) => Number(item.id) !== action.payload?.metaInfo.id);

    state.deleteRequest = false;
    state.tableData = data;
    state.totals -= 1;
};

export const setEventsListRequest = (state: IGoalsSlice) => {
    state.eventsListRequest = true;
};

export const setEventsListFailure = (state: IGoalsSlice) => {
    state.eventsListRequest = false;
};

export const setEventsList = (state: IGoalsSlice, action) => {
    const { data } = action.payload?.result || {};

    const transformData = (item: string, isEventClass?: boolean, parent?: string) => {
        const title = isEventClass && dimensionsValues.event_class[item]?.title;

        return {
            title: title || item,
            name: item,
            main: isEventClass,
            ...(!isEventClass ? { parent } : {}),
        } as IDataListItem;
    };

    const eventClasses = [...new Set(data.map((item) => item.dimensions[0]))] as string[];
    const list = eventClasses.map((item) => transformData(item, true));

    data.forEach((item) => {
        const eventClass = item.dimensions[0];
        const eventName = item.dimensions[1];

        const index = list.findIndex((listItem) => listItem.name === eventClass);
        list[index].children = [...(list[index].children || []), transformData(eventName, false, eventClass)];
    });

    state.eventsList = list;
    state.eventsListRequest = false;
};

export const updateParamsFunc = (state: IGoalsSlice, action) => {
    Object.assign(state, action.payload);
};

const goalsSlice = createSlice({
    name: 'goalsSlice',
    initialState,
    reducers: {
        updateParams: (state, action) => updateParamsFunc(state, action),
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTable.pending, (state) => setRequestTable(state))
            .addCase(getTable.fulfilled, (state, action) => updateTableFunc(state, action))

            .addCase(updateTable.pending, (state) => setRequestTable(state))
            .addCase(updateTable.fulfilled, (state, action) => updateTableFunc(state, action, true))

            .addCase(deleteGoal.pending, (state) => setDeleteRequest(state))
            .addCase(deleteGoal.fulfilled, (state, action) => deleteGoalFromTable(state, action))

            .addCase(getEventsList.pending, (state) => setEventsListRequest(state))
            .addCase(getEventsList.fulfilled, (state, action) => setEventsList(state, action))
            .addCase(getEventsList.rejected, (state) => setEventsListFailure(state));
    },
});

export const { updateParams } = goalsSlice.actions;

export const goalsActions = {
    updateParams,
    getTable,
    updateTable,
    getGoalsByIds,
    createGoal,
    updateGoal,
    deleteGoal,
    getEventsList,
};

export default goalsSlice.reducer;
