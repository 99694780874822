import DateUtils from '@utils/date';

export class Validator {
    __private: any;

    constructor() {
        const self = Validator;

        return Object.freeze({
            test: this.test,
            hasRelativeDates: this.hasRelativeDates,
            hasDaysInString: this.hasDaysInString,
            __private: {
                validationSettings: self.validationSettings,
                validRelativeDates: self.validRelativeDates,
            },
        });
    }

    /**
     * Конфигурация для валидатора
     * @type {*}
     */
    private static validationSettings = {
        text: /^[\da-zа-я.\-\s]+?$/,
        number: /^[0-9]+$/,
        split: /^[a-zA-Z0-9_,]+?$/,
        string: /^[^\\&]*$/,
        stringArray: /^([\w]{1,},){1,}[\w]+$/,
        date: /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/,
        url: /^(https?:\/\/(www)?)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // eslint-disable-line
    };

    /**
     * Валидные алиасы для дат
     * @type {string[]}
     */
    private static validRelativeDates = ['today', 'yesterday', 'days'];

    /**
     * Валидация входных данных по типу
     * @param value
     * @param type
     * @returns {*}
     */
    test = (value: any, type: string): boolean => {
        const prepareValue = typeof value === 'string' ? value.toLowerCase() : value;
        const regExp = Validator.validationSettings[type];

        if (!regExp) {
            throw new Error('not type validator');
        }

        if (type === 'date') {
            const hasRelativeDate = value && this.hasRelativeDates(value);
            let isValidDate = Validator.validationSettings.date.test(value);

            if (!hasRelativeDate && isValidDate) {
                isValidDate = value && DateUtils.checkDateExists(value);
            }

            return isValidDate || hasRelativeDate;
        }

        if (type === 'stringArray' && !regExp.test(prepareValue)) {
            return Validator.validationSettings.string.test(prepareValue);
        }

        return regExp.test(prepareValue);
    };

    /**
     * Проверка допустимых алиасов
     * @param string
     * @returns {boolean}
     */
    hasRelativeDates = (string: string): boolean => {
        if (!string) return false;

        let query = string;
        let validNumber = true;

        if (this.hasDaysInString(string)) {
            query = 'days';
            const daysNumber = string.split(query).join('');

            validNumber = Validator.validationSettings.number.test(daysNumber);
        }

        const result = Validator.validRelativeDates.filter((item) => item === query);

        return !!result.length && validNumber;
    };

    /**
     * Ищем в алиасе дат слово days
     * @param string
     * @returns {boolean}
     */
    hasDaysInString = (string: string): boolean => string.indexOf('days') > -1;
}

export default new Validator();
