import { filtersCnf } from './filters';

const {
    gender,
    age_tns,
    category_1,
    device_type,
    browser_family,
    os_family,
    traffic_type,
    ad_system,
    ad_campaign,
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    openstat_service,
    openstat_campaign,
    openstat_ad,
    openstat_source,
    referer,
    search_phrase,
    search_system,
    url,
    url_no_args,
    url_args_key,
    external_url,
    recom_system,
    message_system,
    exchange_system,
    qr_system,
    aggrnews_system,
    social_network,
    label,
    counter_type_eventbase,
    event_name,
    session_number,
    split,
    auth_top100,
    auth_rambler,
    app_version,
    user_cv_key,
} = filtersCnf;

export const filtersListCnf = [
    {
        label: 'События',
        key: 'events',
        children: [
            event_name,
            {
                label: 'Параметры события',
                key: 'events_params',
                children: [user_cv_key],
            },
        ],
    },
    {
        label: 'Аудитория',
        key: 'audience',
        children: [gender, age_tns, category_1, session_number],
    },
    {
        label: 'Технологии',
        key: 'technology',
        children: [device_type, browser_family, os_family, counter_type_eventbase, app_version],
    },
    {
        label: 'Источники',
        key: 'sources',
        children: [
            traffic_type,
            ad_system,
            ad_campaign,
            {
                key: 'utm_eventbase',
                label: 'UTM Метки',
                children: [utm_source, utm_medium, utm_campaign, utm_content, utm_term],
            },
            {
                key: 'openstat_eventbase',
                label: 'Метки Openstat',
                children: [openstat_service, openstat_campaign, openstat_ad, openstat_source],
            },
            referer,
            search_system,
            search_phrase,
            aggrnews_system,
            exchange_system,
            recom_system,
            social_network,
            message_system,
            qr_system,
        ],
    },
    {
        label: 'Содержание',
        key: 'content',
        children: [url, url_no_args, url_args_key, external_url, label],
    },
    {
        label: 'Тип авторизации',
        key: 'auth',
        children: [auth_top100, auth_rambler],
    },
    {
        label: 'Эксперименты',
        key: 'experiments',
        children: [split],
    },
];
