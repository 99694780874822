import { ChipButton, IconCross } from '@adtech/ui';
import classNames from 'classnames';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import InputField from './InputField';

import s from './MultiInputData.pcss';

interface IProps {
    name: string;
    button: string;
    placeholder: string;
    className?: string;
}

const MultiInputData: React.FC<IProps> = ({ className = '', name, button, placeholder }) => {
    const { control } = useFormContext();
    const { field } = useController({ name, control });

    const handleClick = (value: string) => {
        const newValues = [...(field.value || []), value];

        field.onChange(newValues);
    };

    const changeValues = (values?: string[]) => {
        field.onChange(values);
    };

    const renderSelectedItem = (value: string, key: number) => (
        <ChipButton key={key} className={s.chipButton} fixedWidth icon={<IconCross />} data-id={value} checked={false}>
            {value}
        </ChipButton>
    );

    const removeRemainingElements = (id: string) => {
        if (Array.isArray(field.value)) {
            changeValues(field.value.filter((elem) => elem !== id));
        } else if (field.value === id) {
            changeValues();
        }
    };

    const deleteElement = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;
            if (id) {
                removeRemainingElements(id);
            }
        }
    };

    const renderSelected = () => {
        if (!Array.isArray(field.value)) return null;

        return (
            <div className={s.selected} onClick={deleteElement}>
                {field.value?.map((item, key) => renderSelectedItem(item, key))}
            </div>
        );
    };

    const renderInput = () => (
        <InputField name={`${name}_input`} button={button} placeholder={placeholder} onButtonClick={handleClick} />
    );

    return (
        <div className={classNames(s.inputWrapper, className)}>
            {renderInput()}
            {renderSelected()}
        </div>
    );
};

export default MultiInputData;
