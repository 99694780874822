import { IFilterItem, TDimension, TFilters, TFiltersListCnf } from '@components/CommonFilters/types/filters';
import { ESavedFilterActions, ESavedFilterType, ISavedFilterData } from '@components/CommonFilters/types/savedFilters';
import { TDimensionValuesCnf } from '@configs/dimensionsValues';
import { filtersCnf } from '@configs/filters/filters';
import { filtersListCnf } from '@configs/filters/filtersConfigEventBased';
import { filtersListCnf as filtersConfigMedia } from '@configs/filters/filtersConfigMedia';
import { ESpecialEventsClasses } from '@constants/events';
import snackbarsConstants from '@constants/snackbars';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { ISaveFilterRequestParams } from '@typings/filters';
import { ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { isString } from '@utils/typesChecks';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const useFilters = () => {
    const params: IUrlParams = useParams();
    const { projectId } = params;

    const dispatch = useAppDispatch();
    const { setSnackbar } = useSnackbar();

    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);
    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const groupBy = useSelector((state: IRootSlice) => state.globalSlice.groupBy);
    const stateDynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);
    const filtersDimensionsDict = useSelector((state: IRootSlice) => state.globalSlice.filtersDimensionsDict);
    const savedFiltersList = useSelector((state: IRootSlice) => state.globalSlice.savedFiltersList);

    const { UPDATE_FILTER, SAVE_FILTER, DELETE_FILTER } = snackbarsConstants.filtersMessages;

    const api = {
        clearFilters(): void {
            dispatch(globalActions.updateParams({ globalFilters: [] }));
        },

        getDynamicFilters(dimension: TDimension): Promise<unknown> {
            const limit = 50;
            let reqParams: { limit: number; dimension_filters?: IFilterItem[] } = {
                limit,
            };
            const dimensionData = stateDynamicFiltersData?.[dimension];
            const dataLength = dimensionData?.data?.length || 0;
            // Округляем офсет до значений делимых нацело на лимит
            // так как из-за добавления динамических данных на фронте (setDynamicFilters) офсет сдвигается
            const offset = dataLength - (dataLength % limit);

            if (dimensionData?.data?.length >= dimensionData?.totals) return Promise.resolve();

            if (dimension === 'events') {
                reqParams = {
                    limit: 200,
                    dimension_filters: [
                        {
                            key: 'event_class',
                            op: 'exclude',
                            value: [ESpecialEventsClasses.tech, ESpecialEventsClasses.rec],
                        },
                    ],
                };
            }

            return dispatch(
                globalActions.getDynamicFilters({
                    projectId,
                    filterName: dimension,
                    params: {
                        date_start: dateStart || 'today',
                        date_end: dateEnd || 'today',
                        group: groupBy || 'five_minute',
                        offset,
                        parents: [],
                        ...reqParams,
                    },
                }),
            );
        },

        getGeoIdsDictionary(ids: number[]): void {
            dispatch(globalActions.getGeoIdsDict(ids));
        },

        getCategoryIdsDictionary(ids: number[]): void {
            dispatch(globalActions.getCategoryIdsDict(ids));
        },

        // Получаем словари для гео и интересов
        getFiltersDimensionsDict(): void {
            let geoIds = [];
            let categoryIds = [];

            globalFilters.forEach((filter) => {
                if (['geo_country', 'geo_area', 'geo_city'].includes(filter.key)) {
                    geoIds = [...geoIds, ...(isString(filter.value) ? [filter.value] : filter.value)];
                } else if (['category_1'].includes(filter.key)) {
                    categoryIds = [...categoryIds, ...(isString(filter.value) ? [filter.value] : filter.value)];
                }
            });

            geoIds = geoIds.filter((id) => !filtersDimensionsDict.geo_city?.[id]);
            categoryIds = categoryIds.filter((id) => !filtersDimensionsDict.category_1?.[id]);

            if (geoIds?.length) api.getGeoIdsDictionary(geoIds);
            if (categoryIds?.length) api.getCategoryIdsDictionary(categoryIds);
        },

        // Преобразовываем конфиг значений дименшинов в подходящий формат плоского словаря
        transformDimensionsValuesCnfToDict(
            cnf: Record<string, TDimensionValuesCnf>,
        ): Record<string, Record<string, string>> {
            const dict = {};

            Object.keys(cnf).forEach((dimension) => {
                let dimensionDict = null;

                Object.keys(cnf[dimension]).forEach((value) => {
                    const cnfItem = cnf[dimension][value];

                    if (cnfItem?.title) {
                        dimensionDict = { ...(dimensionDict || {}), [value]: cnfItem?.title };
                    }
                });

                if (dimensionDict) dict[dimension] = dimensionDict;
            });

            return dict;
        },

        getParamsForSavedFilterRequest(title: string, type: string, filtersData?: TFilters): ISaveFilterRequestParams {
            const metricFilters = [];
            const dimensionFilters = [];

            filtersData?.forEach((filter) => {
                if (filtersCnf[filter.key]?.isMetricFilter) {
                    metricFilters.push(filter);
                } else {
                    dimensionFilters.push(filter);
                }
            });

            return {
                title,
                type,
                body: {
                    ...(metricFilters.length ? { metric_filters: metricFilters } : {}),
                    ...(dimensionFilters.length ? { dimension_filters: dimensionFilters } : {}),
                },
            };
        },

        getSavedFilters(): void {
            dispatch(globalActions.getSavedFilters(projectId));
        },

        createSavedFilter(title: string, type: ESavedFilterType): void {
            const filterParams = api.getParamsForSavedFilterRequest(title, type, globalFilters);

            dispatch(globalActions.saveFilter({ projectId, filterParams }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(SAVE_FILTER);
                });
        },

        editSavedFilter(actionName: ESavedFilterActions, data: Partial<ISavedFilterData>): void {
            const { id } = data || {};
            const existingData = savedFiltersList.find((item) => item.id === id);

            // При редактировании шаблона: перезаписываем только то, что пришло из формы редактирования (заголовок и тип),
            // список фильтров шаблона при редактировании не меняется
            // При сохранении шаблона: перезаписываем список фильтров шаблона
            const title = data?.title || existingData.title;
            const type = data?.type || existingData.type;

            const filtersData = actionName === ESavedFilterActions.edit ? existingData.filters : globalFilters;
            const filterParams = api.getParamsForSavedFilterRequest(title, type, filtersData);

            dispatch(globalActions.updateSavedFilter({ projectId, id, filterParams }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(UPDATE_FILTER);
                });
        },

        deleteSavedFilter(id: number): void {
            dispatch(globalActions.deleteSavedFilter({ projectId, id }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar(DELETE_FILTER);
                });
        },

        applySavedFilterAction(actionName: ESavedFilterActions, data: Partial<ISavedFilterData>): void {
            switch (actionName) {
                case ESavedFilterActions.save:
                case ESavedFilterActions.edit:
                    api.editSavedFilter(actionName, data);
                    break;
                case ESavedFilterActions.create:
                    api.createSavedFilter(data.title, data.type);
                    break;
                case ESavedFilterActions.delete:
                    api.deleteSavedFilter(data.id);
                    break;
                default:
                    break;
            }
        },

        // Получаем из конфига фильтров плоский объект с ключами дименшинами
        transformFiltersConfigToFlatObj(filtersConfig: TFiltersListCnf): Record<string, boolean> {
            let resultObj = {};

            filtersConfig.forEach((item) => {
                if (item.children) {
                    resultObj = { ...resultObj, ...api.transformFiltersConfigToFlatObj(item.children) };
                } else {
                    resultObj = { ...resultObj, [item.key]: true };
                }
            });

            return resultObj;
        },

        // Фильтруем значения сохраненных фильтров для конкретного отчета,
        // потому что на разных отчетах разный набор фильтров
        getSavedFiltersByReport(savedFilters: ISavedFilterData[], reportName: ReportName): ISavedFilterData[] {
            let filtersConfig = filtersListCnf;

            if (['top', 'materials_summary', 'material', 'authors_summary', 'author'].includes(reportName)) {
                filtersConfig = filtersConfigMedia;
            }

            const flatCnfObj = {
                ...api.transformFiltersConfigToFlatObj(filtersConfig),
                event_class: true, // костыль, чтобы не игнорировать фильтры, где есть фильтр по event_class
            };

            return savedFilters.filter(({ filters }) => !filters.some(({ key }) => !flatCnfObj[key]));
        },
    };

    return api;
};

export default useFilters;
