import { Alert, Modal, TableSettingsList, TTableSettingsListItemProps } from '@adtech/ui';
import { dimensionsDict } from '@configs/dimensions';
import { metricsDict } from '@configs/metrics';
import { Dimensions } from '@typings/dimensions';
import { Metrics } from '@typings/metrics';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './MarketingTable.pcss';

interface IProps {
    isOpen: boolean;
    onOkHandler: (dimensionsSelected?: Dimensions[]) => void;
    onCloseHandler: () => void;
    dimensions: Dimensions[];
    allDimensions: Dimensions[];
    defaultColumn: Metrics;
}

const TableSettingsModal: React.FC<IProps> = ({
    isOpen,
    onOkHandler,
    onCloseHandler,
    dimensions,
    allDimensions,
    defaultColumn,
}) => {
    const createColumn = (columnId) => ({
        id: columnId,
        checked: true,
        onChange: () => {},
        disabled: true,
        sortDisabled: true,
        title: metricsDict[columnId]?.title,
    });

    const [defaultColumns, setDefaultColumns] = useState(() => [createColumn(defaultColumn)]);
    const [isChecked, setIsChecked] = useState(true);

    useEffect(() => {
        setDefaultColumns([createColumn(defaultColumn)]);
    }, [defaultColumn]);

    const transformToItems = (dimensionsValues: Dimensions[]) =>
        dimensionsValues.map((dimension) => ({
            id: dimension,
            checked: dimensions.includes(dimension),
            onChange: () => {},
            title: dimensionsDict[dimension]?.title || '',
        }));

    const [columns, setColumns] = useState(transformToItems(allDimensions));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const selectedItems = useRef<TTableSettingsListItemProps[]>(null);

    useEffect(() => {
        setColumns(transformToItems(allDimensions));
    }, [JSON.stringify(dimensions), JSON.stringify(allDimensions), defaultColumns]);

    const onChangeSettings = (values) => {
        const hasChecked = values.some(
            (item) => item.checked === true && (item.disabled === false || item.disabled === undefined),
        );
        setIsChecked(hasChecked);
        selectedItems.current = values;
    };

    const modalOkHandler = () => {
        const itemsSelected = selectedItems.current;

        if (itemsSelected && isChecked) {
            const selectedDimensions = itemsSelected
                ?.filter((item) => item?.checked && !item?.disabled)
                .map((item) => item?.id) as Dimensions[];

            onOkHandler(selectedDimensions);
        } else {
            setIsChecked(false);
        }
    };

    const items = useMemo(() => [...defaultColumns, ...columns], [defaultColumns, columns]);

    return (
        <Modal
            className={s.marketingTableSettingsModal}
            isOpened={isOpen}
            title="Настройка сводной таблицы"
            okText="Сохранить"
            cancelText="Отменить"
            onOk={modalOkHandler}
            onClose={onCloseHandler}
            closeOnClickOutside={false}
            centered
            size="lightbox"
        >
            <div className={s.marketingTableSettingsModalTitle} data-testid="help-text">
                Выберите данные для группировки
                {!isChecked && (
                    <Alert
                        className={s.marketingTableModalAlert}
                        type="warning"
                        showIcon
                        message="Выберете хотя бы 1 пункт"
                        data-testid="help-text"
                    />
                )}
            </div>
            <TableSettingsList
                items={items}
                onChange={onChangeSettings}
                className={s.funnelsTableSettingsModalSettings}
            />
        </Modal>
    );
};

export default TableSettingsModal;
