import { IUsersValue } from '@typings/counter';
import React from 'react';

import s from './AccessUsers.pcss';
import AddUserForm from './AddUserForm';
import UsersList from './UsersList';

interface IProps {
    usersList: IUsersValue[];
    setUsersList: (value: IUsersValue[]) => void;
}

const AccessUsers: React.FC<IProps> = ({ usersList, setUsersList }) => (
    <>
        <section className={s.section}>
            <div className={s.sectionTitle}>Разрешить доступ пользователям</div>
            <AddUserForm usersList={usersList} setUsersList={setUsersList} />
        </section>
        <section className={s.section}>
            <div className={s.sectionTitle}>Добавленные пользователи</div>
            <UsersList usersList={usersList} setUsersList={setUsersList} />
        </section>
    </>
);

export default AccessUsers;
