import { Button, Pagination, Loader } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { IRootSlice } from '@typings/rootSlice';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CounterRow } from './CounterRow';

import s from './RestoreCounterStep.pcss';

interface ICounterItem {
    id: number;
    url: string;
    title: string;
    created: string;
    inRating: boolean;
    favicon: string;
}

interface IProps {
    goToCreateCounter: (e: React.MouseEvent) => void;
}

const RestoreCounterStep: React.FC<IProps> = ({ goToCreateCounter }) => {
    const isLoading = useAppSelector((state: IRootSlice) => state.projectsSlice.projectsWithSameUrlRequest) || false;
    const projectsWithSameUrl = useAppSelector((state: IRootSlice) => state.projectsSlice.projectsWithSameUrl);
    const countersData = projectsWithSameUrl?.data || [];
    const { limit, offset, total, originUrl } = projectsWithSameUrl || {};
    const userEmail = useAppSelector((state: IRootSlice) => state.userSlice.email);

    const [selectedCounter, setSelected] = useState(countersData[0]?.id);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const pagesCount = Math.ceil(total / limit);
    const currentPage = Math.ceil(offset / limit) + 1;

    const getNextPageCounters = (page: number) => {
        const newOffset = (page - 1) * limit;

        dispatch(
            projectsActions.getProjectsWithSameUrl({
                url: originUrl,
                limit,
                offset: newOffset,
            }),
        );
    };

    const openModalPopup = (modalPopupId, modalPopupParams = {}) => {
        dispatch(globalActions.openModalPopup({ modalPopupId, modalPopupParams }));
    };

    const restoreCounter = (projectId: number) => {
        dispatch(projectsActions.restoreCounter(projectId)).then(({ payload }) => {
            if (payload.responseStatus === 204) {
                navigate('/stat/projects');
                openModalPopup('SuccessRestoreCounter');
            }
        });
    };

    if (!countersData.length) return null;

    return (
        <div className={s.restoreCounterStep}>
            <Loader loading={isLoading}>
                <div>
                    <div className={s.head}>
                        <div className={s.title}>Сайт</div>
                        <div className={s.date}>Дата создания</div>
                    </div>
                    <div>
                        {countersData.map((counterItem: ICounterItem) => (
                            <CounterRow
                                key={counterItem.id}
                                counterItem={counterItem}
                                onChange={(value) => setSelected(value)}
                                selectedCounter={selectedCounter}
                            />
                        ))}
                    </div>
                    {pagesCount > 1 && (
                        <div className={s.footer}>
                            <Pagination pageSize={pagesCount} current={currentPage} onChange={getNextPageCounters} />
                        </div>
                    )}
                </div>
                <div className={s.buttons}>
                    <Button
                        type="dashed"
                        onClick={goToCreateCounter}
                        data-create-counter={
                            `${attributesData.createCounter.restoreCounterStep}` +
                            `::${attributesData.createCounter.createCounterBtn}`
                        }
                    >
                        Создать новый счётчик
                    </Button>
                    <Button
                        onClick={() => {
                            restoreCounter(selectedCounter);
                            openModalPopup('RestoreAccessFinal', {
                                userEmail,
                            });
                        }}
                        data-create-counter={
                            `${attributesData.createCounter.restoreCounterStep}` +
                            `::${attributesData.createCounter.restoreCounterBtn}`
                        }
                    >
                        Восстановить доступ
                    </Button>
                </div>
            </Loader>
        </div>
    );
};

export default RestoreCounterStep;
