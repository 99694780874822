import { Checkbox } from '@adtech/ui';
import IconMinus from '@images/svg/icons/icon-minus.svg';
import IconPlus from '@images/svg/icons/icon-plus.svg';
import cn from 'classnames';
import React from 'react';

import s from '../ListData.pcss';

interface IDataTypeItem {
    checkbox: boolean;
    id: number;
    parent_id: number;
    parent_title: string;
    theme_id: number;
    title: string;
}

interface IProps {
    mode: 'three' | 'flat';
    data: IDataTypeItem[];
    getIndeterminate: (item: any) => boolean;
    getCountLengthSelected: (item: any) => number;
    setValueItem: (item: any) => void;
    currentElement: number[];
    selectedValues: number[];
    currentNesting: number;
}

const List: React.FC<IProps> = ({
    mode,
    data,
    getIndeterminate,
    getCountLengthSelected,
    setValueItem,
    currentElement,
    selectedValues,
    currentNesting,
}) => {
    // Получаем ID выбранного родителя
    const getCurrentElementParentId = () => (mode === 'three' ? currentElement[0] || false : false);

    const renderValueItem = ({ item, key, parentOffsets = [] }) => {
        const subItems = data.filter((itm) => itm.parent_id === item.id);

        const isShowIcon =
            currentNesting !== parentOffsets.length + 1 &&
            ((currentNesting > 2 && parentOffsets.length >= 1) || (!subItems.length && parentOffsets.length >= 1));
        const isChecked = selectedValues.includes(item.id);
        const isIndeterminate = getIndeterminate(item);

        const resParentOffsets = [...parentOffsets, item.id];

        const titleClassName = cn(s.checkboxItemTitle, {
            [s.active]: isChecked && getCurrentElementParentId() === item.id,
            [s.bold]: isChecked && parentOffsets.length === 0,
        });

        const countSelected = getCountLengthSelected(item);

        const onCheck = () =>
            setValueItem({
                item,
                parentOffsets: resParentOffsets,
            });

        const Icon = () =>
            isChecked ? (
                <IconMinus className={s.checkboxItemIconSvg} />
            ) : (
                <IconPlus className={s.checkboxItemIconSvg} />
            );

        return (
            <div className={s.checkboxItem} key={key}>
                <div className={s.checkboxItemInner}>
                    {isShowIcon && (
                        <div className={s.checkboxItemIcon} onClick={onCheck}>
                            {subItems.length > 0 && <Icon />}
                        </div>
                    )}
                    <Checkbox
                        onChange={onCheck}
                        checked={isChecked && !isIndeterminate}
                        indeterminate={isIndeterminate}
                        className={s.checkboxItemElement}
                    >
                        <div className={s.checkboxItemContainer}>
                            <div className={titleClassName}>{item.title}</div>
                            {mode === 'three' && countSelected > 0 && parentOffsets.length === 0 ? (
                                <span className={s.checkboxItemCount}>Выбрано {countSelected}</span>
                            ) : null}
                        </div>
                    </Checkbox>
                </div>
                {isChecked && subItems.length > 0 && parentOffsets.length >= 1 ? (
                    <div className={s.checkboxItemSub}>
                        {subItems.map((subItm, subKey) =>
                            renderValueItem({
                                item: subItm,
                                key: `sub-${subKey}`,
                                parentOffsets: resParentOffsets,
                            }),
                        )}
                    </div>
                ) : null}
            </div>
        );
    };

    const renderNoItems = (text: string) => <div className={s.noItems}>{text}</div>;

    const renderRightValues = () => {
        let resultData = data;

        if (mode === 'three') {
            resultData = resultData.filter((item) => item.parent_id === getCurrentElementParentId());

            if (!getCurrentElementParentId() || !resultData.length) {
                return (
                    <div className={s.checkbox}>
                        {renderNoItems(data.length ? 'Пока не выбрана ни одна группа параметров' : '')}
                    </div>
                );
            }
        }

        const parentOffsets = getCurrentElementParentId();

        return (
            <div className={s.checkbox}>
                {resultData.map((item, key) =>
                    renderValueItem({
                        item,
                        key: `main-${key}`,
                        parentOffsets: parentOffsets ? [parentOffsets] : [],
                    }),
                )}
            </div>
        );
    };

    const renderLeftValues = () => {
        const filteredData = data.filter((item) => !item.parent_id);

        return filteredData.map((item, index): any =>
            renderValueItem({
                item,
                key: `main-${index}`,
            }),
        );
    };

    return mode === 'flat' ? (
        <div className={s.contentList}>
            <div className={s.contentListItem}>{renderRightValues()}</div>
            <div className={s.contentListItem} />
        </div>
    ) : (
        <div className={s.contentList}>
            <div className={s.contentListItem}>
                <div>{renderLeftValues()}</div>
            </div>
            <div className={s.contentListItem}>{renderRightValues()}</div>
        </div>
    );
};

export default List;
