import { Switch } from '@adtech/ui';
import ContentWithSidebar from '@components/ContentWithSidebar';
import PageHead from '@components/PageHead';

import Counter from '@containers/PostClick/components/Counter';
import useGlobalLoading from '@hooks/useGlobalLoading';
import usePostClick from '@hooks/usePostClick';
import useProjectRequired from '@hooks/useProjectRequired';
import { useAppDispatch } from '@hooks/useStore';

import { globalActions } from '@redux/slices/global';
import { ReportName } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import s from './ImportDataFromYM.pcss';

const ImportDataFromYM: FC = () => {
    useGlobalLoading();

    const dispatch = useAppDispatch();

    const reportName = 'import_data_from_ym' as ReportName;

    const isProjectRequired = useProjectRequired();
    const isReportInited = useRef(false);

    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);

    const globalLoading = useSelector((state: IRootSlice) => state.globalSlice.globalLoading);
    const projectURL = useSelector((state: IRootSlice) => state.projectsSlice.current.cleanedUrl);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const { counterIsCreated, counter } = useSelector((state: IRootSlice) => state.postClickSlice);

    const api = usePostClick();

    const [importFromYM, setImportFromYM] = useState(counter?.import_from_ym || false);

    const setGlobalLoading = (param: boolean) => dispatch(globalActions.setGlobalLoading(param));

    const getPageTitle = () => {
        const url = projectURL ? `(${projectURL}) ` : '';
        return `Получение событий от Яндекс Метрики ${url} – Статистика от SberAds / ${projectId}`;
    };

    useEffect(() => {
        if (!isProjectRequired) {
            setGlobalLoading(true);
            return;
        }

        isReportInited.current = false;

        api.resetCounterData();
        api.init().then(async () => {
            isReportInited.current = true;
        });

        setGlobalLoading(false);
    }, [isProjectRequired, projectId]);

    useEffect(
        () => () => {
            setGlobalLoading(false);
            api.resetCounterData();
        },
        [],
    );

    useEffect(() => {
        setImportFromYM(counter?.import_from_ym);
    }, [counter?.import_from_ym]);

    const changeImportFromYM = (value: boolean) => {
        setImportFromYM(value);
        api.updateImportFromYM(value);
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            {!globalLoading && (
                <>
                    <PageHead
                        reportName={reportName}
                        projectId={projectId}
                        userId={userId}
                        title="Получение событий от Яндекс Метрики"
                        labelText="Бета"
                    />
                    <ContentWithSidebar>
                        <Counter counterIsCreated={counterIsCreated} counter={counter} api={api} />
                        <div className={s.importDataFromYM}>
                            <div className={s.importDataFromYMHeader}>
                                Настройка получения событий от Яндекс Метрики
                            </div>
                            <div className={s.importDataFromYMContent}>
                                <Switch
                                    checked={importFromYM}
                                    onChange={changeImportFromYM}
                                    disabled={!counterIsCreated}
                                />
                                <div
                                    className={cn(s.importDataFromYMText, {
                                        [s.importDataFromYMTextDisabled]: !counterIsCreated,
                                    })}
                                >
                                    Получать события от Яндекс Метрики
                                </div>
                            </div>
                        </div>
                    </ContentWithSidebar>
                </>
            )}
        </DocumentTitle>
    );
};

export default ImportDataFromYM;
