import { Button, Checkbox, ContextAlertMark, Input } from '@adtech/ui';
import CommonFilters from '@components/CommonFilters';
import { TFilters } from '@components/CommonFilters/types/filters';
import Form from '@components/Form';
import { Hint } from '@components/Hint';
import { EntityAccess } from '@configs/entity';
import { filtersCnf as filtersFormCnf } from '@configs/filters/filters';
import { filtersListCnf } from '@configs/filters/filtersConfigGoals';
import { GoalFormType, goalFormTypesConfig } from '@configs/goals/goalForm';
import validationMessages from '@constants/validationMessages';
import useFilters from '@hooks/useFilters';
import useGoals from '@hooks/useGoals';
import { IFilter } from '@typings/filters';
import { IGoalBodyData } from '@typings/goals';
import { IRootSlice } from '@typings/rootSlice';
import { formUtils } from '@utils/index';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import s from './GoalForm.pcss';

interface IETarget {
    target: {
        checked: boolean | ((prevState: boolean) => boolean);
    };
}

interface IStatus {
    isValid: boolean;
    message: string;
}

interface IProps {
    type: GoalFormType;
    closePopup: () => void;
    title?: string; // название цели
    accessProp?: EntityAccess; // доступ к цели
    protectedProp?: boolean; // запрет на редактирование
    sendingTDProp?: boolean; // использовать в рекламной кампании ТД
    filtersData?: IGoalBodyData; // условие цели
    goalId?: number;
    customButtonsRender?: (submitHandler: (reqParams) => void) => React.ReactNode;
    filtersContentSize?: 'default' | 'small';
}

const GoalForm: FC<IProps> = ({
    type,
    title = '',
    accessProp,
    protectedProp,
    sendingTDProp,
    filtersData,
    closePopup,
    goalId,
    customButtonsRender,
    filtersContentSize = 'default',
}: IProps) => {
    const dynamicFiltersData = useSelector((state: IRootSlice) => state.globalSlice.dynamicFiltersData);
    const filtersValues = useRef<{ getValues(): TFilters }>(null);

    const [goalName, setGoalName] = useState(title);
    const [access, setAccess] = useState(accessProp || EntityAccess.own);
    const [isProtected, setProtected] = useState(protectedProp || false);
    const [isSendingTD, setSendingTD] = useState(sendingTDProp || false);
    const [goalNameStatus, setGoalNameStatus] = useState<IStatus>({
        isValid: true,
        message: null,
    });
    const [conditionStatus, setConditionStatus] = useState<IStatus>({
        isValid: true,
        message: null,
    });
    const [isShowErrors, setShowErrors] = useState(false);

    const { getDynamicFilters } = useFilters();

    const { getEventsList, createGoal, updateGoal, transformGoalDataForRequest, isDemoProject } = useGoals();

    useEffect(() => {
        getEventsList();
    }, []);

    const changeGoalTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setGoalName(value);
    };

    const changeAccessHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setAccess(checked ? EntityAccess.common : EntityAccess.own);

        if (!checked) setSendingTD(false);
    };

    const changeProtectedHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setProtected(checked);

        if (!checked) setSendingTD(false);
    };

    const changeIsSendingTDHandler = (e: IETarget) => {
        const { checked } = e?.target || {};
        setSendingTD(checked);

        if (checked) {
            setAccess(EntityAccess.common);
            setProtected(true);
        }
    };

    const validateGoalName = (value: string) => {
        let status = {
            isValid: true,
            message: null,
        };

        const trimValue = value.trim();
        const validRequired = formUtils.rules.required(trimValue);

        if (!validRequired) {
            status = {
                isValid: false,
                message: validationMessages.required,
            };
        } else {
            const validMinLength = formUtils.rules.minLength(3)(trimValue);

            if (!validMinLength) {
                status = {
                    isValid: false,
                    message: validationMessages.minLength(3),
                };
            }
        }

        setGoalNameStatus(status);
        return status.isValid;
    };

    const validateCondition = (filtersArr: IFilter[]): boolean => {
        let status: IStatus = {
            isValid: true,
            message: null,
        };
        const errorStatus: IStatus = {
            isValid: false,
            message: 'Не все поля в условии заполнены',
        };

        if (!filtersArr.length) status = errorStatus;

        filtersArr.forEach(({ key, op, value }) => {
            if (!key || !op || value === undefined || value === '') {
                status = errorStatus;
            }
        });

        setConditionStatus(status);
        return status.isValid;
    };

    const validateForm = (filters?: IFilter[]): boolean => {
        const isValidName = validateGoalName(goalName);
        const isValidCondition = validateCondition(filters);
        const isValidForm = isValidName && isValidCondition;

        setShowErrors(!isValidForm);
        return isValidForm;
    };

    const createGoalHandler = (reqParams?: { showCommonGoals?: boolean; limit?: number }) => {
        const newCondition = filtersValues.current?.getValues() as IFilter[];
        const isValidForm = validateForm(newCondition);

        if (!isValidForm) return;

        createGoal(
            transformGoalDataForRequest({
                title: goalName,
                access,
                is_protected: isProtected,
                is_td_sending: isSendingTD,
                body: {
                    dimension_filters: newCondition,
                },
            }),
            reqParams,
        );
        closePopup();
    };

    const editGoalHandler = () => {
        const newCondition = filtersValues.current?.getValues() as IFilter[];
        const isValidForm = validateForm(newCondition);

        if (!isValidForm) return;

        updateGoal(
            goalId,
            transformGoalDataForRequest({
                title: goalName,
                access,
                is_protected: isProtected,
                is_td_sending: isSendingTD,
                body: {
                    dimension_filters: newCondition,
                },
            }),
        );
        closePopup();
    };

    return (
        <Form onSubmit={() => {}} className={s.addGoalForm}>
            <Input
                value={goalName}
                name="name"
                onChange={changeGoalTitle}
                placeholder="Введите название целевого события"
                type="text"
                status={!isShowErrors || goalNameStatus.isValid ? null : 'error'}
                errorText={isShowErrors ? goalNameStatus.message : ''}
                onBlur={() => {
                    validateGoalName(goalName);
                }}
                data-testid="title-input"
                maxLength={255}
            />
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="access" checked={access === EntityAccess.common} onChange={changeAccessHandler}>
                    Сделать цель публичной
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Цель будут видеть все, у&nbsp;кого есть доступ к&nbsp;счётчику
                </Hint>
            </div>
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="protected" checked={isProtected} onChange={changeProtectedHandler}>
                    Запретить редактирование цели
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Дальнейшее редактирование цели будет запрещено, ее можно будет только удалить
                </Hint>
            </div>
            <div className={s.checkboxWrap} data-testid="checkbox-container">
                <Checkbox name="isSendingTD" checked={isSendingTD} onChange={changeIsSendingTDHandler}>
                    Использовать в рекламной кампании
                </Checkbox>
                <Hint className={s.checkboxWrapHint}>
                    Цель для использования в&nbsp;рекламной кампании SberAds, редактирование будет запрещено
                </Hint>
            </div>
            <div className={s.conditions}>
                <div className={s.conditionsTitle}>Настройте условие достижения цели</div>
                <div className={s.conditionsFields}>
                    <CommonFilters
                        mode="simple"
                        size={filtersContentSize}
                        filtersData={filtersData?.dimension_filters || []}
                        filtersListCnf={filtersListCnf}
                        filtersFormCnf={filtersFormCnf}
                        ref={filtersValues}
                        dynamicFilterData={dynamicFiltersData}
                        getDynamicFilterData={getDynamicFilters}
                        wrapperClassName={s.conditionsInner}
                    />
                </div>
            </div>
            {(isShowErrors || !conditionStatus.isValid) && (
                <div className={s.conditionsStatus}>
                    <ContextAlertMark
                        status={!isShowErrors || conditionStatus.isValid ? null : 'error'}
                        message={isShowErrors ? conditionStatus.message : ''}
                    />
                </div>
            )}
            {customButtonsRender ? (
                customButtonsRender(createGoalHandler)
            ) : (
                <div className={s.btnWrapper}>
                    <Button
                        className={s.btn}
                        size="middle"
                        disabled={isDemoProject()}
                        data-testid="ок-button"
                        onClick={type === GoalFormType.edit ? editGoalHandler : () => createGoalHandler()}
                    >
                        {goalFormTypesConfig[type].buttonTitle}
                    </Button>
                    <Button type="dashed" size="middle" data-testid="cancel-button" onClick={closePopup}>
                        Отмена
                    </Button>
                </div>
            )}
        </Form>
    );
};

export default GoalForm;
