import React, { FC } from 'react';

import s from '../../PostClick.pcss';

import CounterAbsent from '../Counter/CounterAbsent';
import CounterCreated from '../Counter/CounterCreated';

interface IProps {
    counterIsCreated: boolean;
    counter: { id: number; is_active: boolean; project_id: number };
    api: TAPI;
}

const Counter: FC<IProps> = ({ counterIsCreated, counter, api }) => (
    <div className={s.counterBlock}>
        <div className={s.counterBlockHeader}>Синхронизация счётчика</div>
        <div className={s.counterBlockContent}>
            {counterIsCreated ? (
                <CounterCreated deleteCounter={api.deleteCounter} counterNumber={counter.id} />
            ) : (
                <CounterAbsent />
            )}
        </div>
    </div>
);

export default Counter;
