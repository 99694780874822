import funnelsConfig from '@configs/funnels';
import { LatencyKeys } from '@constants/latency';
import reportsConstants from '@constants/reports';
import userRoles from '@constants/userRoles';
import useGlobalParams from '@hooks/useGlobalParams';
import useQuery from '@hooks/useQuery';
import { useRequestParams } from '@hooks/useRequestParams';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch } from '@hooks/useStore';
import { useTable } from '@hooks/useTable';
import { funnelsActions } from '@redux/slices/funnels';
import { DateLiteral } from '@typings/date';
import { IFunnelDTO } from '@typings/funnels';
import { Metrics } from '@typings/metrics';
import { IQuery } from '@typings/query';
import { IUpdateReportOptions, IUpdateReportParams, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import { urlUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

const useFunnels = () => {
    const reportName = 'funnels';
    const params: IUrlParams = useParams();
    const projectId = parseInt(params.projectId, 10);
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { search } = location;
    const query: IQuery = urlUtils.parseQuery(search);
    const MAX_FUNNELS_LIMIT = 100;

    const currentFunnelId = useSelector((state: IRootSlice) => state.funnelsSlice.currentFunnelId);
    const metrics = useSelector((state: IRootSlice) => state.funnelsSlice.metrics);

    const userRole = useSelector((state: IRootSlice) => state.userSlice.roleId);

    const latencySlice = useSelector((state: IRootSlice) => state.latencySlice);

    const dateStart = useSelector((state: IRootSlice) => state.globalSlice.dateStart);
    const dateEnd = useSelector((state: IRootSlice) => state.globalSlice.dateEnd);
    const globalFilters = useSelector((state: IRootSlice) => state.globalSlice.globalFilters);

    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const sample = useSelector((state: IRootSlice) => state.tableSlice.sample);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: funnelsConfig,
    });

    const { setSnackbar } = useSnackbar();
    const { updateGlobalParams } = useGlobalParams();
    const {
        api: { updateTableParams },
    } = useTable();
    const {
        api: { getParamsForRequest },
    } = useRequestParams();

    const { getParamsFromQuery, checkQueryParams } = useQuery();

    const api = {
        // Метод инициализации для страницы просмотра одной конверсии
        async init(): Promise<void> {
            const checkedParams = api.getParams();

            updateGlobalParams(checkedParams);

            if (latencySlice[LatencyKeys.base]?.isExcludedLatency === false) {
                api.getFunnelsList({ limit: MAX_FUNNELS_LIMIT });
            }

            return Promise.resolve();
        },

        // Метод получения параметров для запроса за конверсиями
        getParams(): IUpdateReportParams {
            const defaultParams = {
                dateStart,
                dateEnd,
                sample,
                globalFilters: [...globalFilters],
            };

            const queryParams = getParamsFromQuery(query);

            return checkQueryParams(
                queryParams,
                defaultParams,
                {
                    ...tableSlice,
                },
                () => {},
            );
        },

        // Метод обновления данных воронок (при изменении детализации, дат...)
        updateReport(
            type: string, // all, table... - что нужно обновить, опциональный параметр
            updateParams: IUpdateReportOptions<IUpdateReportParams, ReportNameBase>,
        ): void {
            const { updateParams: updParams, options } = updateParams;
            const { withoutCompare } = options || {};

            updateTableParams(updParams, withoutCompare);
            updateGlobalParams(updParams, withoutCompare);

            if (currentFunnelId !== null) {
                api.getFunnelTable(currentFunnelId, updParams);
            }
        },

        getFunnelTable(funnelId: number, updParams?: IUpdateReportParams): void {
            const reqParams = getParamsForRequest(reportName, 'table', {
                dateStart,
                dateEnd,
                globalFilters,
                sample,
                metrics: metrics?.length
                    ? metrics
                    : (reportsUtils.getParamFromConfig(reportName, 'tableDefaultMetrics') as Metrics[]),
                ...(updParams || {}),
            });
            const reqData = {
                funnel_id: funnelId,
                date_start: reqParams.date_start,
                date_end: reqParams.date_end,
                metrics: reqParams.metrics,
                dimension_filters: reqParams.dimension_filters,
                sample: reqParams.sample || 1,
            };
            dispatch(funnelsActions.getFunnelTable({ projectId, data: reqData }));
        },

        getFunnelsList(reqParams: { limit?: number; search?: string; offset?: number }): void {
            dispatch(funnelsActions.getFunnelsList({ ...reqParams, projectId }));
        },

        getFunnel(id: number): void {
            dispatch(funnelsActions.getFunnel({ id, projectId }));
        },

        createFunnel(data: IFunnelDTO): void {
            dispatch(funnelsActions.createFunnel({ data, projectId }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar('Воронка создана');
                });
        },

        editFunnel({ id, ...data }: IFunnelDTO): void {
            dispatch(funnelsActions.updateFunnel({ id, data, projectId }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar('Воронка изменена');
                    api.getFunnelTable(id);
                });
        },

        deleteFunnel(id: number): void {
            dispatch(funnelsActions.deleteFunnel({ id, projectId }))
                .unwrap()
                .then((res) => {
                    if (!res || res.responseStatus !== 200) return;
                    setSnackbar('Воронка удалена');
                });
        },

        updateParams(newParams: Record<string, any>): void {
            dispatch(funnelsActions.updateParams(newParams));
        },

        handleChangeDate(date: string[], relativeDate: DateLiteral): void {
            const [start, end] = date;

            const updateParams = {
                ...(relativeDate ? DateUtils.getRelativeDates(relativeDate) : { dateStart: start, dateEnd: end }),
            };

            api.updateReport('all', { updateParams });
        },

        isDemoProject(): boolean {
            return Number(projectId) === reportsConstants.DEMO_PROJECT_ID && userRole !== userRoles.ADMIN;
        },
    };

    return { api, reportsUtils };
};

export default useFunnels;
