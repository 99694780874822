import { Button, DropDownButton, DropDownList } from '@adtech/ui';
import React, { useEffect, useState, FC } from 'react';
import CreateOrEditSavedFilterModal from '../modals/CreateOrEditSavedFilterModal';
import DeleteSavedFilterModal from '../modals/DeleteSavedFilterModal';
import { TAllFormValues, TFilters } from '../types/filters';
import { ESavedFilterActions, ISavedFilterActionData, ISavedFilterData } from '../types/savedFilters';
import s from './SavedFilters.pcss';
import SavedFiltersList from './SavedFiltersList';

interface IProps {
    savedFiltersList: ISavedFilterData[];
    savedFilterCurrentId: number;
    loading?: boolean;
    getSavedFiltersList: () => void;
    requestAction: (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => void;
    filtersData: TFilters;
    allFormsValues: TAllFormValues;
    applyFiltersHandler?: (filtersData: TFilters) => void;
}

const SavedFilters: FC<IProps> = ({
    savedFiltersList,
    savedFilterCurrentId,
    loading,
    getSavedFiltersList,
    requestAction,
    filtersData,
    allFormsValues,
    applyFiltersHandler,
}) => {
    const [currentFilterId, setCurrentFilterId] = useState<number>(savedFilterCurrentId);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [modalCreateOrEditData, setModalCreateOrEditData] = useState<ISavedFilterActionData>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalDeleteData, setModalDeleteData] = useState<ISavedFilterActionData>(null);

    useEffect(() => {
        if (savedFiltersList?.length) return;

        getSavedFiltersList();
    }, []);

    useEffect(() => {
        if (savedFilterCurrentId !== currentFilterId) setCurrentFilterId(savedFilterCurrentId);
    }, [savedFilterCurrentId]);

    // Сбрасываем текущий выбранный фильтр, если он был удален
    useEffect(() => {
        if (currentFilterId === null) return;

        const curFilter = savedFiltersList.find(({ id }) => id === currentFilterId);

        if (!curFilter) setCurrentFilterId(null);
    }, [JSON.stringify(savedFiltersList)]);

    const createModalCloseHandler = () => {
        setModalCreateOrEditData(null);
        setIsCreateModalOpen(false);
    };

    const deleteModalCloseHandler = () => {
        setModalDeleteData(null);
        setIsDeleteModalOpen(false);
    };

    const handleAction = (actionName: ESavedFilterActions, data: Partial<ISavedFilterData> = {}) => {
        switch (actionName) {
            case ESavedFilterActions.save:
                requestAction(actionName, data);
                break;
            case ESavedFilterActions.edit:
            case ESavedFilterActions.create:
                // Открываем модальное окно с задержкой,
                // чтобы анимация открытия не пересекалась с анимацией закрытия выпадающих списков (удаление, редактирование и пр.)
                setTimeout(() => {
                    setModalCreateOrEditData({ actionName, data });
                    setIsCreateModalOpen(true);
                }, 200);
                break;
            case ESavedFilterActions.delete:
                setTimeout(() => {
                    setModalDeleteData({ actionName, data });
                    setIsDeleteModalOpen(true);
                }, 200);
                break;
            default:
                break;
        }
    };

    const onOkHandler = (actionName: ESavedFilterActions, data: Partial<ISavedFilterData>) => {
        switch (actionName) {
            case ESavedFilterActions.edit:
            case ESavedFilterActions.create:
                setModalCreateOrEditData(null);
                setIsCreateModalOpen(false);
                break;
            case ESavedFilterActions.delete:
                setModalDeleteData(null);
                setIsDeleteModalOpen(false);
                break;
            default:
                break;
        }
        requestAction(actionName, data);
    };

    const isBtnSaveDisabled = !Object.keys(allFormsValues)?.length || loading;

    return (
        <div className={s.savedFilters}>
            <SavedFiltersList
                list={savedFiltersList}
                isLoading={loading}
                filtersData={filtersData}
                currentFilterId={currentFilterId}
                setCurrentFilterId={setCurrentFilterId}
                applyFiltersHandler={applyFiltersHandler}
                onClickAction={handleAction}
            />
            {currentFilterId ? (
                <DropDownList
                    menuItems={[
                        {
                            dropDownItemProps: {
                                text: 'Сохранить текущий',
                                onClick: () =>
                                    handleAction(ESavedFilterActions.save, {
                                        id: currentFilterId,
                                    }),
                            },
                        },
                        {
                            dropDownItemProps: {
                                text: 'Сохранить как новый',
                                onClick: () => handleAction(ESavedFilterActions.create),
                            },
                        },
                    ]}
                    customButton={
                        <DropDownButton className={s.savedFiltersBtn} size="small" disabled={isBtnSaveDisabled}>
                            Сохранить шаблон
                        </DropDownButton>
                    }
                />
            ) : (
                <Button
                    onClick={() => handleAction(ESavedFilterActions.create)}
                    disabled={isBtnSaveDisabled}
                    size="small"
                >
                    Сохранить шаблон
                </Button>
            )}
            <CreateOrEditSavedFilterModal
                savedFiltersTitles={savedFiltersList.map((item) => item.title)}
                modalData={modalCreateOrEditData}
                isOpen={isCreateModalOpen}
                onOkHandler={onOkHandler}
                onCloseHandler={createModalCloseHandler}
            />
            <DeleteSavedFilterModal
                modalData={modalDeleteData}
                isOpen={isDeleteModalOpen}
                onOkHandler={onOkHandler}
                onCloseHandler={deleteModalCloseHandler}
            />
        </div>
    );
};

export default SavedFilters;
