import { Button, ContextAlertMark, Input } from '@adtech/ui';
import validationMessages from '@constants/validationMessages';
import { formUtils } from '@utils/index';
import React, { ChangeEvent, useState } from 'react';

import s from './MultiInputData.pcss';

interface IProps {
    name: string;
    button: string;
    placeholder: string;
    onButtonClick: (value: string) => void;
}

const InputField: React.FC<IProps> = ({ name, button, placeholder, onButtonClick }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState<string>(null);

    const clickHandler = () => {
        let isValid: boolean | void = false;

        isValid = formUtils.rules.required(inputValue) || setInputError(validationMessages.required);

        if (!isValid) return;

        if (name.includes('whitelistedUrls')) {
            isValid =
                (formUtils.rules.urlWithDomainZone(inputValue) &&
                    formUtils.rules.urlWithProtocolAndParams(inputValue)) ||
                setInputError(validationMessages.urlWithDomainZone);
        } else {
            isValid = formUtils.rules.url(inputValue) || setInputError(validationMessages.url);
        }

        if (!isValid) return;

        onButtonClick(inputValue);
        setInputValue('');
        setInputError(null);
    };

    const keyPressHandler = (e) => {
        if (e.key === 'Enter') clickHandler();
    };

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <>
            <div className={s.inlineGroup}>
                <Input
                    value={inputValue}
                    onChange={changeHandler}
                    autoComplete="off"
                    className={s.input}
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    onKeyPress={keyPressHandler}
                    status={inputError ? 'error' : null}
                />
                <Button onClick={clickHandler} className={s.button}>
                    {button}
                </Button>
            </div>
            {inputError && <ContextAlertMark status="error" message={inputError || ''} />}
        </>
    );
};

export default InputField;
