import { Button } from '@adtech/ui';
import Page from '@components/Page';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { IRootSlice } from '@typings/rootSlice';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import s from './CreateCounter.pcss';
import FirstStep from './FirstStep';
import RestoreCounterStep from './RestoreCounterStep';
import SecondStep from './SecondStep';
import { Header } from './components/Header';

const CreateCounter: React.FC = () => {
    const dispatch = useAppDispatch();
    const createdCounter = useAppSelector((state: IRootSlice) => state.projectsSlice.createdCounter);
    const projectsWithSameUrl = useAppSelector((state: IRootSlice) => state.projectsSlice.projectsWithSameUrl);

    const [step, setStep] = useState(1);

    const counterId = (createdCounter?.project_id as number) || null;
    const projectsWithSameUrlExist = projectsWithSameUrl?.total;

    useEffect(() => {
        dispatch(projectsActions.clearCreatedCounter());
        dispatch(globalActions.setGlobalLoading(false));

        dispatch(globalActions.getRegionsConfig());
        dispatch(globalActions.getTypesConfig());
        dispatch(globalActions.getThemesConfig());
    }, []);

    const getPageTitle = () => {
        if (step === 3) return 'Восстановление доступа к счётчику';

        const counter = counterId ? `${counterId} ` : '';

        return `Создание нового счётчика ${counter}- Статистика от SberAds`;
    };

    const nextStep = (stepValue: number) => {
        window.scrollTo({ top: 0 });
        setStep(stepValue);
    };

    return (
        <DocumentTitle title={getPageTitle()}>
            <Page rowClass={s.createCounterRow}>
                {__DEV__ && (
                    <div style={{ position: 'absolute', right: '100px', zIndex: '1' }}>
                        <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={() => nextStep(1)}>
                            1
                        </Button>
                        <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={() => nextStep(2)}>
                            2
                        </Button>
                        <Button style={{ marginRight: '10px', display: 'inline-block' }} onClick={() => nextStep(3)}>
                            3
                        </Button>
                    </div>
                )}
                <Header step={step} counterId={counterId} />
                <div className={s.content}>
                    {step === 1 && (
                        <FirstStep
                            nextStep={() => nextStep(2)}
                            goToRestoreCounter={() => nextStep(3)}
                            projectsWithSameUrlExist={!!projectsWithSameUrlExist}
                        />
                    )}
                    {step === 2 && <SecondStep />}
                    {step === 3 && <RestoreCounterStep goToCreateCounter={() => nextStep(1)} />}
                </div>
            </Page>
        </DocumentTitle>
    );
};

export default CreateCounter;
