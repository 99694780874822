import dashboardConfig from '@configs/dashboard';
import { filtersCnf } from '@configs/filters/filters';
import mediaConfig from '@configs/media';
import reportsConfig from '@configs/reports';
import { IMediaRequestParams, IUpdateMediaParams, ReportNameMedia } from '@typings/media';
import { IReportRequestParams, IUpdateReportParams, ReportNameBase } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { ISort } from '@typings/table';
import DateUtils from '@utils/date';
import { graphUtils } from '@utils/index';
import reportsUtilsFunc from '@utils/reports';
import tableUtilsFunc from '@utils/table';
import { useSelector } from 'react-redux';

export const useRequestParams = () => {
    const reportsSlice = useSelector((state: IRootSlice) => state.reportsSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig,
    });

    const tableUtils = tableUtilsFunc({
        reportsConfig,
    });

    const api = {
        /**
         * Получение параметров для запроса
         */
        getParamsForRequest(
            reportName: ReportNameBase,
            type: string,
            newParams: IUpdateReportParams,
        ): IReportRequestParams {
            const {
                defaultSort,
                defaultGraphsGroup,
                disabledSortBy,
                sampleDefault,
                availableTools: { activeSample = false },
            } = reportsUtils.getConfig(reportName);
            const isRating = reportsUtils.isRating(reportName);
            const filteredParams = reportsUtils.filterParams({ ...newParams });
            const sortParams = tableUtils.checkSort(
                reportName,
                filteredParams.orderBy,
                defaultSort,
                disabledSortBy,
                filteredParams.metrics,
            ) as ISort;
            const { globalFilters } = newParams;
            const dimensionFilters = [];
            const metricFilters = [];

            const resultParams: IReportRequestParams = {
                split: filteredParams.split || '',
                date_start: filteredParams.dateStart,
                date_end: filteredParams.dateEnd,
                group: filteredParams.groupBy,
                metrics: filteredParams.metrics,
                sort: sortParams,
                ...(filteredParams.offset ? { offset: filteredParams.offset } : {}),
                ...(filteredParams.limit ? { limit: filteredParams.limit } : {}),
                ...(activeSample && filteredParams.sample ? { sample: filteredParams.sample } : {}),
            };

            if (isRating) {
                // Если рейтинг, то добавляем 'position' к запросу
                if (resultParams?.metrics && !resultParams?.metrics.includes('position')) {
                    resultParams.metrics = ['position', ...resultParams.metrics];
                }

                resultParams.category_id = filteredParams.categoryId;
            }

            resultParams.dimensions = filteredParams.dimensions;
            resultParams.sample = filteredParams.sample || sampleDefault;

            if (globalFilters?.length) {
                globalFilters.forEach((globalFilter) => {
                    // Убираем всю лишнюю инфу, которая тянется из стора вместе с фильтром
                    const filter = {
                        key: globalFilter.key,
                        op: globalFilter.op,
                        value: globalFilter.value,
                    };

                    if (filtersCnf[globalFilter.key]?.isMetricFilter) {
                        metricFilters.push(filter);
                    } else {
                        dimensionFilters.push(filter);
                    }
                });

                if (dimensionFilters.length) resultParams.dimension_filters = dimensionFilters;
                if (metricFilters.length) resultParams.metric_filters = metricFilters;
            }

            switch (type) {
                case 'all':
                case 'table': {
                    if (filteredParams.tableFilters && filteredParams.tableFilters.length) {
                        resultParams.metric_filters = filteredParams.tableFilters;
                    }

                    // строковой поиск в таблице
                    if (newParams.titleFilter) {
                        if (isRating) {
                            resultParams.search = `%${newParams.titleFilter.replace('', '%%')}%`;
                        } else {
                            resultParams.dimension_filters = [
                                { key: 'title', op: 'ilike', value: `%${newParams.titleFilter}%` },
                                ...(resultParams.dimension_filters || []),
                            ];
                        }
                    }
                    break;
                }
                case 'graph': {
                    resultParams.metrics = graphUtils.getMetrics(filteredParams.graphs);
                    resultParams.selected_lines = filteredParams.selectedLines.map((line) => line.id);

                    resultParams.need_all_total = true;

                    if (defaultGraphsGroup) {
                        resultParams.group = defaultGraphsGroup;
                    }
                    break;
                }
                case 'tableWithGraph': {
                    resultParams.metric_filters = filteredParams.tableFilters;
                    break;
                }
                case 'tableConstructor': {
                    if (filteredParams.tableFilters && filteredParams.tableFilters.length) {
                        resultParams.metric_filters = filteredParams.tableFilters;
                    }
                    break;
                }
                default:
                    break;
            }

            return resultParams;
        },

        /**
         * Параметры из стора + новые параметры
         */
        getStoreParams: (newParams?: IUpdateReportParams): IUpdateReportParams => ({
            ...reportsSlice,
            ...tableSlice,
            ...graphSlice,
            ...globalSlice,
            ...newParams,
        }),
    };

    return {
        api,
        reportsUtils,
        tableUtils,
    };
};

export const useRequestParamsDashboard = () => {
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: dashboardConfig,
    });

    const api = {
        /**
         * Получение параметров для запроса
         */
        getParamsForRequest(newParams: IUpdateReportParams): Partial<IReportRequestParams> {
            const dateStart = newParams?.dateStart || globalSlice.dateStart;
            const dateEnd = newParams?.dateEnd || globalSlice.dateEnd;
            const groupBy = newParams?.groupBy || globalSlice.groupBy;
            const isToday = DateUtils.isPeriodToday(dateStart, dateEnd);
            const periodDetail = newParams?.periodDetail || globalSlice.periodDetail;
            const [start, end] = isToday ? DateUtils.getDatesByPeriodDetail(periodDetail, globalSlice.timeOffset) : [];
            const sample = isToday ? 1 : newParams?.sample || tableSlice.sample;

            const resultParams: Partial<IReportRequestParams> = {
                date_start: dateStart,
                date_end: dateEnd,
                group: groupBy,
                ...(sample ? { sample } : {}),
            };

            const filtersGlobal = newParams?.globalFilters || globalSlice.globalFilters;
            const dimensionFilters = [];
            const metricFilters = [];

            if (filtersGlobal?.length) {
                filtersGlobal.forEach((globalFilter) => {
                    // Убираем всю лишнюю инфу, которая тянется из стора вместе с фильтром
                    const filter = {
                        key: globalFilter.key,
                        op: globalFilter.op,
                        value: globalFilter.value,
                    };

                    if (filtersCnf[globalFilter.key]?.isMetricFilter) {
                        metricFilters.push(filter);
                    } else {
                        dimensionFilters.push(filter);
                    }
                });

                if (dimensionFilters.length) resultParams.dimension_filters = dimensionFilters;
                if (metricFilters.length) resultParams.metric_filters = metricFilters;
            }

            // Формируем фильтр по periodDetail
            if (start && end) {
                resultParams.dimension_filters = [
                    ...(resultParams.dimension_filters || []),
                    { key: groupBy, op: 'range', value: [start, end] },
                ];
            }

            return resultParams;
        },
    };

    return {
        api,
        reportsUtils,
    };
};

export const useRequestParamsMedia = () => {
    const mediaSlice = useSelector((state: IRootSlice) => state.mediaSlice);
    const tableSlice = useSelector((state: IRootSlice) => state.tableSlice);
    const graphSlice = useSelector((state: IRootSlice) => state.graphSlice);
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);

    const reportsUtils = reportsUtilsFunc({
        reportsConfig: mediaConfig,
    });

    const tableUtils = tableUtilsFunc({
        reportsConfig: mediaConfig,
    });

    const api = {
        /**
         * Получение параметров для запроса
         */
        getParamsForRequest(
            reportName: ReportNameMedia,
            newParams: IUpdateMediaParams,
            type: string = 'all',
        ): IMediaRequestParams {
            const { defaultSort, disabledSortBy, defaultGraphsGroup } = reportsUtils.getConfig(reportName);
            const filteredParams = reportsUtils.filterParams({ ...newParams });
            const sortParams =
                defaultSort &&
                tableUtils.checkSort(
                    reportName,
                    filteredParams.orderBy,
                    defaultSort,
                    disabledSortBy,
                    filteredParams.metrics,
                );
            const resultParams: IMediaRequestParams = {
                date_start: filteredParams.dateStart,
                date_end: filteredParams.dateEnd,
                group: filteredParams.groupBy,
                metrics: filteredParams.metrics,
                dimensions: filteredParams.dimensions,
                sort: sortParams,
                sample: tableSlice.sample || 1,
            };

            const { tableFilters, mediaId } = filteredParams;
            const { globalFilters, titleFilter } = newParams;
            const dimensionFilters = [];
            const metricFilters = [];

            if (globalFilters?.length) {
                globalFilters.forEach((globalFilter) => {
                    // Убираем всю лишнюю инфу, которая тянется из стора вместе с фильтром
                    const filter = {
                        key: globalFilter.key,
                        op: globalFilter.op,
                        value: globalFilter.value,
                    };

                    if (filtersCnf[globalFilter.key]?.isMetricFilter) {
                        metricFilters.push(filter);
                    } else {
                        dimensionFilters.push(filter);
                    }
                });

                if (dimensionFilters.length) resultParams.dimension_filters = dimensionFilters;
                if (metricFilters.length) resultParams.metric_filters = metricFilters;
            }

            if (mediaId) {
                resultParams[reportsUtils.getParamFromConfig(reportName, 'mediaIdParamReq', 'media_id')] = mediaId;
            }

            switch (type) {
                case 'all':
                case 'table': {
                    // Табличные фильтры
                    if (tableFilters && tableFilters.length) {
                        // Убираем дубли, так как табличные
                        // и общие фильтры по метрикам могут пересекаться
                        tableFilters.filter((filter) => !metricFilters.some((item) => item.key === filter.key));
                        resultParams.table_filters = [...metricFilters, ...tableFilters];
                    }

                    // Строковой поиск в таблице
                    if (titleFilter) {
                        const key = reportsUtils.getParamFromConfig(reportName, 'tableTitleDimension');
                        resultParams.dimension_filters = [
                            { key, op: 'ilike', value: `%${titleFilter}%` },
                            ...(resultParams.dimension_filters || []),
                        ];
                    }
                    break;
                }
                case 'graph': {
                    resultParams.metrics = graphUtils.getMetrics(filteredParams.graphs);
                    resultParams.selected_lines = filteredParams.selectedLines.map((line) => line.id);
                    resultParams.need_all_total = true;
                    if (defaultGraphsGroup) resultParams.group = defaultGraphsGroup;

                    break;
                }
                default:
                    break;
            }

            return resultParams;
        },

        /**
         * Параметры из стора + новые параметры
         */
        getStoreParams: (newParams: IUpdateMediaParams): IUpdateMediaParams => ({
            ...mediaSlice,
            ...tableSlice,
            ...graphSlice,
            ...globalSlice,
            ...newParams,
        }),
    };

    return {
        api,
        reportsUtils,
        tableUtils,
    };
};
