import { Alert, Input } from '@adtech/ui';
import { Hint } from '@components/Hint';
import validationMessages from '@constants/validationMessages';
import { formUtils } from '@utils/index';
import cn from 'classnames';
import React, { ReactNode } from 'react';
import { ControllerRenderProps, FieldError, FieldValues, useController, useFormContext } from 'react-hook-form';

import s from './InputData.pcss';

interface IInputProps {
    disabled: boolean;
    maxTitleLength?: number;
    isShowMaxLength?: boolean;
    placeholder?: string;
    label?: string;
    message?: ReactNode | string;
    showHint?: boolean;
    hint?: string;
}

interface IInputPropsWithField extends IInputProps {
    field: ControllerRenderProps<FieldValues, any>;
    error: FieldError;
}

export const InputComponent: React.FC<IInputPropsWithField> = ({
    field,
    error,
    label,
    maxTitleLength = 0,
    isShowMaxLength = true,
    disabled = false,
    placeholder = '',
    message = '',
    showHint = false,
    hint = '',
}) => {
    const { value, name, onChange, onBlur, ref } = field;

    return (
        <div className={cn(s.inputWrapper, { [s.disabled]: disabled })}>
            <div className={s.label}>{label}</div>
            {showHint && <Hint className={s.formGroupHint}>{hint}</Hint>}
            <Input
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                placeholder={placeholder}
                type="text"
                status={error ? 'error' : null}
                errorText={error ? error.message : ''}
                maxLength={isShowMaxLength ? maxTitleLength : null}
                inputRef={ref}
            />
            {message && <Alert type="info" message={message} showIcon className={s.alert} />}
        </div>
    );
};

export const TitleField: React.FC<IInputProps> = (props) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'title',
        control,
        rules: {
            required: {
                value: true,
                message: 'Обязательное поле',
            },
            minLength: {
                value: 3,
                message: 'Минимум 3 символа',
            },
            maxLength: {
                value: props.maxTitleLength,
                message: 'Превышен лимит символов',
            },
        },
    });

    return <InputComponent {...props} field={field} error={error} label="Название сайта" />;
};

const UrlField: React.FC<IInputProps> = (props) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'url',
        control,
        rules: {
            required: {
                value: true,
                message: 'Обязательное поле',
            },
            maxLength: {
                value: 200,
                message: 'URL не должен превышать 200 символов',
            },
            validate: (val) =>
                (formUtils.rules.urlWithDomainZone(val) && formUtils.rules.urlWithProtocolAndParams(val)) ||
                validationMessages.urlWithDomainZone,
        },
    });

    return <InputComponent {...props} field={field} error={error} label="Адрес сайта" />;
};

interface IProps {
    maxTitleLength: number;
    messageUrl?: ReactNode | string;
    messageTitle?: ReactNode | string;
    disabledUrl?: boolean;
    disabledTitle?: boolean;
    isShowMaxLength?: boolean;
}

const RootCom: React.FC<IProps> = ({
    messageUrl,
    messageTitle,
    disabledUrl,
    disabledTitle,
    isShowMaxLength,
    maxTitleLength,
}) => (
    <>
        <UrlField disabled={disabledUrl} message={messageUrl} placeholder="http://" isShowMaxLength={isShowMaxLength} />
        <TitleField
            disabled={disabledTitle}
            isShowMaxLength={isShowMaxLength}
            maxTitleLength={maxTitleLength}
            message={messageTitle}
        />
    </>
);

export default RootCom;
