import { Button, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import { projectsStatus } from '@constants/projects';
import serverErrorsConstants from '@constants/serverErrors';
import snackbarsConstants from '@constants/snackbars';
import useSnackbar from '@hooks/useSnackbar';
import { useAppSelector, useAppDispatch } from '@hooks/useStore';
import { projectsActions } from '@redux/slices/projects';
import { listDataUtils } from '@utils/index';
import React, { useState } from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import s from '../SettingsCounter.pcss';

interface IFormFields {
    title: string;
    inRating: boolean;
    keywords: string;
    publicStats: string;
    categories: number[];
    regions: number[];
    types: number[];
}

const RatingForm: React.FC = () => {
    const currentProject = useAppSelector((state) => state.projectsSlice.current);
    const saveCounterRequest = useAppSelector((state) => state.projectsSlice.saveCounterRequest);
    const projectConfig = useAppSelector((state) => state.globalSlice.projectConfig);
    const dispatch = useAppDispatch();
    const { setSnackbar } = useSnackbar();
    const [serverError, setServerError] = useState<string>(null);

    let categories = listDataUtils.matchingConfigItems(currentProject.categories, projectConfig.categories);
    categories = listDataUtils.getThemeIdsFromCategories(categories, projectConfig.categories.data);
    categories = listDataUtils.getSimpleCategories(categories, projectConfig.categories.data) || [];
    const regions = listDataUtils.matchingConfigItems(currentProject.regions, projectConfig.regions) || [];
    const types = listDataUtils.matchingConfigItems(currentProject.types, projectConfig.types) || [];

    const formMethods = useForm<IFormFields>({
        defaultValues: {
            title: '',
            inRating: false,
            keywords: '',
            publicStats: 'closed',
            categories: [],
            regions: [],
            types: [],
        },
        values: {
            title: currentProject.title,
            inRating: currentProject.in_rating,
            keywords: currentProject.keywords,
            publicStats: currentProject.publicStats ? 'opened' : 'closed',
            categories,
            regions,
            types,
        },
    });

    const { watch, handleSubmit } = formMethods;

    const inRating = watch('inRating');
    const disableEdit = [projectsStatus.EDITING, projectsStatus.NEED_CHANGE, projectsStatus.DROPPED_OUT].includes(
        currentProject.status,
    );

    const showSaveButton =
        (currentProject.status !== projectsStatus.UNINCLUDED || inRating) &&
        currentProject.status !== projectsStatus.EXCLUDED;

    const saveCounter = (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data }));

    const saveSettingsCounter: SubmitHandler<IFormFields> = (values) => {
        let data = {
            categories: values.categories
                ? listDataUtils.getThemeIdsFromCategories(values.categories, projectConfig.categories.data)
                : [],
            regions: values.regions || [],
            types: values.types || [],
            in_rating: values.inRating,
            keywords: values.keywords,
            public_stats: values.publicStats === 'opened',
            title: values.title,
        };

        if (!values.inRating) {
            data = {
                categories: [],
                types: [],
                regions: [],
                in_rating: false,
                public_stats: false,
                title: values.title,
                keywords: values.keywords,
            };
        }

        saveCounter(currentProject.id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                setSnackbar(snackbarsConstants.SAVE_CHANGES);
                window.scrollTo(0, 0);
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                }
            }
        });
    };

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(saveSettingsCounter)}>
                <div className={s.block}>
                    <CounterBlocks.Rating
                        config={projectConfig}
                        inRating={inRating}
                        canRating={currentProject.can_be_in_rate}
                        projectStatus={currentProject.status}
                        disableEdit={disableEdit}
                        withoutDelimiter
                        mode="settings"
                        ratingBlocker={currentProject.blacklisted || currentProject.ununique}
                    />
                </div>
                {showSaveButton && (
                    <div className={s.actions}>
                        <Button loading={saveCounterRequest} disabled={saveCounterRequest} htmlType="submit">
                            Сохранить изменения
                        </Button>
                        {serverError && <ContextAlertMark message={serverError} status="error" />}
                    </div>
                )}
            </Form>
        </FormProvider>
    );
};

export default RatingForm;
