import { Checkbox, ContextAlertMark } from '@adtech/ui';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import s from '../../FirstStep.pcss';

const Terms: React.FC = () => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error },
    } = useController({
        name: 'agree',
        control,
        rules: {
            validate: (val) => val === true || 'Вы должны принять условия',
        },
    });

    const { onChange, value, name } = field;

    return (
        <div className={s.termsAcceptedRow}>
            <div className={s.termsAccepted}>
                <Checkbox name={name} checked={value} onChange={() => onChange(!value)}>
                    Я принимаю условия
                </Checkbox>
                <a href="https://ads.sber.ru/legal/termsofuse_stat" target="_blank" rel="noopener noreferrer">
                    Пользовательского соглашения
                </a>
            </div>
            {error && <ContextAlertMark message="Вы должны принять условия" status="error" />}
        </div>
    );
};

export default Terms;
