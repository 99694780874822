import { Alert, Tabs } from '@adtech/ui';
import { Hint } from '@components/Hint';
import useCopy from '@hooks/useCopy';
import { useAppDispatch } from '@hooks/useStore';
import IconLink from '@images/svg/icons/icon-external-link.svg';
import { postClickActions } from '@redux/slices/postClick';
import { IRootSlice } from '@typings/rootSlice';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import s from '../../PostClick.pcss';
import CounterCreate from './CounterCreate';
import CounterCreating from './CounterCreating';
import CounterTokenAccess from './CounterTokenAccess';
import CounterYandexAccess from './CounterYandexAccess';

const CounterAbsent: FC = () => {
    const dispatch = useAppDispatch();
    const { counterRequest, firstRequest, counterRequestError } = useSelector(
        (state: IRootSlice) => state.postClickSlice,
    );

    const [yaCounterExist, setYaCounterExist] = useState(true);
    const [accessType, setAccessType] = useState('yandex_id');

    const counterExistTabs = [
        {
            value: 'exist',
            label: 'Есть счётчик',
        },
        {
            value: 'not_exist',
            label: 'Нет счётчика',
        },
    ];
    const accessTypeTabs = [
        {
            value: 'yandex_id',
            label: 'Доступ по Яндекс ID',
        },
        {
            value: 'token',
            label: 'Доступ по токену',
        },
    ];

    const { copy } = useCopy();

    const onChangeTabs = () => {
        setYaCounterExist(!yaCounterExist);
        setAccessType('yandex_id');
        dispatch(postClickActions.resetCounterError());
    };

    const copyHandler = (email: string): void => {
        copy(email, 'Скопировано');
    };

    const onChangeTabsAccess = (type: string) => {
        setAccessType(type);
    };

    return (
        <>
            <Tabs className={s.tabs} type="labeled" onChange={onChangeTabs} options={counterExistTabs} />
            {yaCounterExist ? (
                <div className={s.access}>
                    <Alert
                        type="info"
                        message={
                            <p className={s.accessAlert}>
                                Синхронизируйте счётчик Статистики с Яндекс Директ. Для этого предоставьте счётчику
                                Статистики доступ к данным Яндекс Метрики.&nbsp;
                                <a
                                    href="https://ads.sber.ru/help/peredacha-celevykh-sobytii-v-yandeks-direkt"
                                    target="_blank"
                                    rel="noopener"
                                    className={s.hintLink}
                                >
                                    <span>Подробнее в документации</span>
                                    <IconLink />
                                </a>
                            </p>
                        }
                        showIcon
                        className={s.alert}
                    />
                    <span className={s.accessSubtitle}>Доступ к счётчику Яндекс Метрики</span>
                    <div className={s.accessHint}>
                        <span>
                            Авторизуйтесь удобным способом: через доступ по Яндекс ID Статистики или через доступ по
                            токену
                        </span>
                        <Hint className={s.hint}>
                            <span className={s.hintText}>
                                <p>
                                    <b>Доступ по&nbsp;Яндекс ID:</b>
                                </p>
                                <p>
                                    В&nbsp;интерфейсе Метрики предоставьте доступ аккаунту Статистики (
                                    <span onClick={() => copyHandler('StatSberAds@yandex.ru')} className={s.hintEmail}>
                                        StatSberAds@yandex.ru
                                    </span>
                                    ) с&nbsp;правами «Редактирование».
                                </p>
                                <br />
                                <p>
                                    Доступ по&nbsp;токену: Введите токен и&nbsp;номер счётчика в&nbsp;соответствующие
                                    поля.
                                </p>
                                <br />
                                <a
                                    href="https://ads.sber.ru/help/peredacha-celevykh-sobytii-v-yandeks-direkt"
                                    target="_blank"
                                    rel="noopener"
                                    className={s.hintLink}
                                >
                                    <span>Подробнее в документации</span>
                                    <IconLink />
                                </a>
                            </span>
                        </Hint>
                    </div>

                    <Tabs className={s.tabs} type="labeled" onChange={onChangeTabsAccess} options={accessTypeTabs} />
                    {accessType === 'yandex_id' ? <CounterYandexAccess /> : <CounterTokenAccess />}
                    {!firstRequest && counterRequest && <CounterCreating isSync />}
                    {!counterRequest && counterRequestError ? (
                        <Alert
                            type="error"
                            message={
                                <p className={s.accessAlert}>
                                    Нет доступа к&nbsp;счётчику Яндекс Метрики. Для получения доступа ознакомьтесь с
                                    <a
                                        href="https://ads.sber.ru/help/peredacha-celevykh-sobytii-v-yandeks-direkt"
                                        target="_blank"
                                        rel="noopener"
                                        className={s.hintLink}
                                    >
                                        <span>документацией</span>
                                        <IconLink />
                                    </a>
                                </p>
                            }
                            showIcon
                            className={s.alertError}
                        />
                    ) : null}
                </div>
            ) : (
                <div>{counterRequest ? <CounterCreating /> : <CounterCreate />}</div>
            )}
        </>
    );
};

export default CounterAbsent;
