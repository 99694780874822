import { debounce } from '@adtech/ui';
import { Select } from '@sberads/ui';
import React, { useCallback, useEffect, useRef } from 'react';

import FilterFormHeader from '../components/FilterFormHeader';
import {
    IDynamicFilterItem,
    IFilterRuleFormValue,
    IFiltersCnfItem,
    TAllFormValues,
    TDimension,
} from '../types/filters';
import s from './Checkbox.pcss';

interface IProps {
    filterCnf: IFiltersCnfItem;
    currentKey: string;
    allFormsValues: TAllFormValues;
    setAllFormsValues: (formsValues: TAllFormValues) => void;
    ruleFormValue: IFilterRuleFormValue;
    curFilterFormsValues: IFilterRuleFormValue[];
    ruleIndex: number;
    dynamicFilterData?: IDynamicFilterItem;
    getDynamicFilterData?: (dimension: TDimension) => Promise<unknown>;
}

const CheckboxFilter: React.FC<IProps> = ({
    filterCnf,
    currentKey,
    allFormsValues,
    setAllFormsValues,
    ruleFormValue,
    curFilterFormsValues,
    ruleIndex,
    dynamicFilterData,
    getDynamicFilterData,
}) => {
    const selectRef = useRef(null);
    const dropdownRef = useRef(null);
    const SELECT_ITEM_HEIGHT = 48;
    const isDynamicFilter = filterCnf.isDynamic;
    let selectItems;

    if (isDynamicFilter) {
        selectItems = dynamicFilterData?.data;
    } else {
        selectItems = filterCnf.data?.map((item) => ({ ...item, key: item.value }));
    }

    const selectScrollHandler = (e) => {
        const { scrollTop, clientHeight } = e.target;
        const itemsCount = dynamicFilterData?.data?.length || 0;
        const limit = (itemsCount - 5) * SELECT_ITEM_HEIGHT - clientHeight;

        if (scrollTop > limit && !dynamicFilterData?.loading) {
            getDynamicFilterData(filterCnf?.dynamicFilterAlias || currentKey).then(() => {
                e.target.scrollTop = scrollTop;
            });
        }
    };

    const debouncedScrollHandler = debounce(selectScrollHandler, 300);

    // Прокидываем callbackref чтобы отслеживать присоединение/удаление ноды
    const dropdownRefCallback = useCallback(
        (node) => {
            if (!node) {
                dropdownRef.current?.removeEventListener('scroll', debouncedScrollHandler);
                return;
            }

            dropdownRef.current = node;

            node.removeEventListener('scroll', debouncedScrollHandler);
            node.addEventListener('scroll', debouncedScrollHandler);
        },
        [currentKey, dynamicFilterData?.data?.length],
    );

    // Запрашиваем данные по динамическому фильтру
    useEffect(() => {
        if (dynamicFilterData?.data?.length || !isDynamicFilter) return;

        getDynamicFilterData?.(filterCnf?.dynamicFilterAlias || currentKey);
    }, [currentKey]);

    useEffect(() => {
        if (!ruleFormValue.value) selectRef.current.reset();
    }, [ruleFormValue.value]);

    const onChangeSelectHandler = (value: string[] | null) => {
        const newFormVal = [...curFilterFormsValues];
        newFormVal[ruleIndex] = { ...newFormVal[ruleIndex], value };
        setAllFormsValues({ ...allFormsValues, [currentKey]: newFormVal });
    };

    const clearFilter = () => onChangeSelectHandler(null);

    return (
        <div key={`${currentKey}${ruleIndex}`} className={s.checkbox}>
            <FilterFormHeader
                label={filterCnf?.label}
                hint={filterCnf?.hint}
                clearFilter={ruleFormValue.value?.length ? clearFilter : null}
            />
            <Select
                loading={isDynamicFilter ? dynamicFilterData?.loading : false}
                placeholder="Выберите значение из списка"
                mode="multiple"
                options={selectItems || []}
                defaultValue={ruleFormValue.value || ''}
                onSelect={onChangeSelectHandler}
                optionsType="checkbox"
                selectRef={selectRef}
                dropdownRef={dropdownRefCallback}
            />
        </div>
    );
};

export default CheckboxFilter;
