import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';
import unset from 'lodash/unset';

class ListDataUtils {
    constructor() {
        return Object.freeze({
            toFlatConfigData: this.toFlatConfigData,
            matchingConfigItems: this.matchingConfigItems,
            getIndeterminate: this.getIndeterminate,
            getThemeIdsFromCategories: this.getThemeIdsFromCategories,
            getLians: this.getLians,
            getSimpleCategories: this.getSimpleCategories,
            getParentIdsByIds: this.getParentIdsByIds,
            filterOnlySubItems: this.filterOnlySubItems,
            __private: {},
        });
    }

    // TODO: Описать типы для методов ListDataUtils
    // public

    /**
     * Приводим данные к проскому виду
     */
    toFlatConfigData = (originData) => {
        const map = {};
        let result = [];
        let mode = 'three';

        // функция разложения вложенностей на плоский уровень
        const flat = (currentData, curNesting = 0) => {
            currentData.forEach((el) => {
                if (el.children && el.children.length) {
                    flat(el.children, curNesting + 1);
                }

                const element = {
                    parent_id: 0,
                    with_parents_id: null,
                    main: curNesting === 0,
                    nesting: curNesting,
                    ...el,
                };

                if (element.parentId) {
                    element.parent_id = String(element.parentId);
                    unset(element, 'parentId');
                }

                if (element.canBeExpanded) {
                    element.can_be_expanded = element.canBeExpanded;
                    unset(element, 'canBeExpanded');
                }

                if (element.withParentsId) {
                    element.with_parents_id = String(element.withParentsId);
                    unset(element, 'withParentsId');
                }

                if (element.theme_id) {
                    element.theme_id = String(element.theme_id);
                }

                element.id = String(element.id);

                if (element.parent_id) {
                    element.parent_id = String(element.parent_id);
                }

                map[element.id] = element;

                result.push(element);
            });
        };

        // запускаем операцию
        flat(originData);

        // пробегаемся по плоскому списку и выявляем main
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        result = result.map(({ children, ...other }) => ({ ...other }));
        mode = result.find((item) => item.parent_id) ? 'three' : 'flat';

        // собираем тоталы в объект по уровням
        const parentIds = uniq(Object.keys(map).map((id) => map[id].parent_id));
        const totals = {};
        parentIds.forEach((id) => {
            totals[id] = result.filter((item) => item.parent_id === id).length;
        });

        return { data: result, mode, map, totals };
    };

    /**
     * Делаем массив из id доступных элементов
     * и оставляем только выбранных детей
     */
    matchingConfigItems = (originData, config) => {
        const flatData = this.toFlatConfigData(originData);
        const data = flatData?.data || [];

        let result = data
            .map((item) => {
                const resItem = config.data?.map[item.id];

                if (!resItem) return false;

                return resItem.id;
            })
            .filter((item) => item !== false);

        result = result.filter((id) => {
            const resItem = config.data.map[id];

            return resItem && !this.getIndeterminate(resItem, result, config.data.data);
        });

        return result;
    };

    /**
     * Проверяем, есть ли выбранные дети
     */
    getIndeterminate = (item, selectedValues, data) => {
        const checked = selectedValues.includes(item.id);

        const subItems = data.filter((itm) => itm.parent_id === item.id || itm.parent_id === item.with_parents_id);
        const subChecked = subItems.filter((subItem) => selectedValues.includes(subItem.id)).length > 0;

        return checked && subItems && subChecked;
    };

    /**
     * Получаем theme_id элементов по их id
     */
    getThemeIdsFromCategories = (categories, config) => {
        if (!config) return [];

        const { map } = config;

        return uniq(
            categories.map((id) => (map[id].id === map[id].theme_id ? String(map[id].id) : String(map[id].theme_id))),
        );
    };

    /**
     * Получаем лианы элемента
     */
    getLians = (item, data) =>
        data.filter((itm) => item.theme_id && itm.theme_id === item.theme_id && itm.id !== item.id).map((i) => i.id);

    /**
     * Преобразуем полученные категории к пригодному виду
     * 1 - получаем лианы
     * 2 - удаляем родителей, если есть выбранные дети
     */
    getSimpleCategories = (categories, config) => {
        if (!config) return [];

        //  получаем лианы
        let result = flattenDeep(
            categories.map((id) => {
                const item = config.map[id];
                const data = config.data;
                const lians = this.getLians(item, data);

                return [id, ...lians];
            }),
        );

        // фильтруем категории - удаляем родителя, если есть выбранные дети
        result = this.filterOnlySubItems(result, config.map);

        // только уникальные значения
        result = uniq(result);

        return result;
    };

    /**
     * Удаляем родителей, оставляем только детей
     */
    filterOnlySubItems = (result, map) =>
        result.filter((mainId) => {
            const mainItem = map[mainId];

            const isSubChecked =
                result.filter((subId) => {
                    const subItem = map[subId];
                    return subItem.parent_id === mainId;
                }).length > 0;

            return (!mainItem.parent_id && !isSubChecked) || !!mainItem.parent_id;
        });

    /**
     * Получение родителей детей, если они есть
     */
    getParentIdsByIds = (ids, map, recursion = true) =>
        uniq(
            flattenDeep(
                ids?.map((id) => {
                    const item = map[id];
                    const parentId = item ? item.parent_id : 0;

                    if (!parentId) {
                        return [];
                    }

                    const parentIds = recursion ? this.getParentIdsByIds([parentId], map) : [];

                    return [parentId, ...parentIds];
                }),
            ),
        );
}

export default new ListDataUtils();
