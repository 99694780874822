import {
    IconAdSystems,
    IconAge,
    IconAttendance,
    IconAuthorsReports,
    IconBehavior,
    IconBrowser,
    IconConstructor,
    IconContent,
    IconConversions,
    IconDashboard,
    IconDataExport,
    IconDevices,
    IconExchangeNetworks,
    IconExitPage,
    IconFunnels,
    IconGeography,
    IconGoalReports,
    IconInterests,
    IconLoginPage,
    IconMediaReports,
    IconMessengers,
    IconNewsAggregators,
    IconOpenstatParams,
    IconOS,
    IconOwnEvents,
    IconPageFormat,
    IconQRCode,
    IconRamblerLikes,
    IconRating,
    IconRecommendSystems,
    IconReferralSources,
    IconReportsInDevelopment,
    IconReports,
    IconScreen,
    IconSearchEngines,
    IconSearchPhrases,
    IconSex,
    IconSources,
    IconTargetEventsYandex,
    IconTargetEvents,
    IconTechnologies,
    IconTimeOnSite,
    IconTitle,
    IconTopMaterials,
    IconUrlParams,
    IconUtmParams,
    IconViewingDepth,
    IconAccountClients,
} from '@adtech/ui';
import {IconImportFromYm} from "@images/svg/icon-import-from-ym";
import { INavigationItem } from '@typings/reports';
import React from "react";

const reportPath = 'new/report';
const mediaPath = 'new/media';

export const navigationStateStorageKey = 'isNavigationOpen';

export const navigationConfig: Record<string, INavigationItem[]> = {
    settings: [
        {
            title: 'Счётчик',
            name: 'counter',
            path: 'settings/counter',
        },
        {
            title: 'Рейтинг Топ-100',
            name: 'rating',
            path: 'settings/rating',
        },
        {
            title: 'Настройка доступа',
            name: 'access',
            path: 'settings/access',
        },
    ],
    projects: [
        {
            title: 'Мои сайты',
            name: 'projects',
            path: '',
        },
        {
            title: 'Удалённые сайты',
            name: 'deleted',
            path: 'deleted',
        },
    ],
    report: [
        {
            title: 'Дашборд',
            name: 'dashboard',
            path: 'dashboard',
            icon: <IconDashboard />
        },
        {
            title: 'Конструктор',
            name: 'constructor',
            path: 'constructor',
            icon: <IconConstructor />
        },
        {
            title: 'Отчёты по целям',
            name: 'funnels_reports',
            icon: <IconGoalReports />,
            children: [
                {
                    title: 'Целевые события',
                    name: 'goals',
                    path: 'goals',
                    icon: <IconTargetEvents />,
                },
                {
                    title: 'Конверсии',
                    name: 'conversions',
                    path: 'conversions',
                    icon: <IconConversions />,
                },
                {
                    title: 'Воронки',
                    name: 'funnels',
                    path: 'funnels',
                    icon: <IconFunnels />,
                },
            ],
        },
        {
            title: 'Отчёты',
            name: 'base_reports',
            icon: <IconReports />,
            children: [
                {
                    title: 'Аудитория',
                    name: 'audience',
                    icon: <IconAccountClients />,
                    children: [
                        {
                            title: 'Посещаемость',
                            name: 'summary',
                            path: `${reportPath}/summary`,
                            icon: <IconAttendance />,
                        },
                        {
                            title: 'Глубина просмотра',
                            name: 'view_depth',
                            path: `${reportPath}/view_depth`,
                            icon: <IconViewingDepth />,
                        },
                        {
                            title: 'Время на сайте',
                            name: 'visit_time',
                            path: `${reportPath}/visit_time`,
                            icon: <IconTimeOnSite />,
                        },
                        {
                            title: 'География',
                            name: 'geo',
                            path: `${reportPath}/geo`,
                            icon: <IconGeography />,
                        },
                        {
                            title: 'Пол',
                            name: 'sex',
                            path: `${reportPath}/sex`,
                            icon: <IconSex />,
                        },
                        {
                            title: 'Возраст',
                            name: 'age',
                            path: `${reportPath}/age`,
                            icon: <IconAge />,
                        },
                        {
                            title: 'Интересы',
                            name: 'segments',
                            path: `${reportPath}/segments`,
                            icon: <IconInterests />,
                        },
                    ],
                },
                {
                    title: 'Технологии',
                    name: 'technology',
                    icon: <IconTechnologies />,
                    children: [
                        {
                            title: 'Устройства',
                            name: 'device',
                            path: `${reportPath}/device`,
                            icon: <IconDevices />,
                        },
                        {
                            title: 'Браузеры',
                            name: 'browser',
                            path: `${reportPath}/browser`,
                            icon: <IconBrowser />,
                        },
                        {
                            title: 'Операционные системы',
                            name: 'os',
                            path: `${reportPath}/os`,
                            icon: <IconOS />,
                        },
                        {
                            title: 'Разрешение экрана',
                            name: 'screensize',
                            path: `${reportPath}/screensize`,
                            icon: <IconScreen />,
                        },
                        {
                            title: 'Формат страниц',
                            name: 'page_formats',
                            path: `${reportPath}/page_formats`,
                            icon: <IconPageFormat />,
                        },
                    ],
                },
                {
                    title: 'Источники',
                    name: 'sources',
                    icon: <IconSources />,
                    children: [
                        {
                            title: 'Источники переходов',
                            name: 'traffic_sources',
                            path: `${reportPath}/traffic_sources`,
                            icon: <IconReferralSources />,
                        },
                        {
                            title: 'Рекламные системы',
                            name: 'ad_systems',
                            path: `${reportPath}/ad_systems`,
                            icon: <IconAdSystems />,
                        },
                        {
                            title: 'UTM Метки',
                            name: 'utms',
                            path: `${reportPath}/utms`,
                            icon: <IconUtmParams />,
                        },
                        {
                            title: 'Метки Openstat',
                            name: 'openstat',
                            path: `${reportPath}/openstat`,
                            icon: <IconOpenstatParams />,
                        },
                        {
                            name: 'sites',
                            title: 'Сайты / Referer',
                            path: `${reportPath}/sites`,
                            icon: <IconBrowser />,
                        },
                        {
                            title: 'Поисковые системы',
                            name: 'search_system_phrase',
                            path: `${reportPath}/search_system_phrase`,
                            icon: <IconSearchEngines />,
                        },
                        {
                            title: 'Поисковые фразы',
                            name: 'search_phrase_system',
                            path: `${reportPath}/search_phrase_system`,
                            icon: <IconSearchPhrases />,
                        },
                        {
                            title: 'Новостные агрегаторы',
                            name: 'aggrnews_system',
                            path: `${reportPath}/aggrnews_system`,
                            icon: <IconNewsAggregators />,
                        },
                        {
                            title: 'Обменные сети',
                            name: 'exchange_system',
                            path: `${reportPath}/exchange_system`,
                            icon: <IconExchangeNetworks />,
                        },
                        {
                            title: 'Рекомендательные системы',
                            name: 'recom_system',
                            path: `${reportPath}/recom_system`,
                            icon: <IconRecommendSystems />,
                        },
                        {
                            title: 'Социальные сети',
                            name: 'social_network',
                            path: `${reportPath}/social_network`,
                            icon: <IconAccountClients />,
                        },
                        {
                            title: 'Мессенджеры',
                            name: 'message_system',
                            path: `${reportPath}/message_system`,
                            icon: <IconMessengers />,
                        },
                        {
                            title: 'Переходы по QR-кодам',
                            name: 'qr_system',
                            path: `${reportPath}/qr_system`,
                            icon: <IconQRCode />,
                        },
                    ],
                },
                {
                    title: 'Содержание',
                    name: 'content',
                    icon: <IconContent />,
                    children: [
                        {
                            title: 'Популярное',
                            name: 'page_urls',
                            path: `${reportPath}/page_urls`,
                            icon: <IconRating />,
                        },
                        {
                            title: 'Страницы входа',
                            name: 'entrance_pages',
                            path: `${reportPath}/entrance_pages`,
                            icon: <IconLoginPage />,
                        },
                        {
                            title: 'Страницы выхода',
                            name: 'exit_pages',
                            path: `${reportPath}/exit_pages`,
                            icon: <IconExitPage />,
                        },
                        {
                            title: 'Заголовки страниц',
                            name: 'page_titles',
                            path: `${reportPath}/page_titles`,
                            icon: <IconTitle />,
                        },
                        {
                            title: 'Параметры URL',
                            name: 'page_args',
                            path: `${reportPath}/page_args`,
                            icon: <IconUrlParams />,
                        },
                    ],
                },
                {
                    title: 'Поведение',
                    name: 'behavior',
                    icon: <IconBehavior />,
                    children: [
                        {
                            title: 'Собственные события',
                            name: 'custom_vars',
                            path: `${reportPath}/custom_vars`,
                            icon: <IconOwnEvents />,
                        },
                        {
                            title: 'Аналитика блоков',
                            name: 'usability',
                            path: `${reportPath}/usability`,
                            icon: <IconMediaReports />,
                        },
                        {
                            title: 'Рамблер/лайки',
                            name: 'likes',
                            path: `${reportPath}/likes`,
                            icon: <IconRamblerLikes />,
                        },
                    ],
                },
            ],
        },
        {
            title: 'Медиаотчёты',
            name: 'media_reports',
            icon: <IconMediaReports />,
            children: [
                {
                    name: 'materials_summary',
                    title: 'Отчёт по материалам',
                    path: `${mediaPath}/materials_summary`,
                    hiddenChild: 'material', // как в url
                    icon: <IconGoalReports />,
                },
                {
                    name: 'top',
                    title: 'Топ материалов',
                    path: `${mediaPath}/top`,
                    icon: <IconTopMaterials />,
                },
                {
                    name: 'authors_summary',
                    title: 'Отчёт по авторам',
                    path: `${mediaPath}/authors_summary`,
                    hiddenChild: 'author', // как в url
                    icon: <IconAuthorsReports />,
                },
            ],
        },
        {
            title: 'Рейтинг',
            name: 'rating',
            path: `${reportPath}/rating`,
            icon: <IconRating />
        },
        {
            title: 'Интеграции',
            name: 'integrations',
            icon: <IconDataExport />,
            children: [
                {
                    title: 'Передача целевых событий в Яндекс Директ',
                    name: 'post_click',
                    path: 'post_click',
                    icon: <IconTargetEventsYandex />,
                },
                {
                    title: 'Получение событий от Яндекс Метрики',
                    name: 'import_data_from_ym',
                    path: 'import_data_from_ym',
                    icon: <IconImportFromYm />, // TODO: перенести иконку в ui-kit
                },
            ],
        },
        {
            title: 'Отчёты в разработке',
            name: 'beta_reports',
            icon: <IconReportsInDevelopment />,
            isTop100StaffOnly: true,
            children: [
                {
                    title: 'Экспорт данных',
                    name: 'export',
                    path: 'export',
                    isTop100StaffOnly: true,
                },
            ],
        },
    ],
};
