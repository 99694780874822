/**
 * Общий конфиг всех фильтров
 */
import { TFiltersFormCnf } from "@components/CommonFilters/types/filters";

export const filtersCnf = {
    view_depth: {
        key: 'view_depth',
        label: 'Глубина просмотра',
        type: 'period',
        hint: 'Количество просмотров страниц сайта в рамках одного визита с группировкой по количеству страниц',
    },
    geo_country: {
        key: 'geo_country',
        label: 'Страна',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'geo',
    },
    geo_area: {
        key: 'geo_area',
        label: 'Регион',
        type: 'checkbox',
        isDynamic: true,
    },
    geo_city: {
        key: 'geo_city',
        label: 'Город',
        type: 'checkbox',
        isDynamic: true,
    },
    gender: {
        key: 'gender',
        label: 'Пол',
        type: 'checkbox',
        data: [
            {
                value: 'male',
                label: 'Мужчины',
            },
            {
                value: 'female',
                label: 'Женщины',
            },
            {
                value: 'other',
                label: 'Неизвестен',
            },
        ],
    },
    age_tns: {
        key: 'age_tns',
        label: 'Возраст',
        type: 'checkbox',
        data: [
            {
                value: '12-18',
                label: '12-18',
            },
            {
                value: '18-25',
                label: '18-25',
            },
            {
                value: '25-35',
                label: '25-35',
            },
            {
                value: '35-45',
                label: '35-45',
            },
            {
                value: '45-55',
                label: '45-55',
            },
            {
                value: '55-65',
                label: '55-65',
            },
            {
                value: '65+',
                label: '65+',
            },
            {
                value: 'other',
                label: 'Неизвестен',
            },
        ],
    },
    category_1: {
        key: 'category_1',
        label: 'Интересы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'segments',
    },
    device_type: {
        key: 'device_type',
        label: 'Устройства',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'device',
        hint: 'Данные об устройствах, с которых пользователи заходили на сайт',
    },
    browser_family: {
        key: 'browser_family',
        label: 'Браузеры',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'browser',
        hint: 'Браузеры, которыми пользуются посетители вашего сайта',
    },
    os_family: {
        key: 'os_family',
        label: 'Операционные системы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'os',
        hint: 'Данные о том, какими операционными системами (и их версиями) пользуются посетители вашего сайта',
    },
    counter_type_eventbase: {
        key: 'counter_type_eventbase',
        label: 'Формат страниц',
        type: 'checkbox',
        data: [
            {
                value: 'Web',
                label: 'Web',
            },
            {
                value: 'Web-view',
                label: 'Web-view',
            },
            {
                value: 'Android',
                label: 'Android',
            },
            {
                value: 'iOS',
                label: 'iOS',
            },
            {
                value: 'AMP',
                label: 'Amp',
            },
            {
                value: 'Turbo',
                label: 'Turbo',
            },
            {
                value: 'Yandex',
                label: 'Yandex',
            },
        ],
        hint: 'Распределение трафика по форматам страниц — desktop, mobile, amp и turbo',
    },
    app_version: {
        key: 'app_version',
        label: 'Версия приложения',
        type: 'checkbox',
        isDynamic: true,
        hint: 'Работает только на счетчиках, которые стоят в мобильных приложениях',
    },
    traffic_type: {
        key: 'traffic_type',
        label: 'Источники переходов',
        type: 'checkbox',
        data: [
            {
                value: 'straight',
                label: 'Прямые заходы',
            },
            {
                value: 'site',
                label: 'По ссылкам на сайтах',
            },
            {
                value: 'internal',
                label: 'Внутренние переходы',
            },
            {
                value: 'search',
                label: 'Поисковые системы',
            },
            {
                value: 'adv',
                label: 'Рекламные системы',
            },
            {
                value: 'social',
                label: 'Социальные сети',
            },
            {
                value: 'zen',
                label: 'Яндекс.Дзен',
            },
            {
                value: 'email',
                label: 'Почтовые рассылки',
            },
            {
                value: 'local_page',
                label: 'Сохраненные страницы',
            },
            {
                value: 'native_mobile',
                label: 'Мобильные приложения',
            },
        ],
        hint: 'Информация о том, откуда пришел посетитель на сайт',
    },
    ad_system: {
        key: 'ad_system',
        label: 'Рекламные системы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'ad_systems',
    },
    ad_campaign: {
        key: 'ad_campaign',
        label: 'Рекламные кампании',
        type: 'textarea',
    },
    utm_source: {
        key: 'utm_source',
        label: 'Source',
        type: 'textarea',
    },
    utm_medium: {
        key: 'utm_medium',
        label: 'Medium',
        type: 'textarea',
    },
    utm_campaign: {
        key: 'utm_campaign',
        label: 'Campaign',
        type: 'textarea',
    },
    utm_content: {
        key: 'utm_content',
        label: 'Content',
        type: 'textarea',
    },
    utm_term: {
        key: 'utm_term',
        label: 'Term',
        type: 'textarea',
    },
    openstat_service: {
        key: 'openstat_service',
        label: 'Service',
        type: 'textarea',
    },
    openstat_campaign: {
        key: 'openstat_campaign',
        label: 'Campaign',
        type: 'textarea',
    },
    openstat_ad: {
        key: 'openstat_ad',
        label: 'Ad',
        type: 'textarea',
    },
    openstat_source: {
        key: 'openstat_source',
        label: 'Source',
        type: 'textarea',
    },
    search_system: {
        key: 'search_system',
        label: 'Поисковые системы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'search_system_phrase',
        hint: 'Информация о поисковых системах, с помощью которых посетители находят ваш сайт, а также по поисковым запросам, которые использовались в этих сетях',
    },
    search_phrase: {
        key: 'search_phrase',
        label: 'Поисковые фразы',
        type: 'textarea',
        hint: 'Поисковые фразы и слова, которые привели посетителей на ваш сайт',
    },
    recom_system: {
        key: 'recom_system',
        label: 'Рекомендательные системы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'recom_systems',
    },
    message_system: {
        key: 'message_system',
        label: 'Мессенджеры',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'message_systems',
    },
    qr_system: {
        key: 'qr_system',
        label: 'Переходы по QR-кодам',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'qr_systems',
    },
    aggrnews_system: {
        key: 'aggrnews_system',
        label: 'Новостные агрегаторы',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'aggrnews_systems',
    },
    exchange_system: {
        key: 'exchange_system',
        label: 'Обменные сети',
        type: 'textarea',
    },
    social_network: {
        key: 'social_network',
        label: 'Социальные сети',
        type: 'checkbox',
        isDynamic: true,
        dynamicFilterAlias: 'social_networks',
    },
    referer: {
        key: 'referer',
        label: 'Сайты / Referer',
        type: 'textarea',
        hint: 'Данные о том, с каких именно страниц пользователи совершают переходы на ваш ресурс',
    },
    url: {
        key: 'url',
        label: 'URL',
        type: 'textarea',
        hint: 'Поиск информации по введеному вами адресу сайта',
    },
    url_no_args: {
        key: 'url_no_args',
        label: 'Популярный URL',
        type: 'textarea',
        hint: 'Страницы сайта, которые посещают чаще всего',
    },
    url_args_key: {
        key: 'url_args_key',
        label: 'Параметры URL',
        type: 'checkbox',
        isDynamic: true,
        hint: 'Поиск по параметрам URL, переданных в адресах страниц',
    },
    entry_page: {
        key: 'entry_page',
        label: 'Страницы входа',
        type: 'textarea',
        hint: 'Визиты, которые начались со страниц сайта, указанных вами',
    },
    exit_page: {
        key: 'exit_page',
        label: 'Страницы выхода',
        type: 'textarea',
        hint: 'Визиты, которые закончились на страницах сайта, указанных вами',
    },
    external_url: {
        key: 'external_url',
        label: 'Переходы по внешним ссылкам',
        type: 'textarea',
    },
    label: {
        key: 'label',
        label: 'Заголовки страниц',
        type: 'textarea',
    },
    // Metric filter
    avg_recirculation: {
        key: 'avg_recirculation',
        label: 'Рециркуляция',
        type: 'period',
        isMetricFilter: true,
    },
    // Metric filter
    avg_view_duration: {
        key: 'avg_view_duration',
        label: 'Время на материале',
        type: 'period',
        isMetricFilter: true,
        inputPlaceholder: 'мм:сс',
        inputMaskOptions: {
            mask: 'Mm{:}Ss',
            definitions: {
                M: /[0-5]/,
                m: /[0-9]/,
                S: /[0-5]/,
                s: /[0-9]/,
            },
        },
    },
    author_name: {
        key: 'author_name',
        label: 'Автор материала',
        type: 'textarea',
    },
    media_title: {
        key: 'media_title',
        label: 'Название материала',
        type: 'textarea',
    },
    published_datetime: {
        key: 'published_datetime',
        label: 'Период публикации',
        type: 'datePeriod',
    },
    // published_date, published_time нужны для корректного парсинга
    published_date: {
        key: 'published_date',
        label: 'Дата публикации',
        type: 'datePeriod',
    },
    published_time: {
        key: 'published_time',
        label: 'Время публикации',
        type: 'datePeriod',
    },
    event_name: {
        key: 'event_name',
        label: 'Название события',
        type: 'event',
        // Храним данные по классам событий здесь,
        // потому что можем использовать только 1 дименшн
        data: [
            {
                value: 'base',
                label: 'Базовые события',
            },
            {
                value: 'media',
                label: 'События медиа',
            },
            {
                value: 'ec',
                label: 'Ecom события',
            },
            {
                value: 'ub',
                label: 'События аналитики блоков',
            },
            {
                value: 'cv',
                label: 'Собственные события',
            },
        ],
        dynamicFilterAlias: 'events',
        defaultValue: 'cv',
    },
    session_number: {
        key: 'session_number',
        label: 'Номер визита',
        type: 'period',
    },
    split: {
        key: 'split',
        label: 'Сплит',
        type: 'checkbox',
        isDynamic: true,
        hint: 'Подробнее о том, как настроить сплит-тестирование, читайте в <a href="https://ads.sber.ru/help/splitovanie" target="_blank">документации</a>',
    },
    auth_top100: {
        key: 'auth_top100',
        label: 'Авторизация сайта',
        type: 'radio',
        data: [
            {
                value: 'auth',
                label: 'Авторизован на сайте',
            },
            {
                value: 'not_auth',
                label: 'Не авторизован на сайте',
            },
        ],
    },
    auth_rambler: {
        key: 'auth_rambler',
        label: 'Пользователи Рамблер',
        type: 'radio',
        data: [
            {
                value: 'auth',
                label: 'Авторизован по rambler_id',
            },
            {
                value: 'not_auth',
                label: 'Не авторизован по rambler_id',
            },
        ],
    },
    ub_block_list: {
        key: 'ub_block_list',
        label: 'Аналитика блоков: Список блоков',
        type: 'checkbox',
        isDynamic: true,
    },
    customer_params_key: {
        key: 'customer_params_key',
        label: 'Собственные события: Список параметров',
        type: 'checkbox',
        isDynamic: true,
    },
    user_cv_key: {
        key: 'user_cv_key',
        label: 'Пользовательский параметр',
        type: 'textarea',
    },
} as TFiltersFormCnf;
