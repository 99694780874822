import { Input } from '@adtech/ui';
import inputConstants from '@constants/input';
import React, { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import s from '../../FirstStep.pcss';

interface IProps {
    defaultTitle?: string;
}

const Title: React.FC<IProps> = ({ defaultTitle }) => {
    const { control } = useFormContext();
    const {
        field,
        fieldState: { error, isDirty },
    } = useController({
        name: 'title',
        control,
        rules: {
            required: {
                value: true,
                message: 'Поле не заполнено',
            },
            minLength: {
                value: 3,
                message: 'Минимум 3 символа',
            },
        },
    });

    const { onBlur, onChange, value, name, ref } = field || {};
    const defaultTitlePrev = useRef('');

    useEffect(() => {
        // Если при запросе title сайта по введенному пользователем url пришло не пустое значение,
        // и оно не равно значению title запрошенному до этого,
        // и пользователь еще не вводил ничего в поле title
        if (defaultTitle && defaultTitle !== defaultTitlePrev.current && !isDirty) {
            onChange(defaultTitle);
        }
        defaultTitlePrev.current = defaultTitle;
    }, [defaultTitle]);

    return (
        <div className={s.formRow}>
            <span>Название сайта</span>
            <Input
                value={value}
                name={name}
                placeholder="Будет отображаться в списке сайтов"
                type="text"
                errorText={error?.message || ''}
                onBlur={onBlur}
                maxLength={inputConstants.MAX_TITLE_LENGTH}
                onChange={onChange}
                status={error ? 'error' : ''}
                inputRef={ref}
            />
        </div>
    );
};

export default Title;
