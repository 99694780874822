import React from 'react';
import s from '../ModalPopup.pcss';

interface IProps {
    userEmail: string;
}

export default {
    getContent(props: IProps) {
        const popupContent = (
            <div className={s.content} data-testid="description-text">
                <p>
                    Через пару минут отчёт будет у вас на почте:{' '}
                    <span className={s.csvUserEmail} data-testid="mail-text">
                        {props.userEmail}
                    </span>
                    .<br />
                    Ссылка на его скачивание активна в течение 7 дней — не забудьте сохранить его в этот период.
                </p>
            </div>
        );

        const okButton = {
            type: 'default',
            children: 'Отправить',
            closePopupOnClick: true,
            className: s.btnOk,
        };

        return {
            popupClassName: s.csv,
            width: 'md',
            title: 'Отправить отчёт',
            content: popupContent,
            buttonContainerClassName: s.buttons,
            okButton,
        };
    },
};
