import Dropdown from '@components/Dropdown';
import { colors } from '@configs/counterBanner';
import IconTop100Banner from '@images/svg/icons/top100-banner.svg';
import cn from 'classnames';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import s from './LogoColorChooser.pcss';

const LogoColorChooser: React.FC = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'color', control });

    const handleColorChange = (newColor: string) => {
        field.onChange(newColor);
    };

    const renderColorPicker = () => (
        <div className={s.colorPicker}>
            {colors.map((color) => (
                <div
                    onClick={() => handleColorChange(color)}
                    key={color}
                    className={cn(s.colorBlock, { [s.active]: color.toUpperCase() === field.value?.toUpperCase() })}
                    style={{ backgroundColor: color }}
                />
            ))}
        </div>
    );

    const renderImageGenerator = () => {
        const colorInlineStyle = {
            backgroundColor: field.value,
        };

        return (
            <div className={s.banner} style={colorInlineStyle}>
                <div className={s.bannerLogo}>
                    <IconTop100Banner />
                </div>
            </div>
        );
    };

    return (
        <div className={s.root}>
            {renderImageGenerator()}
            <Dropdown
                type="customContent"
                anchorText="Выбрать цвет виджета"
                customContent={renderColorPicker()}
                contentClassName={s.colorDropdownContent}
            />
        </div>
    );
};

export default LogoColorChooser;
