import { Button, Accordion, AccordionItem, ContextAlertMark } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import Form from '@components/Form';
import attributesData from '@configs/attributesData';
import notifications from '@constants/notifications';
import serverErrorsConstants from '@constants/serverErrors';
import useDesignForTD from '@hooks/useDesignForTD';
import useSnackbar from '@hooks/useSnackbar';
import { useAppDispatch, useAppSelector } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { projectsActions } from '@redux/slices/projects';
import { listDataUtils } from '@utils/index';
import React, { useState } from 'react';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';

import s from '../CreateCounter.pcss';

interface IFormFields {
    blockAnalyticsAttribute: string;
    color: string;
    counterType: boolean;
    mergeSubdomains: boolean;
    mergeWww: boolean;
    title: string;
    useBlockAnalytics: boolean;
    useDomainBlacklist: boolean;
    useHashes: boolean;
    useUserId: boolean;
    useWhitelist: boolean;
    whitelistedUrls: string[];
    blacklistedUrls: string[];
    inRating: boolean;
    keywords: string;
    publicStats: string;
    categories: number[];
    regions: number[];
    types: number[];
}

const SecondStep: React.FC = () => {
    const dispatch = useAppDispatch();
    const createdCounter = useAppSelector((state) => state.projectsSlice.createdCounter);
    const currentProject = useAppSelector((state) => state.projectsSlice.current);
    const saveCounterRequest = useAppSelector((state) => state.projectsSlice.saveCounterRequest);
    const config = useAppSelector((state) => state.globalSlice.projectConfig);

    const defaultValues = {
        blockAnalyticsAttribute: 'your-attribute',
        color: '#313EFB',
        counterType: false,
        mergeSubdomains: false,
        mergeWww: false,
        title: '',
        useBlockAnalytics: false,
        useDomainBlacklist: false,
        useHashes: false,
        useUserId: false,
        useWhitelist: false,
        whitelistedUrls: [],
        blacklistedUrls: [],
        publicStats: 'opened',
        inRating: false,
        keywords: '',
        categories: [],
        regions: [],
        types: [],
    };

    const formMethods = useForm<IFormFields>({
        defaultValues,
        values: {
            ...defaultValues,
            title: currentProject?.title || '',
        },
    });

    const { handleSubmit, watch } = formMethods;
    const formValues = watch();

    const { setSnackbar } = useSnackbar();
    const isFromTD = useDesignForTD();

    const [serverError, setServerError] = useState<string>(null);

    const showPopupNotification = () => {
        dispatch(globalActions.setNotification(notifications.settingsCounter));
    };

    const saveCounter = (id: number, data: Record<string, any>) => dispatch(projectsActions.save({ id, data }));

    const saveSettingsCounter: SubmitHandler<IFormFields> = (values) => {
        const categories = values.categories
            ? listDataUtils.getThemeIdsFromCategories(values.categories, config.categories.data)
            : [];

        const id = createdCounter.project_id as number;
        const data = {
            counter_settings: {
                block_analytics_attribute: values.blockAnalyticsAttribute,
                merge_subdomains: values.mergeSubdomains,
                track_hashes: values.useHashes,
                track_user_id: values.useUserId,
                use_block_analytics: values.useBlockAnalytics,
                blacklisted_urls: values.blacklistedUrls || [],
                merge_www: !values.mergeWww,
                use_whitelist: values.useWhitelist,
                whitelisted_urls: values.whitelistedUrls,
                color: values.color,
                counter_type: Number(values.counterType),
            },
            public_stats: values.publicStats === 'opened',
            in_rating: values.inRating,
            regions: values.regions ? values.regions : [],
            types: values.types ? values.types : [],
            categories,
            keywords: values.keywords,
            ...(values.title ? { title: values.title } : {}),
        };

        saveCounter(id, data).then(({ payload }) => {
            if (payload.responseStatus === 200) {
                dispatch(
                    globalActions.openModalPopup({
                        modalPopupId: 'CreateCounterFinal',
                        modalPopupParams: { projectId: id },
                    }),
                );
            } else if (payload.errors && payload.errors.message) {
                if (payload.errors.message === serverErrorsConstants.REQUEST_API_ERROR) {
                    setServerError('Произошла ошибка при запросе на сервер.');
                }
            }
        });
    };

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(saveSettingsCounter)}>
                {!isFromTD && (
                    <CounterBlocks.Rating
                        config={config}
                        inRating={formValues.inRating || false}
                        canRating={createdCounter.can_be_in_rate as boolean}
                        projectStatus={currentProject.status}
                        rootClassName={s.secondStepRoot}
                        smallTitle
                        dataAttrName={attributesData.createCounter.attrName}
                        dataAttr={[
                            attributesData.createCounter.secondStep,
                            attributesData.createCounter.ratingSwitcher,
                        ]}
                    />
                )}
                <Accordion
                    defaultActiveKey={['settings_counter']}
                    onChange={showPopupNotification}
                    className={s.secondStepSettings}
                >
                    <AccordionItem key="settings_counter" header="Настройки счётчика">
                        <>
                            <CounterBlocks.SettingsCounter counterType={formValues.counterType || false} />
                            <CounterBlocks.SettingsDomain useDomainBlacklist={formValues.useDomainBlacklist} />
                            <CounterBlocks.SettingsOther />
                        </>
                    </AccordionItem>
                </Accordion>
                <CounterBlocks.CounterCode
                    id={String(createdCounter.project_id)}
                    counterType={Number(formValues.counterType)}
                    blockAnalyticsAttribute={formValues.blockAnalyticsAttribute}
                    useBlockAnalytics={formValues.useBlockAnalytics}
                    useHashes={formValues.useHashes}
                    useUserId={formValues.useUserId}
                    setSnackbar={setSnackbar}
                    mode="create"
                />
                <div>
                    <div className={s.row}>
                        <div className={s.create}>
                            <Button
                                loading={saveCounterRequest}
                                htmlType="submit"
                                data-create-counter={
                                    `${attributesData.createCounter.secondStep}` +
                                    `::${attributesData.createCounter.saveSettings}`
                                }
                            >
                                Сохранить настройки
                            </Button>
                            {isFromTD && (
                                <Link to={`/stat/projects/${createdCounter.project_id}/goals`}>
                                    <Button type="dashed">Настроить конверсии</Button>
                                </Link>
                            )}
                        </div>
                        {serverError && <ContextAlertMark status="error" message={serverError} />}
                    </div>
                </div>
            </Form>
        </FormProvider>
    );
};

export default SecondStep;
