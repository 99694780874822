import { Button, Loader, Modal } from '@adtech/ui';
import { listDataUtils } from '@utils/index';
import flattenDeep from 'lodash/flattenDeep';
import isEqualWith from 'lodash/isEqualWith';
import max from 'lodash/max';
import React, { useEffect, useRef, useState } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import s from '../ListData.pcss';
import List from './List';
import Selected from './Selected';

interface IProps {
    isOpened: boolean;
    onClose: () => void;
    onApply: (values: any[]) => void;
    getLians: (item: Record<string, unknown>) => any;
    getParentIdsByIds: (ids: number[]) => any;
    config: Record<string, any>;
    map: Record<string, any>;
    data: any[];
    mode: 'three' | 'flat';
    field: ControllerRenderProps<FieldValues, any>;
    error: FieldError;
}

const MyDropdown: React.FC<IProps> = ({
    isOpened,
    onClose,
    onApply,
    getLians,
    getParentIdsByIds,
    config,
    map,
    data,
    mode,
    field,
    error,
}) => {
    // Получаем уровень вложенности
    const getNesting = (dataValue: any[], items: { parent_id: number; id: number }[], nesting: number = 0): number => {
        if (!items.length) return nesting;

        const curNesting = items.map((parentItem) => {
            const childItems = dataValue.filter((childItem) => childItem.parent_id === parentItem.id);

            return getNesting(dataValue, childItems, nesting + 1);
        });

        return max(curNesting);
    };

    const parents = getParentIdsByIds(field.value);
    const values = [...(field?.value || []), ...parents];
    const parentItems = data.filter((itm) => !itm.parent_id);
    const currentNestingInitial = getNesting(data, parentItems);

    const [initialValues, setInitialValues] = useState(values);
    const [selectedValues, setSelectedValues] = useState(values);
    const [currentElement, setCurrentElement] = useState([]);
    const [currentNesting, setCurrentNesting] = useState(currentNestingInitial);

    const previousSelectedValues = useRef(null);
    const previousData = useRef<any[]>(null);

    // Определяем, выбраны ли у элемента его дети
    const getIndeterminate = (item: Record<string, any>) => listDataUtils.getIndeterminate(item, selectedValues, data);

    // Получаем отфильтрованные выбранные элементы (без учёта indeterminate)
    const getFilteredValues = (valuesParam: number[] = [], isOnlySubItems: boolean = false): Array<number> => {
        let result = valuesParam.filter((id: number) => {
            const item = map[id];

            return item && !getIndeterminate(item);
        });

        if (isOnlySubItems) result = listDataUtils.filterOnlySubItems(result, map);

        return result;
    };

    useEffect(() => {
        if (previousSelectedValues.current && previousData.current) {
            const selectedValuesNew = getFilteredValues(previousSelectedValues.current);
            const value = getFilteredValues(field.value);
            const prevData = previousData.current;
            const currentData = [...data];

            if (!isEqualWith(value, selectedValuesNew) && !isOpened) {
                const parentsVal = getParentIdsByIds(value);
                const valuesNew = [...value, ...parentsVal];

                setSelectedValues(valuesNew);
                setInitialValues(valuesNew);
                setCurrentElement([]);
            }

            if (!isEqualWith(prevData, currentData)) {
                const parentItemsNew = currentData.filter((item) => !item.parent_id);
                const currentNestingNew = getNesting(currentData, parentItemsNew);
                setCurrentNesting(currentNestingNew);
            }
        }

        previousSelectedValues.current = field.value;
        previousData.current = data;
    });

    // Событие срабатывает при нажатие на кнопку "Применить"
    const handleApply = () => {
        setInitialValues(selectedValues);
        onClose();
    };

    const handleClose = () => {
        if (!isOpened) return;

        onApply(getFilteredValues(initialValues));
    };

    // Удаляем выбранный элемент
    const removeSelectedItem = (valuesParam: number[]) => {
        // получаем удаляемые элементы, путем сравнения разницы двух массивов
        const removedValues = getFilteredValues(selectedValues).filter((id) => !valuesParam.includes(id));

        let resSelectedValues = [...selectedValues];

        // получаем лианы и родителей всех элементов (в том числе лиан)
        const liansAndParents = flattenDeep(
            removedValues.map((id) => {
                const item = map[id];
                const lians = getLians(item);
                const parentsVal = getParentIdsByIds([item.id, ...lians]);

                return [...lians, ...parentsVal];
            }),
        );

        // удаляем элементы, их лианы и их родителей
        resSelectedValues = selectedValues.filter((id) => !removedValues.includes(id) && !liansAndParents.includes(id));

        // после фильтрации ищем родителей, если их нету в списке
        const parentsVal = getParentIdsByIds(resSelectedValues).filter((id) => !resSelectedValues.includes(id));

        // мержим
        resSelectedValues = [...resSelectedValues, ...parentsVal];

        setSelectedValues(resSelectedValues);
        field.onChange(getFilteredValues(resSelectedValues));
    };

    const getSubCheckedIds = (item: Record<string, any>) => {
        const subItems = data.filter((itm) => itm.parent_id === item.id);
        const subCheckedIds = subItems
            .filter((subItem) => selectedValues.includes(subItem.id))
            .map((subItem) => subItem.id);

        const recursionSubs = flattenDeep(subItems.map((subItem) => getSubCheckedIds(subItem)));

        return [...subCheckedIds, ...recursionSubs];
    };

    // Уставливаем флаг / убираем флаг
    const setValueItem = ({ item, parentOffsets }) => {
        const isIncluded = selectedValues.includes(item.id);
        const isIndeterminate = getIndeterminate(item);
        const isEqualParentOffsets = isEqualWith(currentElement, parentOffsets);
        let selectedValuesNew = [...selectedValues];

        if ((isIndeterminate && isEqualParentOffsets) || !isIndeterminate) {
            selectedValuesNew = isIncluded
                ? selectedValues.filter((id) => id !== item.id)
                : [...selectedValues, item.id];

            const lians = getLians(item);

            const parentIds = getParentIdsByIds([item.id, ...lians]);

            if (isIncluded) {
                // если мы снимаем галочку, должны снять галочки у всех детей
                const subCheckedIds = getSubCheckedIds(item);

                // собираем лианы и родителей лиан в массив
                const subCheckedLiansAndParents = flattenDeep(
                    subCheckedIds.map((id) => {
                        const subLians = getLians(map[id]);
                        const subParents = getParentIdsByIds(subLians);
                        return [...subLians, ...subParents];
                    }),
                );

                // если у снимаемого элемента есть лианы, получаем его родителей
                const parentLiansCheckedIds = lians.length
                    ? parentIds.filter((id) => selectedValuesNew.includes(id))
                    : [];

                // фильтруем значения
                selectedValuesNew = selectedValuesNew.filter(
                    (id) =>
                        !subCheckedIds.includes(id) &&
                        !subCheckedLiansAndParents.includes(id) &&
                        !lians.includes(id) &&
                        !parentLiansCheckedIds.includes(id),
                );

                // после фильтрации ищем родителей, если их нету в списке
                const parentsVal = getParentIdsByIds(selectedValuesNew).filter((id) => !selectedValuesNew.includes(id));

                // мержим
                selectedValuesNew = [...selectedValuesNew, ...parentsVal];
            } else {
                // если мы ставим галочку, должны проставить галочку всем родителям
                const parentNoCheckedIds = parentIds.filter((id) => !selectedValuesNew.includes(id));
                selectedValuesNew = [...selectedValuesNew, ...lians, ...parentNoCheckedIds];
            }
        }

        setSelectedValues(selectedValuesNew);
        setCurrentElement(parentOffsets);
        field.onChange(getFilteredValues(selectedValuesNew));
    };

    // Получаем количество выбранных элементов из списка
    const getCountLengthSelected = (item: Record<string, any>): number => {
        const selectedItem = selectedValues.includes(item.id) ? [item.id] : [];
        const subItems = getSubCheckedIds(item);

        return getFilteredValues([...selectedItem, ...subItems])?.length;
    };

    const renderActions = () => (
        <div className={s.actions}>
            <Button disabled={!field.value?.length} type="default" onClick={handleApply} className={s.actionsButton}>
                Применить
            </Button>
            <Button type="dashed" className={s.actionsButton} onClick={handleClose}>
                Отмена
            </Button>
        </div>
    );

    const renderLoading = () => (
        <Button type="dashed" suffixIcon={<Loader size="small" />} className={s.loader}>
            Загружается...
        </Button>
    );

    const renderError = () => (error ? <div className={s.error}>{error?.message}</div> : null);

    if (!data.length) return renderLoading();

    return (
        <Modal
            isOpened={isOpened}
            closeOnClickOutside={false}
            onClose={handleClose}
            customFooter={renderActions()}
            size="lg"
            title={config.titlePopup}
        >
            <List
                mode={mode}
                data={data}
                currentNesting={currentNesting}
                currentElement={currentElement}
                selectedValues={selectedValues}
                getIndeterminate={getIndeterminate}
                getCountLengthSelected={getCountLengthSelected}
                setValueItem={setValueItem}
            />
            <Selected
                map={map}
                removeSelectedItem={removeSelectedItem}
                selectedValues={getFilteredValues(selectedValues, true)}
            />
            {renderError()}
        </Modal>
    );
};

export default MyDropdown;
