class DomUtils {
    putDataAttr = (node: HTMLElement, dataAttr: string): void => {
        const data = dataAttr.split('=');

        if (data.length < 2) return;

        // eslint-disable-next-line no-param-reassign
        node.dataset[data[0]] = data[1];
    };
}

export default new DomUtils();
