/* eslint-disable max-len */
import { Checkbox } from '@adtech/ui';
import CounterBlocks from '@components/CounterBlocks';
import { Hint } from '@components/Hint';
import React from 'react';
import { ControllerRenderProps, FieldValues, useController, useFormContext } from 'react-hook-form';

import s from './SettingsDomain.pcss';

interface IProps {
    useDomainBlacklist: boolean;
}

interface ICheckboxType {
    field: ControllerRenderProps<FieldValues, any>;
    title: string;
    hint: string;
}

export const CheckboxComponent = ({ field, title, hint }: ICheckboxType) => (
    <Checkbox className={s.checkboxesItem} checked={field.value} onChange={() => field.onChange(!field.value)}>
        {title}
        <Hint>{hint}</Hint>
    </Checkbox>
);

const UseDomainBlacklistField: React.FC = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'useDomainBlacklist', control });

    return (
        <CheckboxComponent
            field={field}
            title="Исключить из статистики данные со следующих доменов"
            hint="Данные, поступающие с указанных адресов, не будут учитываться в статистике. Например, вы можете указать адрес тестовой версии сайта."
        />
    );
};

const UseSubDomainsField: React.FC = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'mergeSubdomains', control });

    return (
        <CheckboxComponent
            field={field}
            title="Учитывать поддомены"
            hint="Все домены второго уровня и выше указанных доменов будут считаться частью сайта при расчёте статистики."
        />
    );
};

const UseWhitelistField: React.FC = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'useWhitelist', control });

    return (
        <CheckboxComponent
            field={field}
            title="Показывать в статистике данные только с указанных доменов"
            hint="В статистике будут учитываться только данные с указанных адресов, независимо от того, на каких ещё страницах установлен счётчик."
        />
    );
};

const UseWwwField: React.FC = () => {
    const { control } = useFormContext();
    const { field } = useController({ name: 'mergeWww', control });

    return (
        <CheckboxComponent
            field={field}
            title="Считать раздельно URL с www и без"
            hint='В отчётах группы «Содержание» страницы с "www" будут учитываться отдельно.'
        />
    );
};

const docLink = 'https://ads.sber.ru/help/nastroika-schyotchika#dopolnitelnye-domeny';

const SettingsDomain: React.FC<IProps> = ({ useDomainBlacklist }) => (
    <div>
        <CounterBlocks.Wrapper
            title="Дополнительные домены"
            description="Укажите дополнительные домены, на которых располагается ваш сайт, для корректного расчёта статистики по источникам трафика."
            rootClassName={s.domainRoot}
            descClassName={s.domainDescr}
            smallTitle
        >
            <div className={s.blockLink}>
                <a href={docLink} className={s.link} target="_blank" rel="noopener">
                    Подробнее
                </a>
            </div>
            <CounterBlocks.MultiInputData name="whitelistedUrls" button="Добавить" placeholder="Доменное имя" />
            <div className={s.checkboxes}>
                <UseSubDomainsField />
                <UseWhitelistField />
                <UseWwwField />
            </div>
            <CounterBlocks.Wrapper
                subWrap
                withoutDelimiter
                rootClassName={s.customPaddingRoot}
                normalPadding={useDomainBlacklist}
            >
                <UseDomainBlacklistField />
                {useDomainBlacklist && (
                    <CounterBlocks.MultiInputData
                        className={s.inputBlackList}
                        name="blacklistedUrls"
                        button="Добавить"
                        placeholder="Доменное имя"
                    />
                )}
            </CounterBlocks.Wrapper>
        </CounterBlocks.Wrapper>
    </div>
);

export default SettingsDomain;
