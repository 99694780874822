import { ChipButton, IconCross, Button, IconPlus } from '@adtech/ui';
import { listDataUtils } from '@utils/index';
import cn from 'classnames';
import flattenDeep from 'lodash/flattenDeep';
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Wrapper from '../Wrapper';
import Dropdown from './Dropdown';

import s from './ListData.pcss';

interface IProps {
    isLoadedConfig: boolean;
    config: Record<string, any>;
    name: string;
    label: string;
    rules: Record<string, any>;
    map: Record<string, any>;
    data: any[];
    mode: 'three' | 'flat';
    isRequired: boolean;
    disabled: boolean;
    smallTitle?: boolean;
}

const ListData: React.FC<IProps> = ({
    isLoadedConfig,
    config,
    name,
    rules,
    map,
    data,
    mode,
    isRequired,
    disabled,
    smallTitle,
}) => {
    const { control } = useFormContext();
    const [isOpen, setOpen] = useState(false);

    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control,
        rules,
    });

    // Закрываем дропдаун
    const handleClose = () => {
        field.onBlur();
        setOpen(false);
    };

    // Открываем дрондаун
    const handleOpen = () => setOpen(true);

    // Меняем количество выбранных элементов (прилетает новый список)
    const changeValues = (values: string[]) => {
        field.onChange(values);

        return Promise.resolve();
    };

    // Применяем новые выбранные чекбоксы
    const handleApply = async (values: string[]) => {
        // это нужно, чтобы сначала изменились данные,
        // а затем закрылся <Dropdown />
        await changeValues(values);
        handleClose();
    };

    // Получение лиан элемента
    const getLians = (item: Record<string, unknown>) => listDataUtils.getLians(item, data);

    // Получение родителей элементов
    const getParentIdsByIds = (ids: number[]) => listDataUtils.getParentIdsByIds(ids, map);

    // Удаляем выбранный элемент
    const removeSelectedItem = (values?: string[]) => {
        const selectedValues = field.value as string[];

        // получаем удаляемые элементы, путем сравнения разницы двух массивов
        const removedValues = selectedValues.filter((id) => !values.includes(id));

        // получаем лианы и родителей всех элементов (в том числе лиан)
        const liansAndParents = flattenDeep(
            removedValues.map((id) => {
                const item = map[id];
                const lians = getLians(item);
                const parents = getParentIdsByIds([item.id, ...lians]);

                return [...lians, ...parents];
            }),
        );

        // удаляем элементы, их лианы и их родителей
        const resSelectedValues = selectedValues.filter(
            (id) => !removedValues.includes(id) && !liansAndParents.includes(id),
        );

        return changeValues(resSelectedValues);
    };

    const removeRemainingElements = (id: string) => {
        const removeFunction = disabled ? undefined : removeSelectedItem;

        if (removeFunction) {
            if (Array.isArray(field.value)) {
                removeFunction(field.value.filter((elem) => elem !== id));
            } else if (field.value === id) {
                removeFunction();
            }
        }
    };

    const removeElement = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        if (target.closest('svg')) {
            const span = target.closest('span');
            const id = span?.dataset?.id;
            if (id) {
                removeRemainingElements(id);
            }
        }
    };

    const renderButtonAnchor = () => (
        <Button onClick={handleOpen} prefixIcon={<IconPlus />} type="dashed">
            {config.labelButton}
        </Button>
    );

    const renderAction = () => {
        if (disabled) return null;

        return (
            <div className={s.action}>
                {renderButtonAnchor()}
                {isLoadedConfig && (
                    <div className={cn(s.actionLabel, { [s.error]: error })}>{config.labelSelected}</div>
                )}
            </div>
        );
    };

    const renderSelectedItem = (id: number, key: number) => {
        const currentItem = map[id];

        const title = [];

        const pushParentTitles = (item: Record<string, any>) => {
            if (item.parent_id) {
                const parentItem = map[item.parent_id];
                if (parentItem) title.push(parentItem.title);

                pushParentTitles(parentItem);
            }
        };

        pushParentTitles(currentItem);

        title.reverse();
        title.push(currentItem.title);

        return (
            <ChipButton
                key={key}
                className={s.chipButton}
                fixedWidth
                icon={<IconCross className={s.removeIcon} />}
                data-id={id}
                checked={false}
            >
                {`${title.join(' / ')}`}
            </ChipButton>
        );
    };

    const renderSelected = () => {
        if (!field.value?.length || !isLoadedConfig) return null;

        const listSelected = listDataUtils.filterOnlySubItems(field.value, map);

        return (
            <div className={s.listSelected} onClick={removeElement.bind(this)}>
                {listSelected.map((id, key) => renderSelectedItem(id, key))}
            </div>
        );
    };

    return (
        <Wrapper
            subWrap
            fixPadding
            title={config.title}
            description={config.description}
            smallTitle={smallTitle}
            isRequired={isRequired}
        >
            {renderAction()}
            {renderSelected()}
            <Dropdown
                config={config}
                mode={mode}
                field={field}
                error={error}
                data={data}
                map={map}
                isOpened={isOpen}
                onClose={handleClose}
                onApply={handleApply}
                getLians={getLians}
                getParentIdsByIds={getParentIdsByIds}
            />
        </Wrapper>
    );
};

export default ListData;
