import { GroupBy } from '@configs/group';
import dateConstants from '@constants/date';
import { useAppDispatch } from '@hooks/useStore';
import { globalActions } from '@redux/slices/global';
import { IUpdateReportParams } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import DateUtils from '@utils/date';
import globalUtilsFunc from '@utils/global';
import { useSelector } from 'react-redux';

const useGlobalParams = () => {
    const globalSlice = useSelector((state: IRootSlice) => state.globalSlice);
    const dispatch = useAppDispatch();

    const globalUtils = globalUtilsFunc();

    const api = {
        updateGlobalParams(updateParams: IUpdateReportParams, withoutCompare?: boolean) {
            let newParams = { ...updateParams };

            newParams = globalUtils.filterParamsForStore(newParams, globalSlice);

            const noParamsUpdate =
                !withoutCompare &&
                !globalUtils.compareParams(
                    {
                        ...globalSlice,
                    },
                    newParams,
                );

            if (Object.keys(newParams).length) dispatch(globalActions.updateParams(newParams));

            return { newParams, noParamsUpdate };
        },

        /**
         * Сбрасываем данные на сегодня
         */
        resetDatesToToday(groupBy?: GroupBy): void {
            const today = DateUtils.getDate().format(dateConstants.DATE_FORMAT);

            dispatch(
                globalActions.updateParams({
                    dateStart: today,
                    dateEnd: today,
                    groupBy: groupBy || GroupBy.fiveMinute,
                }),
            );
        },

        /**
         * Установить состояние навигации
         */
        setIsNavigationOpen(isOpen?: boolean): void {
            dispatch(
                globalActions.updateParams({
                    isNavigationOpen: isOpen,
                }),
            );
        },
    };

    return api;
};

export default useGlobalParams;
